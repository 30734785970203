import { AccordionInfo } from '@/components/AccordionInfo.tsx';
import { Button } from 'flowbite-react';
import { ReactMediaRecorder } from 'react-media-recorder';

import { FormikProvider, useFormik } from 'formik';
import { UploadedFile } from '@/components/UploadedFile.tsx';
import { AddResourcePayload, InputType } from '@/models/add-resource-payload.interface.ts';
import { inputsPayloadMapper } from '@/helpers/inputs-payload-mapper.ts';
import { DateTime } from 'luxon';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { GLOBAL_LOADER_MESSAGES } from '@/components/GlobalLoader/global-loader-messages.const.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useAddResource } from '@/api/inputs/add-resource.ts';

export function DataRoomAddRecordingsModalBody() {
  const { addResource } = useAddResource();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal } = useGlobalModal();

  const formik = useFormik<{ recordedFiles: { url: string; file: File }[] }>({
    initialValues: {
      recordedFiles: [],
    },
    onSubmit: async ({ recordedFiles }: { recordedFiles: { url: string; file: File }[] }) => {
      const files = recordedFiles.map((f) => f.file);
      const payloads: AddResourcePayload[] = await inputsPayloadMapper[InputType.File](files);
      closeModal();
      showLoader(GLOBAL_LOADER_MESSAGES.AddingData);
      await Promise.all(payloads.map((payload) => addResource(payload)));
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <AccordionInfo
        question="What content should be included in my recordings?"
        answer="Feel welcome to share any new insights about your business and details not explicitly mentioned in other types of documents. You can incorporate your tone of voice, specific channels of focus, and any recent updates."
      />

      <form
        onSubmit={formik.handleSubmit}
        className="mt-8"
      >
        <ReactMediaRecorder
          audio={true}
          onStop={(blobUrl, blob) => {
            const filename = `${DateTime.now().toFormat('yyyy-MM-dd HH-mm')}.mp3`;
            const file = new File([blob], filename, { type: blob.type });
            formik.setFieldValue('recordedFiles', [...formik.values.recordedFiles, { url: blobUrl, file }]);
          }}
          render={({ status, startRecording, stopRecording }) => (
            <Button
              color="secondary"
              className="w-full"
              onClick={status === 'recording' ? stopRecording : startRecording}
              disabled={status === 'acquiring_media'}
            >
              <img
                src={status === 'recording' ? '/stop-red.svg' : '/mic-black.svg'}
                alt=""
              />
              {status === 'recording' ? 'Stop audio' : 'Record audio'}
            </Button>
          )}
        />

        <div className="mt-6 flex flex-col gap-2">
          {formik.values.recordedFiles.map((recordedFile, index) => (
            <UploadedFile
              key={index}
              file={recordedFile.file}
              url={recordedFile.url}
              onRemove={(file) => {
                formik.setFieldValue(
                  'recordedFiles',
                  formik.values.recordedFiles.filter((f) => f.file !== file),
                );
              }}
            />
          ))}
        </div>

        <div className="modal-divider mt-8 mb-6"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={formik.values.recordedFiles.length === 0}
          >
            Add
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
