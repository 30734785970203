import { useApi } from '@/api/api.ts';
import { mutate } from 'swr';

export const useDeleteFromMemoryContext = (): {
  deleteFromMemoryContext: (projectId: string, slot: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();

  const deleteFromMemoryContext = async (projectId: string, memoryContextItemId: number) => {
    await deleteFetcher(`/memory-context/${projectId}/${memoryContextItemId}`);
    await mutate(`/memory-context/${projectId}`);
  };

  return {
    deleteFromMemoryContext,
  };
};
