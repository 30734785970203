import { useAccessToken } from '@/api/get-access-token.ts';

export const useRefreshAuth0Token = (): {
  refreshAuth0Token: () => Promise<string>;
} => {
  const { getAccessToken } = useAccessToken();

  return {
    refreshAuth0Token: () =>
      getAccessToken({ cacheMode: 'off', authorizationParams: { redirect_uri: window.location.origin } }),
  };
};
