import { useApi } from '../api';
import { mutate } from 'swr';
import { useCurrentWorkspace } from '@/state/app-store.ts';

export const useDeleteWebSearcherRoom = (): {
  deleteWebSearcherRoom: (roomId: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const deleteWebSearcherRoom = async (roomId: number) => {
    await deleteFetcher(`/websearcher/room/${roomId}`);
    await mutate(`/websearcher/rooms/by-workspace/${workspaceId}`);
  };

  return { deleteWebSearcherRoom };
};
