import { create } from 'zustand';
import { MemoryLogItem } from './MemoryLog';

interface MemoryLogState {
  logs: MemoryLogItem[];
  isOpen: boolean;
  allTaskIsCompleted: boolean;
  unreadLogsCount: number;
}

export const useMemoryLogStore = create<MemoryLogState>(() => ({
  logs: [],
  isOpen: false,
  allTaskIsCompleted: true,
  unreadLogsCount: 0,
}));

export const addLog = (log: MemoryLogItem) => {
  useMemoryLogStore.setState({ logs: [...useMemoryLogStore.getState().logs, log] });
};
export const setLogs = (logs: MemoryLogItem[]) => {
  useMemoryLogStore.setState({ logs });
};

export const setIsOpen = (isOpen: boolean) => {
  useMemoryLogStore.setState({ isOpen });
};

export const setAllTaskIsCompleted = (allTaskIsCompleted: boolean) => {
  useMemoryLogStore.setState({ allTaskIsCompleted });
};

export const setUnreadLogsCount = (unreadLogsCount: number) => {
  useMemoryLogStore.setState({ unreadLogsCount });
};

export const setClearMemoryLogs = () => {
  useMemoryLogStore.setState({ logs: [] });
  useMemoryLogStore.setState({ allTaskIsCompleted: true });
  useMemoryLogStore.setState({ unreadLogsCount: 0 });
  useMemoryLogStore.setState({ isOpen: false });
};
