import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';
import { usePublicApi } from '@/api/public-api.ts';

export const useCreatePublicWebSearcherRoom = (): {
  createPublicWebSearcherRoom: (name: string) => Promise<WebSearcherRoom>;
} => {
  const { publicPostFetcher } = usePublicApi();

  const createPublicWebSearcherRoom = async (name: string) => {
    return await publicPostFetcher('/websearcher/room', { arg: { name } });
  };

  return { createPublicWebSearcherRoom };
};
