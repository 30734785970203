import { marked } from 'marked';
import { ToolName } from 'src/libs/tools/type.model';

interface SmartScrapperOutput {
  results: {
    content: {
      url: string;
      raw_text: string;
      google_research: boolean;
      summarization: string | null;
      color_stats: string | null;
    }[];
  };
}

interface DynamicResearchOutput {
  content: string;
  query: string;
}

interface ResearcherCompanyOutput {
  company_overview: string;
  product_information: string;
  brand_guidelines: string;
  marketing_effort: string;
}

interface TavilyOutput {
  query: string;
  follow_up_questions: string | null;
  answer: string | null;
  images: [];
  results: { title: string; url: string; content: string; score: number }[];
}

interface ResearcherGeneralOutput {
  positions: { specificPrompt: string; value: string }[];
}

export type Output = SmartScrapperOutput | DynamicResearchOutput | ResearcherCompanyOutput | ResearcherGeneralOutput;

export const researchgenOutputFormater = (output: Output, toolName: ToolName): string => {
  switch (toolName) {
    case ToolName.SMART_SCRAPPER:
      return formatSmartScraper(output as SmartScrapperOutput);

    case ToolName.DYNAMIC_RESEARCHER:
      return formatDynamicResearch(output as DynamicResearchOutput);

    case ToolName.RESEARCHER:
      return formatResearcher(output as ResearcherCompanyOutput | ResearcherGeneralOutput);

    case ToolName.TAVILY:
      return formatTavily(output as unknown as TavilyOutput);
  }

  if (typeof output === 'string') return marked(output as string) as string;

  return output as unknown as string;
};

function formatSmartScraper(output: SmartScrapperOutput) {
  let text = '';

  output.results.content.forEach((element) => {
    text += `<h2>${element.url}</h2>`;

    if (element.summarization) {
      text += `<p>${marked(element.summarization)}</p>`;
    } else {
      text += `<p>${element.raw_text}</p>`;
    }

    if (element.color_stats) {
      text += `<h3>Colors</h3>`;
      text += `<p>${marked(element.color_stats)}</p>`;
    }
  });

  return text;
}
function formatDynamicResearch(output: DynamicResearchOutput): string {
  return marked(output.content) as string;
}
function formatResearcher(output: ResearcherCompanyOutput | ResearcherGeneralOutput): string {
  let text = '';

  if ('company_overview' in output) {
    text += `<h2>Company Overview</h2>`;
    text += `<p>${marked(output.company_overview)}</p>`;
  }

  if ('product_information' in output) {
    text += `<h2>Product Information</h2>`;
    text += `<p>${marked(output.product_information)}</p>`;
  }

  if ('positions' in output) {
    output.positions.forEach((element) => {
      text += `<h2>${element.specificPrompt}</h2>`;
      text += `<p>${element.value}</p>`;
    });
  }

  return text;
}
function formatTavily(output: TavilyOutput): string {
  let text = `<h2> Your Query: ${output.query} </h2>`;

  if (output.follow_up_questions) {
    text += `<h3>Follow Up Questions</h3>`;
    text += `<p>${output.follow_up_questions}</p>`;
  }

  if (output.answer) {
    text += `<h3>Answer</h3>`;
    text += `<p>${output.answer}</p>`;
  }

  if (output.images.length > 0) {
    text += `<h3>Images</h3>`;
    output.images.forEach((element) => {
      text += `<img class="my-6" src="${element}">`;
    });
  }

  if (output.results.length > 0) {
    text += `<h3>Results</h3>`;

    output.results.forEach((element) => {
      text += `<h4>${element.title}</h4>`;
      text += `<p>${element.content}</p>`;
      text += `<a href="${element.url}">${element.url}</a>`;
      text += `<p>score: ${element.score}</p>`;
    });
  }

  return marked(text) as string;
}
