import { useEffect, useMemo, useState } from 'react';

import { Editor, useEditor } from '@tiptap/react';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { HocuspocusProvider, WebSocketStatus } from '@hocuspocus/provider';
import * as Y from 'yjs';
import { ExtensionKit } from '@/components/tiptap/extensions/extension-kit';
import { userColors, userNames } from '../lib/constants';
import { randomElement } from '../lib/utils';
import { EditorUser } from '../components/BlockEditor/types';
import { useSidebar } from './useSidebar';
import { useDebounce } from 'use-debounce';
import { useTiptapCurrentView } from '@/components/tiptap/extensions/DigitalFirst/store';
import { turndownService } from '@/components/tiptap/extensions/DigitalFirst/turndown/turndown.service';
// import { useSearchParams } from 'next/navigation';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';

declare global {
  interface Window {
    editor: Editor | null;
  }
}

export const useBlockEditor = ({
  ydoc,
  provider,
  editable = true,
}: {
  ydoc: Y.Doc;
  provider?: HocuspocusProvider | null | undefined;
  editable: boolean;
}) => {
  const leftSidebar = useSidebar();
  const [collabState, setCollabState] = useState<WebSocketStatus>(WebSocketStatus.Connecting);
  const isExecutionMode = useTiptapCurrentView() == TiptapView.EXECUTION_MODE;

  const editor = useEditor(
    {
      editable: editable,
      autofocus: true,
      extensions: [
        ...ExtensionKit({
          provider,
        }),

        Collaboration.configure({
          document: ydoc,
        }),
        CollaborationCursor.configure({
          provider,
          user: {
            name: randomElement(userNames),
            color: randomElement(userColors),
          },
        }),
      ],
      editorProps: {
        attributes: {
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
          class: 'min-h-full',
        },
      },
    },
    [ydoc, provider],
  );

  const [debouncedEditor] = useDebounce(editor?.state.doc.content, 5000);

  useEffect(() => {
    if (debouncedEditor && isExecutionMode) {
      const finalContent: Record<string, string> = {};

      editor?.extensionStorage.tableOfContents.content.forEach((header: { dom: HTMLElement }) => {
        const firstHeader: HTMLElement = header.dom;
        const firstHeaderLevel = firstHeader?.tagName;

        let next = firstHeader?.nextSibling as HTMLElement;
        let nextLevel = next?.tagName ?? '';
        const content = [];

        while (next && nextLevel !== firstHeaderLevel) {
          content.push(next);
          next = next?.nextSibling as HTMLElement;
          nextLevel = next?.tagName ?? '';
        }

        finalContent[firstHeader?.textContent ?? ''] = turndownService.turndown(
          content.map((e) => e.innerHTML).join(' '),
        );
      });

      // try {
      //   const payload = JSON.parse(
      //     JSON.stringify({
      //       text: finalContent,
      //       docName,
      //     }).replaceAll(/data:image\/[^;]+;base64,[\w\/+=]+/g, ''),
      //   );

      //   dfAxios().post(`/tiptap/save-content`, { ...payload });
      // } catch {
      //   console.log('Unable to parse finalContent');
      // }
    }
  }, [debouncedEditor]);

  const users = useMemo(() => {
    if (!editor?.storage.collaborationCursor?.users) {
      return [];
    }

    return editor.storage.collaborationCursor?.users
      .filter((user: EditorUser) => user.name)
      .map((user: EditorUser) => {
        const names = user.name?.split(' ');
        const firstName = names?.[0];
        const lastName = names?.[names.length - 1];
        const initials = `${firstName?.[0] || '?'}${lastName?.[0] || '?'}`;

        return { ...user, initials: initials.length ? initials : '?' };
      });
  }, [editor?.storage.collaborationCursor?.users]);

  const characterCount = editor?.storage.characterCount || { characters: () => 0, words: () => 0 };

  useEffect(() => {
    provider?.on('status', (event: { status: WebSocketStatus }) => {
      setCollabState(event.status);
    });
  }, [provider]);

  window.editor = editor;

  return { editor, users, characterCount, collabState, leftSidebar };
};
