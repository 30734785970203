import Mention from '@tiptap/extension-mention';
import { mentionRender } from './mentionRender';
import { PluginKey } from '@tiptap/pm/state';
import { MutableRefObject } from 'react';
import { Instance, Props } from 'tippy.js';
import { CanvasTemplate } from '@/api/canvases/api';
import { DOMOutputSpec } from 'prosemirror-model';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';

export const CanvasMention = (tippy: MutableRefObject<Instance<Props>[]>, canvasTemplates: CanvasTemplate[]) => {
  return Mention.extend({ name: MentionReferenceType.CANVAS }).configure({
    HTMLAttributes: {
      class: MentionReferenceType.CANVAS,
    },
    renderText({ options, node }) {
      return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
    },
    renderHTML({ options, node }): DOMOutputSpec {
      const span = document.createElement('span');
      span.dataset.id = node.attrs.id;
      span.dataset.child = node.attrs.childId;
      span.dataset.label = node.attrs.label;
      span.dataset.type = MentionReferenceType.CANVAS;
      span.classList.add('inline-block', 'px-2', 'bg-violet-100', 'rounded', 'text-violet-1000', 'text-body-md');

      span.innerHTML = `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;

      return span;
    },
    deleteTriggerWithBackspace: true,
    suggestion: {
      char: '@',
      pluginKey: new PluginKey(MentionReferenceType.CANVAS),
      items: async ({ query }: { query: string }) => {
        const commands = canvasTemplates?.map((canvas: CanvasTemplate) => ({
          id: canvas.id,
          name: canvas.name,
          label: canvas.name,
          children: {
            name: 'list',
            commands: canvas?.positions?.map((position: { label: string; id: number }) => ({
              label: position.label,
              id: position.id,
            })),
          },
        }));

        return [
          {
            name: 'List',
            title: 'Add to prompt',
            commands: commands.filter(({ name }: { name: string }) =>
              name.toLowerCase().startsWith(query.toLowerCase()),
            ),
          },
        ];
      },
      render: mentionRender(tippy),
    },
  });
};
