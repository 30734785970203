/* eslint-disable react-refresh/only-export-components */
import { EditorContent, useEditor } from '@tiptap/react';

import Paragraph from '@tiptap/extension-paragraph';
import { Document } from '@/components/tiptap/extensions/Document';
import Text from '@tiptap/extension-text';
import { Column, Columns } from '@/components/tiptap/extensions/MultiColumn';
import { TrailingNode } from '@/components/tiptap/extensions/TrailingNode';

import './PromptEditor.css';
import { SectionMention } from './mentions/SectionMention';
import { CanvasMention } from './mentions/CanvasMention';
import { useRef } from 'react';
import tippy from 'tippy.js';
import { useCanvasTemplates } from '@/api/canvases/api';
import { DataRoomMention } from './mentions/DataRoomMention.tsx';
import { useInputs } from '@/api/inputs/get-inputs';
import { MentionInjector } from '@/components/tiptap/extensions/DigitalFirst/editing/mention-injector/MentionInjector.tsx';
import { PromptEditorContext } from '@/components/tiptap/extensions/DigitalFirst/editing/prompt-editor.context.ts';

export interface TOC {
  content: {
    id: string;
    dom: {
      textContent: string;
    };
  }[];
}

interface PromptEditorProps {
  initialContent: string;
  onContentChanged: (content: string) => void;
  toc: TOC;
}

const getTippy = () =>
  tippy('body', {
    interactive: true,
    trigger: 'manual',
    placement: 'bottom-start',
    theme: 'slash-command',
    maxWidth: '16rem',
    offset: [16, 8],
    popperOptions: {
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          enabled: false,
        },
      ],
    },
  });

export enum PromptMentionType {
  SECTION = 'SECTION',
  CANVAS = 'CANVAS',
  DATA_ROOM = 'DATA_ROOM',
}

export const PromptEditor = ({ initialContent, onContentChanged, toc }: PromptEditorProps) => {
  const tippyRefCanvas = useRef(getTippy());
  const tippyRefSections = useRef(getTippy());
  const tippyRefInputResources = useRef(getTippy());

  const { canvasTemplates, isLoading: canvasTemplatesLoading } = useCanvasTemplates(true);
  const { addedData, isLoading: addedDataLoading } = useInputs();

  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph,
        Columns,
        Column,
        SectionMention(toc, tippyRefSections),
        DataRoomMention(tippyRefInputResources, addedData),
        CanvasMention(tippyRefCanvas, canvasTemplates),

        Text,
        TrailingNode,
      ],
      content: initialContent,
      injectCSS: false,
      onUpdate({ editor }) {
        onContentChanged(editor.getHTML());
      },
    },
    [canvasTemplates],
  );

  return (
    <PromptEditorContext.Provider value={{ editor, toc }}>
      {!canvasTemplatesLoading && !addedDataLoading && (
        <>
          <div className="df__propseditor text-input-text rounded-t-lg border border-neutrals-400 py-2.5 px-4">
            <EditorContent editor={editor} />
          </div>
          <MentionInjector />
        </>
      )}
    </PromptEditorContext.Provider>
  );
};
