import { cn } from '@/components/tiptap/lib/utils';
import { HTMLProps, forwardRef } from 'react';

export type SurfaceProps = HTMLProps<HTMLDivElement> & {
  withShadow?: boolean;
  withBorder?: boolean;
};

export const Surface = forwardRef<HTMLDivElement, SurfaceProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, className, withShadow = true, withBorder = true, selected, ...props }, ref) => {
    const surfaceClass = cn(
      className,
      'bg-white rounded-lg dark:bg-black text-sm',
      withShadow ? 'shadow-sm' : '',
      selected ? 'border border-purple-400 dark:border-purple-400' : 'border border-zinc-200 dark:border-neutral-800',
    );

    return (
      <div
        className={surfaceClass}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

Surface.displayName = 'Surface';
