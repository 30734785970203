import { convertVideoLinkToEmbed } from '@/helpers/convert-video-link-to-embed.ts';

interface VideoEmbedProps {
  videoSrc: string;
  width?: string;
  height?: string;
  className?: string;
}

export const VideoEmbed = ({ videoSrc, width = '560px', height = '314px', className }: VideoEmbedProps) => {
  const embedSrc = convertVideoLinkToEmbed(videoSrc);

  return (
    <>
      {embedSrc && (
        <iframe
          src={embedSrc}
          width={width}
          height={height}
          style={{ border: 'none', overflow: 'hidden' }}
          className={className}
          allowFullScreen={true}
        ></iframe>
      )}
    </>
  );
};
