import { Badge, Button } from 'flowbite-react';
import { ColorPalette } from './ColorPalette.tsx';
import { DfTooltip } from '@/components/DfTooltip/DfTooltip.tsx';

export function CompanyBriefIsland() {
  return (
    <div className="bg-[#fff3f6] rounded-3xl p-8 flex">
      <div className="shrink-0 bg-[#ffb3c7] rounded-3xl size-[132px] mr-6 flex items-center justify-center">
        <span className="text-[70px]">W</span>
      </div>

      <div className="grow flex flex-col mr-8">
        <div className="flex items-center gap-3 mb-3">
          <h2 className="text-h1-desktop">Company name</h2>
          <Badge
            color="pink"
            size="lg"
          >
            Coming soon!
          </Badge>
        </div>
        <p className="text-body-md mb-2">
          Digital First AI is a revolutionary marketing platform that provides easy access to effective marketing
          strategies, empowering...
        </p>
        <div className="text-[#d73c65] font-medium flex gap-2 items-end">
          Overview
          <img
            src="/chevron-right-pink.svg"
            alt=""
          />
        </div>
      </div>

      <div className="flex flex-col justify-between items-end min-w-[300px]">
        <Button
          color="secondary"
          disabled={true}
          data-tooltip-id="coming-soon-brand-assets"
        >
          <img
            src="/brand-assets-black.svg"
            alt=""
          />
          Brand assets
        </Button>
        <DfTooltip
          id="coming-soon-brand-assets"
          content="Coming soon!"
          place="bottom"
        />
        <div className="flex gap-4 items-center">
          <ColorPalette
            colors={['#ffb3c7', '#110f23', '#423f5e', '#f7658c']}
            bgColor="#fff3f6"
          />
          <div className="flex gap-3">
            <img
              src="/font-black.svg"
              alt=""
            />
            Inter font
          </div>
        </div>
      </div>
    </div>
  );
}
