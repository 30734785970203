/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldInputProps, FormikProps, getIn } from 'formik';
import { RadioProps, TextInputProps } from 'flowbite-react';

export function getDfFieldProps(
  name: string,
  formik: FormikProps<any>,
): FieldInputProps<any> & {
  id: string;
  color: TextInputProps['color'];
  helperText: string;
} {
  const touched = getIn(formik.touched, name);
  const error = getIn(formik.errors, name);

  return {
    id: name,
    ...formik.getFieldProps(name),
    color: touched && error ? 'failure' : 'gray',
    helperText: touched && error,
  };
}

export function getDfRadioProps(
  name: string,
  value: string,
  formik: FormikProps<any>,
): RadioProps & {
  color: string;
} {
  const touched = getIn(formik.touched, name);
  const error = getIn(formik.errors, name);

  return {
    ...formik.getFieldProps({ name, value, type: 'radio' }),
    value,
    id: `${name}-${value}`,
    color: touched && error ? 'failure' : 'gray',
  };
}
