import { mutate, useSWRConfig } from 'swr';
import { useApi } from '../api.ts';

export function useDeleteFlowTemplate() {
  const { deleteFetcher } = useApi();
  const { cache } = useSWRConfig();

  const deleteFlowTemplate = (flowId: number) =>
    deleteFetcher(`/flow/remove-template/${flowId}`).then(async () => {
      await Promise.all(
        Array.from(cache.keys())
          .filter((key) => key.startsWith('/flow'))
          .map((key) => mutate(key)),
      );
    });

  return {
    deleteFlowTemplate,
  };
}
