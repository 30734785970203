import { useApi } from '../api';
import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';

export const useCanAccessTiptapDocument = (): {
  canAccessTiptapDocument: (tiptapDocumentId: number) => Promise<TiptapAccessMode>;
} => {
  const { getFetcher } = useApi();

  const canAccessTiptapDocument = async (tiptapDocumentId: number): Promise<TiptapAccessMode> => {
    return getFetcher(`/tiptap-document/can-access/${tiptapDocumentId}`);
  };

  return { canAccessTiptapDocument };
};
