import { useApi } from '../api.ts';
import { mutate } from 'swr';

export const useDeleteOrganization = (): {
  deleteOrganization: () => Promise<void>;
} => {
  const { deleteFetcher } = useApi();

  const deleteOrganization = async (): Promise<void> => {
    await deleteFetcher('/organization');
    await mutate('/organization');
  };

  return {
    deleteOrganization,
  };
};
