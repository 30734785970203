import { useApi } from '../../api.ts';
import { Flow } from '@/models/flow.interface.ts';
import { TiptapDocumentType } from '@/enums/tiptap-document-type.enum.ts';

export const useCreateMyFlow = (): {
  createMyFlow: (name: string, projectId: number, type?: TiptapDocumentType) => Promise<Flow>;
} => {
  const { postFetcher } = useApi();

  const createMyFlow = async (name: string, projectId: number, type: TiptapDocumentType = TiptapDocumentType.FLOW) => {
    return await postFetcher(`/flow/my-flow`, {
      arg: {
        name,
        projectId,
        type,
      },
    });
  };

  return { createMyFlow };
};
