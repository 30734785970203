import { useApi } from '@/api/api.ts';
import { mutate } from 'swr';
import { MemoryContextPayload } from '@/models/MemoryContextPayload.type.ts';

export const useAddToMemoryContext = (): {
  addToMemoryContext: (projectId: string, payload: MemoryContextPayload) => Promise<void>;
} => {
  const { postFetcher } = useApi();

  const addToMemoryContext = async (projectId: string, payload: MemoryContextPayload): Promise<void> => {
    await postFetcher(`/memory-context/${projectId}`, { arg: payload });
    await mutate(`/memory-context/${projectId}`);
  };

  return {
    addToMemoryContext,
  };
};
