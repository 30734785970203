import { useApi } from '@/api/api';
import { CanvasDetails, convertToCanvasDetails } from '@/api/canvases/api.ts';

/**
 * workaround for tiptap, because tiptap is not hook based
 */
export const useTiptapCanvasDetails = (): {
  getTiptapCanvasDetails: (instanceId: number) => Promise<CanvasDetails>;
} => {
  const { getFetcher } = useApi();

  const getTiptapCanvasDetails = async (instanceId: number) => {
    const canvasInstance = await getFetcher(`/strategy-canvas/instance/${instanceId}`);
    return convertToCanvasDetails(canvasInstance);
  };

  return {
    getTiptapCanvasDetails,
  };
};
