import { Helmet } from 'react-helmet';
import { WebSearcherPanel } from '../../web-searcher/components/WebSearcherPanel.tsx';
import { WebSearcherNewSearchForm } from '../../web-searcher/components/WebSearcherNewSearchForm.tsx';
import { usePublicWebSearcherRooms } from '@/state/app-store.ts';

export function PublicWebSearcherPage() {
  const { publicWebSearcherRooms } = usePublicWebSearcherRooms();

  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai | WebSearcher</title>
      </Helmet>
      <div className="w-full h-svh">
        {publicWebSearcherRooms.length ? (
          <WebSearcherPanel webSearcherRooms={publicWebSearcherRooms} />
        ) : (
          <WebSearcherNewSearchForm />
        )}
      </div>
    </>
  );
}
