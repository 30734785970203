import { Button } from 'flowbite-react';
import { CanvasDetails, useGenerateCanvas } from '@/api/canvases/api.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { ToolName } from '../../../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { listenOnCanvasFillerMessage } from '../../../helpers/listen-on-canvas-filler-message.ts';
import { mutate } from 'swr';

export function GenerateCanvasButton({ canvasDetails }: { canvasDetails: CanvasDetails }) {
  const { generateCanvas } = useGenerateCanvas();
  const { showLoader, hideLoader } = useGlobalLoader();

  const onRegenerateClick = async () => {
    showLoader('Generating canvas...');
    const wsChannel = `${ToolName.CANVAS_FILLER}: ${uuid()}`;
    await generateCanvas(canvasDetails.templateId, wsChannel);
    listenOnCanvasFillerMessage(wsChannel, () => {
      mutate(`/strategy-canvas/instance/${canvasDetails.id}`);
      hideLoader();
    });
  };

  return (
    <Button
      color="primary"
      size="sm"
      onClick={onRegenerateClick}
    >
      <img
        src="/regenerate-white.svg"
        alt=""
      />
      Generate
    </Button>
  );
}
