export enum ToolName {
  TEXT_BASE_MODELS = 'TEXT_BASE_MODELS',
  ASK_VISION_MODEL = 'ASK_VISION_MODEL',
  IMAGE_IMAGE_SD_MODELS = 'IMAGE_IMAGE_SD_MODELS',
  UPSCALE_SD_MODELS = 'UPSCALE_SD_MODELS',
  SD_ERASE = 'SD_ERASE',
  SD_INPAINT = 'SD_INPAINT',
  SD_OUTPAINT = 'SD_OUTPAINT',
  SD_SEARCH_AND_REPLACE = 'SD_SEARCH_AND_REPLACE',
  SD_REMOVE_BACKGROUND = 'SD_REMOVE_BACKGROUND',
  SD_SKETCH = 'SD_SKETCH',
  SD_STRUCTURE = 'SD_STRUCTURE',
  SD_STYLE = 'SD_STYLE',
  ASK_INPUT = 'ASK_INPUT',
  CANVAS_FILLER = 'CANVAS_FILLER',
  CANVAS_FILLER_POSITION = 'CANVAS_FILLER_POSITION',
  SMART_SCRAPPER = 'SMART_SCRAPPER',
  RESEARCHER = 'RESEARCHER',
  RESEARCHER_PRE_CANVAS = 'RESEARCHER_PRE_CANVAS',
  GOOGLE_ADS_KEYWORDS_IDEAS = 'GOOGLE_ADS_KEYWORDS_IDEAS',
  SIMILARWEB = 'SIMILARWEB',
  DYNAMIC_RESEARCHER = 'DYNAMIC_RESEARCHER',
  FLUX = 'FLUX',
  PROMPT_IMAGE_SD_MODELS = 'PROMPT_IMAGE_SD_MODELS',
  PROMPT_IMAGE_DALLE3_MODELS = 'PROMPT_IMAGE_DALLE3_MODELS',
  IDEOGRAM_DESCRIBE_IMAGE = 'IDEOGRAM_DESCRIBE_IMAGE',
  PROMPT_IMAGE_IDEOGRAM_MODELS = 'PROMPT_IMAGE_IDEOGRAM_MODELS',
  PERPLEXITY = 'PERPLEXITY',
  TAVILY = 'TAVILY',
  LUMA_PROMPT_TO_VIDEO = 'LUMA_PROMPT_TO_VIDEO',
  LUMA_IMAGE_TO_VIDEO = 'LUMA_IMAGE_TO_VIDEO',
}

export enum ToolFieldInputType {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  FILE = 'file',
  CHECKBOX = 'checkbox',
}

export enum ToolOutputType {
  TEXT = 'text',
  IMAGE = 'image',
  DOCUMENT = 'document',
  WEBSITE = 'website',
  VIDEO = 'video',
  AUDIO = 'audio',
  NULL = 'null',
  CANVAS = 'canvas',
  RESEARCHER = 'researcher',
  SMART_SCRAPPER = 'smart_scrapper',
}

export interface ToolInstancePayload {
  id: number;
  toolName: ToolName;
  currentOutput: ToolOutput;
  outputHistory: ToolHistoryItem[];
}

export interface ToolHistoryItem {
  id: number;
  output: ToolOutput;
}

export type ToolOutput = string | string[] | Record<string, string>;

export interface ToolInputField {
  displayName?: string;
  type: ToolFieldInputType;
  isAdvanced: boolean;
  defaultValue?: string | number | boolean;
  showIf?: Record<string, ShowIdPossibleValues>;
  validation: {
    required?: boolean;
    valueIn?: (string | number)[];
    maxLength?: number;
    minLength?: number;
    allowExtentions?: string[];
    maxFileSize?: number;
    regexp?: string;
  };
  canBeGeneratedBy?: ToolOutputType;
}

export interface ToolConfigInputPayload {
  [key: string]: ToolInputField;
}

export interface ToolConfigPayload {
  input: ToolConfigInputPayload;
  outputType: ToolOutputType;
}

export enum ToolCategory {
  TEXTGEN = 'TEXTGEN',
  IMAGEGEN = 'IMAGEGEN',
  RESEARCHGEN = 'RESEARCHGEN',
  OTHER = 'OTHER', // when the tool does not fit into any of the above categories
}

export type ShowIdPossibleValues = string | number | boolean | File;

export const KnownToolCategories = [ToolCategory.TEXTGEN, ToolCategory.IMAGEGEN, ToolCategory.RESEARCHGEN];

export interface Tool {
  isStreamable?: boolean;
  name: ToolName;
  config: ToolConfigPayload;
  category: ToolCategory;
  displayName: string;
}

export interface ToolFormikFields {
  name: string;
  label: string;
  type: ToolFieldInputType;
  options?: string[];
  disabled?: boolean;
  showIf?: Record<string, ShowIdPossibleValues>;
}

export interface ExecuteToolFieldsPayload {
  key: string;
  value?: string | number | boolean;
  outputFromTool?: number;
}

export interface ExecuteToolPayload {
  id?: number;
  toolName: string;
  fields: ExecuteToolFieldsPayload[];
  wsChannel: string;
}
