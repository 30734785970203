import { useApi } from '@/api/api';

export const useDeletePersona = (): {
  deletePersona: (personaId: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();

  const deletePersona = async (personaId: number) => {
    return deleteFetcher(`/persona-canvas/${personaId}`);
  };

  return {
    deletePersona,
  };
};
