import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useApi } from '@/api/api.ts';
import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';
import useSWR from 'swr';

export const useWebSearcherRooms = (): {
  webSearcherRooms: WebSearcherRoom[];
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const { data, isLoading, error } = useSWR<WebSearcherRoom[]>(
    `/websearcher/rooms/by-workspace/${workspaceId}`,
    getFetcher,
  );

  return {
    webSearcherRooms: data || [],
    isLoading,
    error,
  };
};
