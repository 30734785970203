import { ChatMessage } from '@/models/chat-message.interface.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import useSWR from 'swr';
import { useApi } from '../api.ts';

export const useChatHistory = (): {
  messages: ChatMessage[];
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const { data, isLoading, error } = useSWR(dataRoomId ? `/chat/${dataRoomId}` : null, getFetcher);

  return {
    messages: data,
    isLoading,
    error,
  };
};
