import { FlowTemplateCategory } from '@/models/flow-template.interface';

export const FLOW_CATEGORIES = [
  { label: 'Marketing', value: FlowTemplateCategory.MARKETING },
  { label: 'Communication', value: FlowTemplateCategory.COMMUNICATION },
  { label: 'Execution', value: FlowTemplateCategory.EXECUTION },
  { label: 'Documentation', value: FlowTemplateCategory.DOCUMENTATION },
  { label: 'Analysis', value: FlowTemplateCategory.ANALYSIS },
  { label: 'Research', value: FlowTemplateCategory.RESEARCH },
  { label: 'Strategy', value: FlowTemplateCategory.STRATEGY },
  { label: 'Creative development', value: FlowTemplateCategory.CREATIVE_DEVELOPMENT },
  { label: 'Content creation', value: FlowTemplateCategory.CONTENT_CREATION },
];
