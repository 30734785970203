import Mention from '@tiptap/extension-mention';
import { mentionRender } from './mentionRender';
import { PluginKey } from '@tiptap/pm/state';
import { MutableRefObject } from 'react';
import { Instance, Props } from 'tippy.js';
import { DOMOutputSpec } from 'prosemirror-model';
import { mergeAttributes } from '@tiptap/core';
import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';

export const DataRoomMention = (tippy: MutableRefObject<Instance<Props>[]>, inputs: DataRoomItem[]) => {
  return Mention.extend({ name: MentionReferenceType.DATA_ROOM }).configure({
    HTMLAttributes: {
      class: MentionReferenceType.DATA_ROOM,
    },
    renderText({ options, node }) {
      return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
    },
    renderHTML({ options, node }): DOMOutputSpec {
      return [
        'span',
        mergeAttributes(
          {
            id: node.attrs.id,
            class: 'inline-block px-2 bg-orange-100 rounded text-orange-900 text-body-md',
          },
          options.HTMLAttributes,
        ),
        `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
      ];
    },
    deleteTriggerWithBackspace: true,
    suggestion: {
      char: '$',
      pluginKey: new PluginKey(MentionReferenceType.DATA_ROOM),
      items: async ({ query }: { query: string }) => {
        const parsedItems = inputs.filter((input) => input.isParsed);
        const commands = parsedItems.map((input) => ({
          id: input.id,
          name: input.name,
          label: input.name,
        }));

        return [
          {
            name: 'List',
            title: 'Add to prompt',
            commands: commands.filter(({ name }: { name: string }) =>
              name.toLowerCase().startsWith(query.toLowerCase()),
            ),
          },
        ];
      },
      render: mentionRender(tippy),
    },
  });
};
