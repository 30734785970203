export function ProjectItemPlaceholder() {
  return (
    <>
      <div className="h-28 p-4 rounded-xl bg-gradient-to-l from-white to-[#F7F7F9]">
        <div className="py-1 flex flex-col items-start gap-3">
          <div className="bg-neutrals-300 w-2/3 rounded-3xl h-3"></div>
          <div className="bg-neutrals-300 w-1/2 rounded-3xl h-3"></div>
          <div className="bg-neutrals-300 rounded-full h-6 w-6"></div>
        </div>
      </div>
    </>
  );
}
