import { useApi } from '../api';
import { Flow } from '@/models/flow.interface.ts';

export const useSharingTiptapDocument = (): {
  shareTiptapDocument: (tiptapDocumentId: number) => Promise<Flow>;
  unshareTiptapDocument: (tiptapDocumentId: number) => Promise<Flow>;
} => {
  const { postFetcher } = useApi();

  const shareTiptapDocument = async (tiptapDocumentId: number): Promise<Flow> => {
    return await postFetcher(`/tiptap-document/shared/${tiptapDocumentId}`, { arg: { isShared: true } });
  };

  const unshareTiptapDocument = async (tiptapDocumentId: number): Promise<Flow> => {
    return await postFetcher(`/tiptap-document/shared/${tiptapDocumentId}`, { arg: { isShared: false } });
  };

  return {
    shareTiptapDocument,
    unshareTiptapDocument,
  };
};
