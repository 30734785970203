import { InputsFile } from '@/models/inputs-file.interface.ts';
import { DataRoomItemType } from '@/enums/data-room-item.enum.ts';

export const getAddedDataTypeForFile = (file: InputsFile): DataRoomItemType => {
  if (file.extension.includes('audio')) {
    return DataRoomItemType.AUDIO;
  } else if (file.extension.includes('video')) {
    return DataRoomItemType.VIDEO;
  } else if (file.extension.includes('application')) {
    return DataRoomItemType.DOCUMENT;
  } else {
    return DataRoomItemType.TEXT;
  }
};
