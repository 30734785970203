import { ToolOutput as ToolOutputFinalType, ToolOutputType } from './type.model';
import { useOutputHistory } from './api';
import { Button, Card, HR } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { marked } from 'marked';

export interface ToolOutputProps {
  output: ToolOutputFinalType;
  toolId?: number;
  outputType: ToolOutputType;
  showHistory: boolean;
}

export const ToolOutput = ({ output, toolId, outputType, showHistory = true }: ToolOutputProps) => {
  const { outputs: outputHistory } = useOutputHistory(toolId);
  const [currentOutput, setCurrentOutput] = useState<string | string[] | Record<string, string>>(output);
  const [allOutputs, setAllOutputs] = useState<Array<string | string[] | Record<string, string>>>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentOutput(output);
    setAllOutputs([output, ...outputHistory]);
  }, [output]);

  const setNext = () => {
    if (currentIndex < allOutputs.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentOutput(allOutputs[currentIndex + 1]);
    }
  };

  const setPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentOutput(allOutputs[currentIndex - 1]);
    }
  };

  const getImage = (output: string | string[]) => {
    if (Array.isArray(output)) {
      return output.map((image) => (
        <img
          key={image}
          src={import.meta.env.VITE_ASSETS + image}
          alt="Output image"
        />
      ));
    }

    return (
      <img
        src={import.meta.env.VITE_ASSETS + output}
        alt="Output image"
      />
    );
  };

  const getVideo = (output: string | string[]) => {
    if (Array.isArray(output)) {
      return output.map((video) => (
        <video
          key={video}
          src={import.meta.env.VITE_ASSETS + video}
        />
      ));
    }
  };

  return (
    <>
      <Card>
        {showHistory && (
          <>
            <div className="flex gap-2">
              <Button onClick={setPrev}>Prev</Button>
              <Button onClick={setNext}>Next</Button>
              {/*<Button onClick={() => updateCurrentOutput(toolId as number, currentOutput)}>Set as current</Button>*/}
            </div>
            <HR />
          </>
        )}

        {outputType === ToolOutputType.TEXT && (
          <p dangerouslySetInnerHTML={{ __html: marked(currentOutput as string) }}></p>
        )}
        {outputType === ToolOutputType.CANVAS && <pre>{JSON.stringify(currentOutput, null, 2)}</pre>}
        {outputType === ToolOutputType.IMAGE && getImage(currentOutput as string | string[])}
        {outputType === ToolOutputType.VIDEO && getVideo(currentOutput as string | string[])}
      </Card>
    </>
  );
};
