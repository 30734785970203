import clsx from 'clsx';

export function ColorPalette({ colors, bgColor }: { colors: string[]; bgColor: string }) {
  return (
    <div
      className="flex relative"
      style={{ translate: `${(colors.length - 2) * 8}px` }}
    >
      {colors.map((color, index) => (
        <div
          key={index}
          className={clsx('w-8 h-8 rounded-full border-[2px]')}
          style={{
            backgroundColor: color,
            translate: `-${index * 8}px`,
            borderColor: bgColor,
          }}
        />
      ))}
    </div>
  );
}
