import { useApi } from '../api';

export const useUpdateDataTiptapDocument = () => {
  const { postFetcher } = useApi();

  const updateDataTiptapDocument = async (tiptapDocumentId: number, data: string): Promise<boolean> => {
    return await postFetcher(`/tiptap-document/update-data/${tiptapDocumentId}`, { arg: { data } });
  };

  return {
    updateDataTiptapDocument,
  };
};
