import { useDataRoomId } from '@/state/app-store.ts';
import { useApi } from '../api.ts';

export function useSendMessage(): {
  sendMessage: (question: string, wsChannel: string) => Promise<void>;
} {
  const { postFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const sendMessage = (question: string, wsChannel: string) =>
    postFetcher('/chat/askAI', {
      arg: {
        dataRoomId,
        question,
        wsChannel,
      },
    });

  return {
    sendMessage,
  };
}
