import useSWR from 'swr';
import { useApi } from '../api';

export const useTemplateFlowAccess = () => {
  const { getFetcher } = useApi();
  const { data, isLoading, error } = useSWR<{ name: string; owner: string }[]>(`/flow/template-access`, getFetcher);

  return {
    templateAccess: data,
    isLoading,
    error,
  };
};
