export const buildUrl = (
  urls: string[],
  params?: { [k: string]: string | number },
  queryParams?: { [k: string]: string | number },
  relative = false,
) => {
  let url = urls.join('/');

  if (!relative) {
    url = url.startsWith('/') ? url : `/${url}`;
  }

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      // first try to replace optional param with '?'
      url = url.replace(`:${key}?`, value?.toString() ?? '');
      // then try to replace required param
      url = url.replace(`:${key}`, value?.toString() ?? '');
    });
  }

  if (queryParams) {
    const queryParamsString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    url = `${url}?${queryParamsString}`;
  }

  return url;
};
