import { useCurrentWorkspace } from '@/state/app-store.ts';
import useSWR from 'swr';
import { useApi } from '../api.ts';
import { Project } from '@/models/project.interface.ts';

export const useProjects = (): {
  projects: Project[] | undefined;
  projectsById: Project[] | undefined;
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();
  const currentWorkspace = useCurrentWorkspace();

  const { data, isLoading, error } = useSWR<Project[]>(
    currentWorkspace?.id ? `/project/by-workspace/${currentWorkspace?.id}` : null,
    getFetcher,
  );

  return {
    projects: data ? [...data]?.sort((a, b) => b.modifiedAt.localeCompare(a.modifiedAt)) : [],
    projectsById: data ? [...data]?.sort((a, b) => b.id - a.id) : [],
    isLoading,
    error,
  };
};
