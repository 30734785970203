import { useApi } from '@/api/api.ts';
import { useDataRoomId } from '@/state/app-store.ts';

/**
 * workaround for tiptap, because tiptap is not hook based
 */
export const useTiptapResourceSummary = () => {
  const { getFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const tiptapResourceSummary = async (fileId: string) => {
    return (await getFetcher(`/inputs/dataRoom/${dataRoomId}/resource-summary/${fileId}`)) as unknown as string;
  };

  return {
    tiptapResourceSummary,
  };
};
