import { Button } from 'flowbite-react';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';

export function CreateBlankFlowTemplateButton() {
  const { openModal } = useGlobalModal();

  return (
    <Button
      size="sm"
      color="primary"
      onClick={() => openModal(MODALS.CreateTemplateFlow)}
    >
      <img
        src="/add-white.svg"
        alt=""
      />
      Blank template
    </Button>
  );
}
