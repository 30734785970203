import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { v4 as uuid } from 'uuid';
import { DFUserInputView } from './components/DFUserInputView';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Fragment } from 'prosemirror-model';

import { Node as ProsemirrorNode } from 'prosemirror-model';

export const DFUserInput = Node.create({
  name: 'DFUserInput',

  group: 'block',
  content: 'block+',

  defining: true,
  atom: false,
  isolating: true,

  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: uuid(),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('dfUserInputEventHandler'),
        props: {
          handlePaste(_view, _event, slice) {
            const content: ProsemirrorNode[] = [];
            slice.content.forEach((node) => {
              if (node.type.name === 'dfGenOutput' || node.type.name === 'DFUserInput') {
                content.push(Fragment.from(node.content) as unknown as ProsemirrorNode);
              }
            });

            (slice as unknown as { content: Fragment }).content = Fragment.from(content);
          },
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      addUserInput:
        () =>
        ({ chain }) =>
          chain()
            .insertContent([
              {
                type: 'heading',
                content: [
                  {
                    type: 'text',
                    text: 'Your heading',
                  },
                ],
                attrs: {
                  level: 1,
                  id: uuid(),
                },
              },

              {
                type: this.name,
                content: [
                  {
                    type: 'paragraph',
                  },
                ],
                attrs: {
                  id: uuid(),
                },
              },
            ])
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(DFUserInputView);
  },
});
