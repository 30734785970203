import { llmMarked } from '@/helpers/llmMarked';
import { ToolCategory, ToolName, ToolOutput } from 'src/libs/tools/type.model';
import { HtmlElementToString } from './HtmlElementToString';
import { Output, researchgenOutputFormater } from '@/helpers/researchgen-output-formater';

export const parseOutput = (output: ToolOutput, toolCategory: ToolCategory, toolName: ToolName): string => {
  if (!output) return '';

  switch (toolCategory) {
    case ToolCategory.TEXTGEN: {
      return llmMarked(output as string) as string;
    }

    case ToolCategory.IMAGEGEN: {
      let images = `<div>`;

      if (toolName.includes('VIDEO')) {
        (output as string[]).forEach((element) => {
          const video = document.createElement('video');
          video.src = import.meta.env.VITE_ASSETS + element;
          images += HtmlElementToString(video);
        });
      } else {
        (output as string[]).forEach((element) => {
          const img = document.createElement('img');
          img.src = import.meta.env.VITE_ASSETS + element;
          img.alt = '';
          images += HtmlElementToString(img);
        });
      }

      return (images += '</div>');
    }

    case ToolCategory.RESEARCHGEN:
      return `<div>${researchgenOutputFormater(output as unknown as Output, toolName)}</div>`;
  }

  return '';
};
