import useSWR from 'swr';
import { useApi } from '../api.ts';
import { User } from '@/models/user.interface.ts';
import { useAuth0 } from '@auth0/auth0-react';

export const useOrganizationMembers = (): {
  organizationMembers: User[];
  isLoading: boolean;
  error: unknown;
} => {
  const { user } = useAuth0();
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR('org_id' in (user ?? {}) ? '/organization/members' : null, getFetcher);

  return {
    organizationMembers: data,
    isLoading,
    error,
  };
};
