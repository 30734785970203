import { Tooltip } from 'react-tooltip';
import './DfTooltip.scss';

interface TooltipProps {
  id: string;
  content: string;
  place: 'top' | 'right' | 'bottom' | 'left';
  delay?: number;
}

export function DfTooltip({ id, content, place, delay = 300 }: TooltipProps) {
  return (
    <>
      <div className="df-tooltip-container">
        <Tooltip
          id={id}
          className="df-tooltip"
          content={content}
          delayShow={delay}
          place={place}
          positionStrategy="fixed"
          opacity={1}
        />
      </div>
    </>
  );
}
