'use client';
import { EditorContent, PureEditorContent } from '@tiptap/react';
import { RefObject, useContext, useEffect, useMemo, useRef } from 'react';

import { ContentItemMenu, LinkMenu } from '@/components/tiptap/components/menus';

import { useBlockEditor } from '@/components/tiptap/hooks/useBlockEditor';

import '@/components/tiptap/styles/index.css';

import { Sidebar } from '@/components/tiptap/components/Sidebar';
import { Loader } from '@/components/tiptap/components/ui/Loader';
import { EditorContext, IEditorContext } from '@/components/tiptap/context/EditorContext';
import ImageBlockMenu from '@/components/tiptap/extensions/ImageBlock/components/ImageBlockMenu';
import { ColumnsMenu } from '@/components/tiptap/extensions/MultiColumn/menus';
import { TableColumnMenu, TableRowMenu } from '@/components/tiptap/extensions/Table/menus';
import { useAIState } from '@/components/tiptap/hooks/useAIState';
import { createPortal } from 'react-dom';
import { TiptapProps } from './types';
import { EditorHeader } from './components/EditorHeader';
import { TextMenu } from '../menus/TextMenu';
import { ArrowUpDown } from 'lucide-react';

import { clsx } from 'clsx';
import {
  getDisableAutoScrolling,
  setDisableAutoScrolling,
} from '@/components/tiptap/extensions/DigitalFirst/helpers/smoothScroll';
import { IconButton } from '@/components/IconButton';
import AddPanel from '../AddPanel/AddPanel';
import { useExecuteAllFields } from '../../extensions/DigitalFirst/helpers/executeField';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import { ToolsContext } from '../../../../../libs/tools/ToolsContext.tsx';
import {
  openConfigurationEditor,
  useMemoryContextSidebar,
  useTiptapCurrentView,
} from '../../extensions/DigitalFirst/store.ts';
import { MemoryContextTiptapSidebar } from '@/components/tiptap/components/MemoryContextSidebar/MemoryContextTiptapSidebar.tsx';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum.ts';
import { WebSocketStatus } from '@hocuspocus/provider';

export const BlockEditor = ({ ydoc, provider, editable }: TiptapProps) => {
  const aiState = useAIState();
  const menuContainerRef = useRef(null);
  const editorRef = useRef<PureEditorContent | null>(null);
  const { user } = useAuth0();
  const [searchParams] = useSearchParams();

  const toolName = searchParams.get('toolName');
  const tools = useContext(ToolsContext)?.tools;
  const toolCategory = tools?.find((tool) => tool.name === toolName)?.category;
  const tiptapCurrentView = useTiptapCurrentView();
  const { isMemoryContextSidebarOpen, toggleMemoryContextSidebar } = useMemoryContextSidebar();

  const { editor, users, characterCount, collabState, leftSidebar } = useBlockEditor({ ydoc, provider, editable });
  const { isExecute, executeAll } = useExecuteAllFields();

  const displayedUsers = users.slice(0, 3);

  const providerValue = useMemo(() => {
    return {
      isAiLoading: aiState.isAiLoading,
      aiError: aiState.aiError,
      setIsAiLoading: aiState.setIsAiLoading,
      setAiError: aiState.setAiError,
    } as IEditorContext;
  }, [aiState]);

  useEffect(() => {
    if (toolName) {
      editor?.chain().focus().addGenOuput({ toolcategory: toolCategory }).run();
      const nodes = editor?.$nodes('dfGenOutput');

      if (nodes?.length) {
        const tool = tools?.find((tool) => tool.name === toolName);
        nodes[0].setAttribute({ selectedtool: tool });
        openConfigurationEditor(nodes[0].attributes.id);
      }
    }
  }, [editor, toolName]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(collabState === WebSocketStatus.Connected);
    }
  }, [collabState, editor]);

  useEffect(() => {
    const color = Math.floor(Math.random() * 16777215)?.toString(16);
    editor?.commands.updateUser({
      name: user?.name,
      color: color,
      avatar: user?.picture,
    });
  }, [!!editor]);

  if (!editor) {
    return null;
  }

  const showEnableAutoScrollingButton = isExecute && getDisableAutoScrolling();

  const aiLoaderPortal = createPortal(<Loader label="AI is now doing its job." />, document.body);

  return (
    <EditorContext.Provider value={providerValue}>
      <div
        className="flex h-full bg-gray-ultra-light flex-col z-0"
        ref={menuContainerRef}
      >
        <div>
          <EditorHeader
            characters={characterCount.characters()}
            collabState={collabState}
            users={displayedUsers}
            words={characterCount.words()}
            editorRef={editorRef}
            editor={editor}
            executeAll={executeAll}
          />
        </div>
        <div className="flex mr-3 mb-3 grow h-full min-h-0 bg-white rounded-2xl relative">
          <div className="absolute left-3 top-3 w-8 h-8 z-20">
            <IconButton
              icon="/sidebar-black.svg"
              onClick={() => leftSidebar.open()}
            />
          </div>
          <div className="flex z-30">
            <Sidebar
              isOpen={leftSidebar.isOpen}
              onClose={leftSidebar.close}
              editor={editor}
            />
          </div>
          <div className="relative flex flex-col flex-1 h-full overflow-hidden ">
            {tiptapCurrentView === TiptapView.CONFIGURATION_MODE && (
              <div className="absolute left-3 top-0 bottom-0 flex items-center">
                <AddPanel editor={editor} />
              </div>
            )}
            <EditorContent
              editor={editor}
              ref={editorRef as unknown as RefObject<HTMLDivElement>}
              className="flex-1 overflow-y-auto"
            />
            <div
              className={clsx('absolute bottom-[50px] w-full flex justify-center', {
                hidden: !showEnableAutoScrollingButton,
              })}
            >
              <button
                onClick={() => setDisableAutoScrolling(false)}
                className="rounded-lg p-2 py-2 bg-[#2e4fff] text-white"
              >
                <ArrowUpDown size={24} />
              </button>
            </div>
            <ContentItemMenu editor={editor} />
            <LinkMenu
              editor={editor}
              appendTo={menuContainerRef}
            />
            <TextMenu editor={editor} />
            <ColumnsMenu
              editor={editor}
              appendTo={menuContainerRef}
            />
            <TableRowMenu
              editor={editor}
              appendTo={menuContainerRef}
            />
            <TableColumnMenu
              editor={editor}
              appendTo={menuContainerRef}
            />
            <ImageBlockMenu
              editor={editor}
              appendTo={menuContainerRef}
            />
          </div>

          <MemoryContextTiptapSidebar
            isOpen={isMemoryContextSidebarOpen}
            toggle={toggleMemoryContextSidebar}
            editor={editor}
          />
        </div>
      </div>
      {aiState.isAiLoading && aiLoaderPortal}
    </EditorContext.Provider>
  );
};

export default BlockEditor;
