import { Flow } from '@/models/flow.interface.ts';
import { useApi } from '@/api/api.ts';
import useSWR from 'swr';

export const useMyFlow = (
  flowId: number,
): {
  myFlow: Flow;
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR(`/flow/my-flow/${flowId}`, getFetcher);

  return {
    myFlow: data,
    isLoading,
    error,
  };
};
