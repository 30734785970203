import { createBrowserRouter, Outlet } from 'react-router-dom';
import { Dashboard } from './routes/dashboard/Dashboard.tsx';
import { DataPanel } from './routes/data/DataPanel.tsx';
import { App } from './routes/App.tsx';
import { Urls } from '@/consts/urls.ts';
import { CanvasPanel } from './routes/canvases/CanvasPanel.tsx';
import { MyCanvases } from './routes/canvases/routes/my-canvases/MyCanvases.tsx';
import { CanvasLibrary } from './routes/canvases/routes/canvas-library/CanvasLibrary.tsx';
import { CanvasDetails } from './routes/canvases/routes/canvas-deatils/CanvasDetails.tsx';
import { ToolsPipelineTestView } from './libs/tools-pipeline/ToolsPipelineTestView.tsx';
import { FlowEditor } from './routes/flows/FlowEditor.tsx';
import { ProjectListPanel } from './routes/projects/ProjectListPanel.tsx';
import { ProjectDetails } from './routes/projects/routes/project-details/ProjectDetails.tsx';
import { FlowsLibrary } from './routes/projects/routes/flows-library/FlowsLibrary.tsx';
import { ToolsPanel } from './routes/tools/ToolsPanel.tsx';
import { FlowTemplateEditor } from './routes/flows/FlowTemplateEditor.tsx';
import { WebSearcherPage } from './routes/web-searcher/WebSearcherPage.tsx';
import { UserSettings } from './routes/settings/UserSettings.tsx';
import { OrganizationSettings } from './routes/settings/OrganizationSettings.tsx';
import { OrganizationMembers } from './routes/settings/OrganizationMembers.tsx';
import { WorkspaceSettings } from './routes/workspace/WorkspaceSettings.tsx';
import { WorkspaceMembers } from './routes/workspace/WorkspaceMembers.tsx';
import { AuthorizedApp } from './routes/AuthorizedApp.tsx';
import { PublicApp } from './routes/public/PublicApp.tsx';
import { PublicWebSearcherPage } from './routes/public/web-searcher/PublicWebSearcherPage.tsx';
import { WorkspacesListPanel } from './routes/workspaces/WorkspacesPage.tsx';
import { PreCanvas } from './routes/pre-canvas/PreCanvas.tsx';
import { SegmentsPanel } from './routes/segments/SegmentsPanel.tsx';
import { PersonaDetailsPage } from './routes/segments/persona/PersonaDetailsPage.tsx';

export const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '',
        element: <AuthorizedApp />,
        children: [
          {
            path: Urls.SHARED,
            children: [
              {
                path: Urls.FLOW_EDITOR,
                element: <FlowEditor />,
              },
            ],
          },
          {
            path: Urls.TOOLS_PIPELINE,
            element: <ToolsPipelineTestView />,
          },
          {
            path: Urls.DASHBOARD,
            element: <Dashboard />,
          },
          {
            path: Urls.PRE_CANVAS_TEST,
            element: <PreCanvas />,
          },
          {
            path: Urls.WORKSPACES,
            element: <WorkspacesListPanel />,
          },
          {
            path: Urls.DATA_PANEL,
            element: <DataPanel />,
          },
          {
            path: Urls.WEB_SEARCHER,
            element: <WebSearcherPage />,
          },
          {
            path: Urls.WEB_SEARCHER_ROOM,
            element: <WebSearcherPage />,
          },
          {
            path: Urls.CANVASES,
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <CanvasPanel />,
              },
              {
                path: Urls.CANVAS,
                element: <CanvasDetails />,
              },
              {
                path: Urls.MY_CANVASES,
                element: <MyCanvases />,
              },
              {
                path: Urls.CANVAS_LIBRARY,
                element: <CanvasLibrary />,
              },
            ],
          },
          {
            path: Urls.SEGMENTS,
            children: [
              {
                path: '',
                element: <SegmentsPanel />,
              },
              {
                path: Urls.PERSONA_DETAILS,
                element: <PersonaDetailsPage />,
              },
            ],
          },
          {
            path: Urls.USER_SETTINGS,
            element: <UserSettings />,
          },
          {
            path: Urls.ORGANIZATION_SETTINGS,
            element: <OrganizationSettings />,
          },
          {
            path: Urls.ORGANIZATION_MEMBERS,
            element: <OrganizationMembers />,
          },
          {
            path: Urls.WORKSPACE_SETTINGS,
            element: <WorkspaceSettings />,
          },
          {
            path: Urls.WORKSPACE_MEMBERS,
            element: <WorkspaceMembers />,
          },
          {
            path: Urls.PROJECTS,
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <ProjectListPanel />,
              },
              {
                path: Urls.PROJECT,
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <ProjectDetails />,
                  },
                  {
                    path: Urls.FLOW_EDITOR,
                    element: <FlowEditor />,
                  },
                  {
                    path: Urls.FLOWS_LIBRARY,
                    element: <FlowsLibrary />,
                  },
                  {
                    path: Urls.TOOLS_LIBRARY,
                    element: <ToolsPanel />,
                  },
                ],
              },
            ],
          },
          {
            path: Urls.TOOLS_LIBRARY,
            element: <ToolsPanel />,
          },
          {
            path: Urls.FLOW_TEMPLATE_EDITOR,
            element: <FlowTemplateEditor />,
          },
        ],
      },
      {
        path: Urls.PUBLIC,
        element: <PublicApp />,
        children: [
          {
            path: Urls.WEB_SEARCHER,
            element: <PublicWebSearcherPage />,
          },
          {
            path: Urls.WEB_SEARCHER_ROOM,
            element: <PublicWebSearcherPage />,
          },
        ],
      },
    ],
  },
]);
