import { useWebSocketMessageListener } from '@/useOnWebSocketMessage';
import { Editor, Mark } from '@tiptap/core';
import { useContext } from 'react';
import { useExecuteTool } from 'src/libs/tools/api';
import { ToolsContext } from 'src/libs/tools/ToolsContext';
import { ToolName } from 'src/libs/tools/type.model';
import { useTiptapToolsByCategory } from '../../../../../libs/tools/use-tiptap-tools-by-category.tsx';
import { v4 as uuid } from 'uuid';
import { llmMarked } from '@/helpers/llmMarked.ts';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiTools: {
      aiSimplify: () => ReturnType;
      aiEmojify: () => ReturnType;
      aiComplete: () => ReturnType;
      aiFixSpellingAndGrammar: (editor: Editor) => ReturnType;
      aiExtend: () => ReturnType;
      aiShorten: () => ReturnType;
      aiTldr: () => ReturnType;
      aiAdjustTone: (tone: string) => ReturnType;
      aiTranslate: (language: string) => ReturnType;
    };
  }
}

export const AiTools = () => {
  const allTools = useContext(ToolsContext);
  const toolsByCategory = useTiptapToolsByCategory(allTools!.tools!);
  const { executeTool } = useExecuteTool();
  const { listenerOn } = useWebSocketMessageListener();

  async function requestPrompt(prompt: string) {
    const tool = toolsByCategory.TEXTGEN.find((tool) => tool.name === ToolName.TEXT_BASE_MODELS);
    const wsChannel = `${tool?.name}: ${uuid()}`;
    let response = '';

    try {
      await executeTool({
        toolName: tool!.name,
        wsChannel,
        fields: [
          {
            key: 'prompt',
            value: prompt,
          },
          {
            key: 'model',
            value: 'gpt-4o',
          },
        ],
      });
    } catch {
      return ''; // error is handled by WebSocketErrorListener
    }

    await new Promise((resolve) => {
      const { listenerOff } = listenerOn(wsChannel, (data: string) => {
        if (typeof data === 'string') {
          response += data;
        } else {
          listenerOff();
          resolve(true);
        }
      });
    });

    return llmMarked(response);
  }

  function getSelectionText(editor: Editor) {
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    return state.doc.textBetween(from, to, '\n');
  }

  function replaceSelectionText(editor: Editor, text: string) {
    const { view } = editor;
    const { from, to } = view.state.selection;
    view.dispatch(view.state.tr.replaceWith(from, to, editor.state.schema.text(text)).scrollIntoView());
  }

  return Mark.create({
    name: 'aiTools',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    addCommands() {
      return {
        aiSimplify: () => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `Simplify this text: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
        aiEmojify: () => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `Emojify this text: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
        aiComplete: () => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `Complete this text: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
        aiFixSpellingAndGrammar: () => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `Fix spelling and grammar in this text: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
        aiExtend: () => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `Extend this text: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
        aiShorten: () => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `Shorten this text: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
        aiTldr: () => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `TLDR this text: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
        aiAdjustTone: (tone: string) => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `Adjust tone to ${tone} in this text: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
        aiTranslate: (language: string) => async (editor: Editor) => {
          const selectedText = getSelectionText(editor);
          const prompt = `Translate this text to ${language}: ${selectedText}`;
          const response = await requestPrompt(prompt);

          replaceSelectionText(editor, response);
        },
      };
    },
  });
};
