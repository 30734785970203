import { useCallback, useEffect, useState } from 'react';
import { setCanvasSectionProcessingState } from '@/api/canvases/store.ts';
import { ToolName } from '../../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { mutate } from 'swr';
import { Card } from 'flowbite-react';
import { IconButton } from '@/components/IconButton.tsx';
import ContentEditableListEditor from '../../../canvases/routes/canvas-deatils/components/ContentEditableList.tsx';
import { PersonaPosition } from '@/models/persona-position.interface.ts';
import { PersonaDetails } from '@/models/persona-details.interface.ts';
import { setPersonaPositionrocessingState, usePersonaPositionProcessingStatus } from '../persona.store.ts';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useGeneratePersonaPosition } from '@/api/segments/persona/generate-persona-position.ts';
import { useUpdatePersona } from '@/api/segments/persona/update-persona.ts';
import { PersonaUpdatePayload } from '@/models/persona-update-payload.interface.ts';

export const PersonaPositionCard = ({
  personaDetails,
  position,
}: {
  personaDetails: PersonaDetails;
  position: PersonaPosition;
}) => {
  const [descriptions, setDescriptions] = useState<string[]>([]);
  const [editedDescriptions, setEditedDescriptions] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const isProcessing = usePersonaPositionProcessingStatus(personaDetails.id, position.id);
  const { listenerOn } = useWebSocketMessageListener();
  const { generatePersonaPosition } = useGeneratePersonaPosition();
  const { updatePersona } = useUpdatePersona();

  useEffect(() => {
    setDescriptions(position.value);
  }, [position.value]);

  const onDescriptionsChange = useCallback((newDescriptions: string[]) => {
    setEditedDescriptions(newDescriptions);
  }, []);

  const [borderColor] = useState('#F8F7FE');
  const [circleColor] = useState('#4B30F0');

  const onSave = async () => {
    const newPositions = personaDetails.positions.filter((p) => p.id !== position.id);
    newPositions.push({
      ...position,
      value: editedDescriptions,
    });
    const payload: PersonaUpdatePayload = {
      positionValue: newPositions
        .filter((p) => p.value && p.value.length > 0)
        .flatMap((p) => p.value.map((v) => ({ positionTemplateId: p.id, value: v }))),
    };
    setPersonaPositionrocessingState(personaDetails.id, position.id, true);
    await updatePersona(personaDetails.id, payload);
    setPersonaPositionrocessingState(personaDetails.id, position.id, false);
    setIsEditing(false);
    setDescriptions(editedDescriptions);
  };

  const onRegenerate = async () => {
    setPersonaPositionrocessingState(personaDetails.id, position.id, true);
    const wsChannel = `${ToolName.CANVAS_FILLER_POSITION}: ${uuid()}`;

    generatePersonaPosition(personaDetails.id, position.id, wsChannel);

    const { listenerOff } = listenerOn(wsChannel, async () => {
      await mutate(`/persona-canvas/${personaDetails.id}`);
      setCanvasSectionProcessingState(personaDetails.id, position.id, false);
      listenerOff();
    });
  };

  return (
    <>
      <Card className="relative">
        {isProcessing && (
          <div className="absolute top-0 left-0 w-full h-full z-10 bg-white opacity-80 flex items-center justify-center">
            <img
              src="/loading-blue.svg"
              className="animate-spin-slow"
              alt=""
            />
          </div>
        )}

        <div className="flex justify-between items-center w-full">
          <h2 className="text-subtitle-md">{position.label}</h2>
          <div className="flex gap-2">
            {isEditing ? (
              <>
                <IconButton
                  icon="/close-gray.svg"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                />
                <IconButton
                  icon="/approve-gray.svg"
                  onClick={onSave}
                />
              </>
            ) : (
              <>
                <IconButton
                  icon="/regenerate-gray.svg"
                  onClick={onRegenerate}
                />
                <IconButton
                  icon="/edit-gray.svg"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
              </>
            )}
          </div>
        </div>

        {isEditing && (
          <div className="mt-4 w-full">
            <ContentEditableListEditor
              descriptions={descriptions}
              onDescriptionsChange={onDescriptionsChange}
            />
          </div>
        )}
        {!isEditing && descriptions.length !== 0 && (
          <ul className="mt-4">
            {descriptions.map((item, index) => (
              <li
                key={index}
                className={`
              relative pl-6 mb-4
              before:content-['']
              before:absolute before:left-0 before:top-[6px]
              before:w-[14px] before:h-[14px]
              before:border-[3px]
              before:rounded-full
              before:bg-[var(--tw-before-bg-color)]
              before:border-[var(--tw-before-border-color)]
              text-body-md
              text-text-secondary
            `}
                style={{
                  ['--tw-before-border-color' as never]: borderColor,
                  ['--tw-before-bg-color' as never]: circleColor,
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </Card>
    </>
  );
};
