import { create } from 'zustand';

interface PersonaState {
  processingPersonaPositions: {
    [personaIdWithPositionId: string]: boolean;
  };
}

const usePersonaStore = create<PersonaState>(() => ({
  processingPersonaPositions: {},
}));

export const setPersonaPositionrocessingState = (personaId: number, positionId: number, isProcessing: boolean) => {
  usePersonaStore.setState((state) => ({
    processingPersonaPositions: {
      ...state.processingPersonaPositions,
      [`${personaId}:${positionId}`]: isProcessing,
    },
  }));
};

export const usePersonaPositionProcessingStatus = (personaId: number, positionId: number) =>
  usePersonaStore((state) => state.processingPersonaPositions[`${personaId}:${positionId}`]);
