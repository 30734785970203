import { useApi } from '../api.ts';
import { Flow } from '@/models/flow.interface.ts';

export const usePinFlowToProject = (): {
  pinFlowToProject: (flowId: number, projectId: number) => Promise<Flow>;
} => {
  const { postFetcher } = useApi();

  const pinFlowToProject = async (flowId: number, projectId: number) => {
    return postFetcher(`/flow/pin-to-project`, {
      arg: {
        flowId,
        projectId,
      },
    });
  };

  return { pinFlowToProject };
};
