import { useEffect, useRef } from 'react';
import { EditorState, Selection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Schema } from 'prosemirror-model';
import { keymap } from 'prosemirror-keymap';
import { baseKeymap } from 'prosemirror-commands';

import './ContentEditableList.scss';

const listSchema = new Schema({
  nodes: {
    doc: {
      content: 'list',
    },
    list: {
      content: 'list_item+',
      toDOM: () => ['ul', { class: 'list-disc pl-4 text-text-secondary text-sm' }, 0],
      parseDOM: [{ tag: 'ul' }],
    },
    list_item: {
      content: 'text*',
      toDOM: () => ['li', { class: 'pl-2 my-2' }, 0],
      parseDOM: [{ tag: 'li' }],
    },
    text: {
      inline: true,
    },
  },
});

interface ContentEditableListEditorProps {
  descriptions?: string[];
  onDescriptionsChange?: (newDescriptions: string[]) => void;
}

const ContentEditableListEditor = ({ descriptions = [], onDescriptionsChange }: ContentEditableListEditorProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null);

  const initialState = useRef(
    EditorState.create({
      schema: listSchema,
      plugins: [keymap(baseKeymap)],
      doc:
        descriptions.length > 0
          ? listSchema.node('doc', null, [
              listSchema.node(
                'list',
                null,
                descriptions.map((desc) => listSchema.node('list_item', null, listSchema.text(desc))),
              ),
            ])
          : undefined,
    }),
  );

  useEffect(() => {
    const view = new EditorView(editorRef.current, {
      state: initialState.current,
      dispatchTransaction(transaction) {
        const newState = view.state.apply(transaction);
        view.updateState(newState);
        onDescriptionsChange?.(
          newState
            .toJSON()
            ?.doc?.content[0].content.map(
              (node: { content: { text: string }[] }) =>
                node?.content && node.content.length > 0 && node.content[0].text,
            )
            .filter((text: never) => text),
        );
      },
    });

    view.focus();
    view.dispatch(view.state.tr.setSelection(Selection.atEnd(view.state.doc)));

    return () => {
      if (view) {
        view.destroy();
      }
    };
  }, [onDescriptionsChange]);

  return (
    <div className="prose-mirror-editor">
      <div ref={editorRef} />
    </div>
  );
};

export default ContentEditableListEditor;
