import { Badge, Button, Table } from 'flowbite-react';
import { RecommendedToolsSection } from '../projects/components/RecommendedToolsSection.tsx';
import { Link } from 'react-router-dom';
import { Urls } from '@/consts/urls.ts';
import { ProjectCard } from '../projects/components/ProjectCard.tsx';
import { useProjects } from '@/api/projects/get-projects.ts';
import { DashboardSearch } from './components/DashboardSearch.tsx';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { CreateProjectButton } from './components/CreateProjectButton.tsx';

export function Dashboard() {
  const { projects, isLoading } = useProjects();

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [{ label: 'Dashboard', href: Urls.DASHBOARD }],
        icon: '/dashboard-black.svg',
        rightAddons: [<CreateProjectButton key="create-project-button" />],
      }}
    >
      <section
        className="w-full pt-16 pb-10 flex items-center justify-center rounded-3xl relative flex-col"
        style={{ backgroundImage: 'url(/gradient-wide.svg)', backgroundSize: 'cover' }}
      >
        <div
          className="w-full h-full rounded-3xl absolute top-0 left-0 -z-10"
          style={{ backgroundImage: 'url(/noise-wide.svg)' }}
        ></div>
        <div className="flex flex-col gap-4 text-center w-full items-center">
          <h1 className="text-h1-desktop">What do you want to achieve today?</h1>
          <div className="flex w-full max-w-[600px]">
            <DashboardSearch />
          </div>

          <div className="text-body-md text-text-tertiary">Work with AI strategy, assets, creation, and more.</div>
        </div>
        <div className="mt-7 bg-primary-100 rounded-xl p-4 mx-5">
          <RecommendedToolsSection />
        </div>
      </section>

      {!isLoading && !!projects?.length && (
        <section className="mt-7">
          <div className="flex items-center justify-between">
            <h2 className="text-h3-desktop">Recent projects</h2>
            <Link
              to={Urls.PROJECTS}
              className="link link--lg"
            >
              View all
              <img
                src="/arrow-right-blue.svg"
                alt=""
              />
            </Link>
          </div>

          <div className="grid grid-cols-4 gap-4 mt-5">
            {projects.slice(0, 4).map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
              />
            ))}
          </div>
        </section>
      )}

      <section className="mt-7">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mb-4">
            <h2 className="text-h3-desktop">Recommended workflows</h2>
            <Badge
              color="primary"
              size="lg"
            >
              Coming soon!
            </Badge>
          </div>
          <a className="link link--lg">
            View all
            <img
              src="/arrow-right-blue.svg"
              alt=""
            />
          </a>
        </div>

        <div className="opacity-[.64] pointer-events-none">
          <Table>
            <Table.Head>
              <Table.HeadCell className="w-[60%]">Workflow name</Table.HeadCell>
              <Table.HeadCell>Using</Table.HeadCell>
              <Table.HeadCell>
                <span className="sr-only">Generate</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {['Entering a new foreign market', 'Launching a new service', 'Enhancing brand awareness'].map(
                (item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell className="flex items-center gap-3">
                      <img
                        className="p-2.5 rounded-lg bg-purple-dark"
                        src="/interests-black.svg"
                        alt=""
                      />
                      <span className="text-text-primary text-h5-desktop overflow-hidden grow text-ellipsis whitespace-nowrap max-w-full">
                        {item}
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="flex items-center gap-1">
                        <Badge color="blue">Campaign</Badge>
                        <Badge color="green">Flows</Badge>
                        <Badge color="yellow">Creation</Badge>
                      </div>
                    </Table.Cell>
                    <Table.Cell align="right">
                      <Button
                        color="secondary"
                        gradientMonochrome="yellow"
                      >
                        <img
                          src="/play-yellow.svg"
                          alt=""
                        />
                        Run
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </div>
      </section>
    </PageWithHeaderLayout>
  );
}
