import { IconButton } from '@/components/IconButton.tsx';
import { Button, Dropdown } from 'flowbite-react';
import { MenuItem } from '@/components/MenuItem.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '@/components/tiptap/lib/utils';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useDeleteWebSearcherRoom } from '@/api/websearcher/delete-web-searcher-room.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { usePublicWebSearcherRooms } from '@/state/app-store.ts';
import { useWebSearcherMenuStore } from '../web-searcher.store.ts';

export function WebSearcherPanelMenu({ webSearcherRooms }: { webSearcherRooms: WebSearcherRoom[] }) {
  const { isMenuOpen, setIsMenuOpen } = useWebSearcherMenuStore();
  const navigate = useNavigate();
  const { roomId } = useParams();
  const currentRoom = webSearcherRooms.find((room) => room.id === Number(roomId));
  const { openDialog } = useGlobalDialog();
  const { deleteWebSearcherRoom } = useDeleteWebSearcherRoom();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { isAuthenticated } = useAuth0();
  const { setPublicWebSearcherRooms } = usePublicWebSearcherRooms();

  const onRoomDelete = (roomId: number) => {
    openDialog(DIALOGS.DeleteWebSearcherRoom, async () => {
      showLoader('Deleting...');

      if (isAuthenticated) {
        await deleteWebSearcherRoom(roomId);
      } else {
        setPublicWebSearcherRooms(webSearcherRooms.filter((room) => room.id !== roomId));
      }

      hideLoader();
    });
  };

  return (
    <div className="z-50 relative">
      <IconButton
        icon="/sidebar-black.svg"
        className={cn('absolute top-5 right-2 z-10', { 'rotate-180': isMenuOpen }, { '!top-2 !-right-8': !isMenuOpen })}
        size={24}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />

      <div
        className={cn(
          'w-[17.5rem] overflow-x-hidden border-r border-b-neutrals-300 z-0 transition-[width] duration-300',
          {
            'w-0 border-none': !isMenuOpen,
          },
        )}
      >
        <div className="w-[17.5rem] h-[calc(100svh-66px)] py-6 flex flex-col">
          <div className="mb-4 px-3">
            <div className="text-caption-sm text-neutrals-700">Research results</div>
          </div>

          <Button
            color="primary"
            size="sm"
            className="mb-3 mx-3"
            onClick={() => navigate(buildUrl(isAuthenticated ? [Urls.WEB_SEARCHER] : [Urls.PUBLIC, Urls.WEB_SEARCHER]))}
          >
            <img
              src="/search-check-white.svg"
              alt=""
            />
            New research
          </Button>

          <div className="flex flex-col grow overflow-y-auto gap-1 px-3">
            {webSearcherRooms
              .sort((a, b) => b.modifiedAt.localeCompare(a.modifiedAt))
              .map((room, i) => (
                <MenuItem
                  label={room.name}
                  href={buildUrl(isAuthenticated ? [Urls.WEB_SEARCHER_ROOM] : [Urls.PUBLIC, Urls.WEB_SEARCHER_ROOM], {
                    roomId: room.id,
                  })}
                  leftIcon="/edit-note-gray.svg"
                  key={`websearcher-${i}`}
                  customActiveCondition={currentRoom?.id === room.id}
                  addons={
                    <Dropdown
                      label=""
                      renderTrigger={() => (
                        <div>
                          <IconButton
                            icon="/three-dots-gray.svg"
                            size={24}
                          />
                        </div>
                      )}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Dropdown.Item onClick={() => onRoomDelete(room.id)}>
                        <img
                          src="/trash-gray.svg"
                          className="mr-2"
                          alt=""
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown>
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
