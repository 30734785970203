import clsx from 'clsx';
import { IconButton } from '@/components/IconButton.tsx';
import { WorkspaceSwitcher } from './WorkspaceSwitcher.tsx';
import { LeftSideNavMenuItems } from './LeftSideNavMenuItems.tsx';
import { useLeftSideNav } from '../store.ts';
import { MenuFooter } from '@/layouts/MainLayout/components/MenuFooter.tsx';

export function LeftSideNav() {
  const { isNavCollapsed, collapseNav } = useLeftSideNav();

  return (
    <nav
      className={clsx(
        'flex flex-col z-10 py-5 bg-gray-ultra-light w-[284px] group transition-[width] duration-300 h-svh overflow-hidden',
        {
          'is-collapsed w-[64px]': isNavCollapsed,
        },
      )}
    >
      <div className="flex justify-between mb-5 px-3">
        <img
          src="/df-logo-black.svg"
          style={{ display: isNavCollapsed ? 'none' : 'block' }}
          width={120}
          height={20}
          className="max-w-[120px]"
          alt="Digital First AI logo"
        />
        <img
          src="/df-logo-small-black.svg"
          style={{ display: isNavCollapsed ? 'block' : 'none' }}
          width={20}
          height={20}
          className="mx-auto"
          alt="Digital First AI logo"
        />
        <div className="group-[.is-collapsed]:hidden rotate-180">
          <IconButton
            icon="/sidebar-black.svg"
            onClick={() => collapseNav()}
          />
        </div>
      </div>

      <div className="px-3">
        <WorkspaceSwitcher isNavCollapsed={isNavCollapsed} />
      </div>

      <div className="grow min-h-0 flex flex-col gap-4 justify-between mt-3">
        <div className="overflow-y-auto px-3 grow">
          <LeftSideNavMenuItems />
        </div>

        <div className="px-3">
          {/* TODO: Revert onboarding component when onboarding is implemented */}
          {/*<div className="group-[.is-collapsed]:hidden">*/}
          {/*  <OnboardingProgressBox progress={1} />*/}
          {/*</div>*/}
          <MenuFooter />
        </div>
      </div>
    </nav>
  );
}
