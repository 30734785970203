import { ToolConfigInputPayload, ToolFieldInputType } from './type.model';
import * as Yup from 'yup';

export const getValidationSchema = (input: ToolConfigInputPayload, disabledFields: string[]) => {
  const schema = Yup.object();
  const schemaFields: Record<string, Yup.AnyObject> = {};

  for (const key in input) {
    const validation = input[key].validation;
    const type = input[key].type;

    if (disabledFields && disabledFields.includes(key)) {
      continue;
    }

    switch (type) {
      case ToolFieldInputType.TEXT:
        schemaFields[key] = Yup.string();
        break;

      case ToolFieldInputType.NUMBER:
        schemaFields[key] = Yup.number();
        break;

      case ToolFieldInputType.SELECT:
        schemaFields[key] = Yup.string();
        break;

      case ToolFieldInputType.FILE:
        schemaFields[key] = Yup.mixed()
          .test('maxSize', 'File is too large', (value) => {
            const sizeValidator = validation.maxFileSize as number;
            const file = value as { size: number };
            return file?.size <= sizeValidator;
          })
          .test('fileType', 'Invalid file type', (value) => {
            const allowedExtentions = validation.allowExtentions as string[];
            const fileName = value as string | undefined;
            const fileExtention = fileName?.split('.')?.pop() as string;
            return allowedExtentions.includes(fileExtention);
          });
        break;

      case ToolFieldInputType.CHECKBOX:
        schemaFields[key] = Yup.boolean();
        break;
    }

    if (validation.required) {
      schemaFields[key] = schemaFields[key].required('Required');
    }

    if (validation.valueIn) {
      schemaFields[key] = schemaFields[key].oneOf(validation.valueIn);
    }

    if (validation.maxLength) {
      schemaFields[key] = schemaFields[key].max(validation.maxLength);
    }

    if (validation.minLength) {
      schemaFields[key] = schemaFields[key].min(validation.minLength);
    }

    if (validation.regexp) {
      schemaFields[key] = schemaFields[key].matches(new RegExp(validation.regexp));
    }
  }

  return schema.shape(schemaFields as Yup.ObjectShape);
};
