import { MyCanvasCard } from '../../components/MyCanvasCard.tsx';
import { useCanvases } from '@/api/canvases/api.ts';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';

export function MyCanvases() {
  const { canvases, isLoading } = useCanvases();

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Strategy canvases', href: buildUrl([Urls.CANVASES]) },
          { label: 'My canvases', href: '#' },
        ],
        icon: '/canvases-black.svg',
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | My canvases</title>
      </Helmet>

      <div className="grid grid-cols-3 gap-6">
        {!isLoading &&
          canvases?.map((canvasDetails) => (
            <MyCanvasCard
              key={canvasDetails.id}
              canvasDetails={canvasDetails}
            />
          ))}
      </div>
    </PageWithHeaderLayout>
  );
}
