export let disabledAutoScrolling = false;

export function getDisableAutoScrolling(): boolean {
  return disabledAutoScrolling;
}

export function setDisableAutoScrolling(value: boolean): void {
  disabledAutoScrolling = value;
}

export function smoothScroll(element: HTMLElement): void {
  if (disabledAutoScrolling) return;

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
  });
}
