import { Button, Select, TextInput } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { getDfFieldProps } from '@/helpers/get-df-field-props.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { FlowTemplate } from '@/models/flow-template.interface';
import { FLOW_CATEGORIES } from '@/consts/flow-categories';
import { useUpdateTemplateFlow } from '@/api/flow/update-template-flow';
import { useTemplateFlowAccess } from '@/api/flow/use-template-flow-access';

export function FlowTemplateModalBody() {
  const { closeModal, context } = useGlobalModal<FlowTemplate>();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { updateTemplateFlow } = useUpdateTemplateFlow();
  const { templateAccess } = useTemplateFlowAccess();

  const formik = useFormik({
    initialValues: {
      name: context.name,
      description: context.description,
      category: context.category,
      access: context.access,
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
      description: string().required(VALIDATION_ERRORS.Required),
      category: string().required(VALIDATION_ERRORS.Required),
      access: string().default('public').required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async (data) => {
      showLoader();
      await updateTemplateFlow(context.id, data);
      hideLoader();
      closeModal();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form
        className="flex flex-col gap-3"
        onSubmit={formik.handleSubmit}
      >
        <TextInput
          {...getDfFieldProps('name', formik)}
          placeholder="Template name"
        />
        <TextInput
          {...getDfFieldProps('description', formik)}
          placeholder="Template description"
        />
        <Select {...getDfFieldProps('category', formik)}>
          {FLOW_CATEGORIES.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </Select>

        <Select {...getDfFieldProps('access', formik)}>
          {templateAccess?.map((option) => (
            <option
              key={option.owner}
              value={option.owner}
            >
              {option.name}
            </option>
          ))}
        </Select>

        <div className="modal-divider mt-8 mb-6"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Update template
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
