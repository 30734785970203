import useSWR, { mutate } from 'swr';
import { useApi } from '../api.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import { CanvasCategory } from '@/enums/canvas-category.enum.ts';
import { useMemo } from 'react';
import { TimeEntity } from '@/models/time-entity.interface.ts';

export interface CanvasTemplate {
  category: CanvasCategory;
  description: string;
  id: number;
  img: null | string;
  name: string;
  positions: CanvasPosition[];
}

export interface Canvas {
  id: number;
  name: string;
  template: {
    id: number;
  };
  instanceId?: number;
}

export interface CanvasInstance {
  id: number;
  name: string;
  templateId: number;
  sections: CanvasSection[];
}

export interface CanvasSection {
  sectionId: number;
  type?: string;
  title: string;
  touched: boolean;
  data: string[];
}

export interface CanvasDetails extends TimeEntity {
  id: number;
  name: string;
  positions: CanvasPosition[];
  isProcessing: boolean;
  templateId: number;
}

export interface CanvasPosition {
  id: number;
  label: string;
  value: string[];
  hierarchyPosition: number;
}

type Columns = '100' | '50-50' | '30-70';

export interface LayoutDefinition {
  columnLayout: Columns;
  columns: {
    sectionIds: number[];
  }[];
}

export const useCanvasTemplates = (
  fullList = false,
): {
  canvasTemplates: CanvasTemplate[];
  isLoading: boolean;
} => {
  const { getFetcher } = useApi();
  const { canvases, isLoading: isCanvasLoading } = useCanvases();

  const { data, isLoading } = useSWR('/strategy-canvas/templates', getFetcher);

  const notUsedTemplates = useMemo(() => {
    if (!data || !canvases) return data;
    return data.filter(
      (template: CanvasTemplate) => !canvases.find((canvas: CanvasDetails) => canvas.templateId === template.id),
    );
  }, [data, canvases]);

  return {
    canvasTemplates: fullList ? data : notUsedTemplates,
    isLoading: isLoading || isCanvasLoading,
  };
};

export const useCanvases = (): {
  canvases: CanvasDetails[] | null;
  isLoading: boolean;
} => {
  const { getFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const { data, isLoading } = useSWR(`/strategy-canvas/instances/${dataRoomId}`, getFetcher);

  const canvases: CanvasDetails[] | null = useMemo(() => {
    if (!data) return null;
    return data.map((canvas: CanvasInstance) => convertToCanvasDetails(canvas));
  }, [data]);

  return {
    canvases,
    isLoading,
  };
};

export const useCanvasDetails = (
  instanceId: string,
): {
  canvasDetails: CanvasDetails | null;
  isLoading: boolean;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading } = useSWR(`/strategy-canvas/instance/${instanceId}`, getFetcher);

  const canvasDetails: CanvasDetails | null = useMemo(() => {
    if (!data) return null;
    return convertToCanvasDetails(data);
  }, [data]);

  return {
    canvasDetails,
    isLoading,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToCanvasDetails = (canvas: any): CanvasDetails => {
  const values = canvas.positionValues?.reduce(
    (acc: Record<number, string[]>, positionValue: { position: { id: number }; value: string }) => {
      acc[positionValue.position.id] = acc[positionValue.position.id] || [];
      acc[positionValue.position.id].push(positionValue.value);
      return acc;
    },
    {},
  );

  return {
    id: canvas.id,
    name: canvas?.template?.name,
    positions: canvas.template.positions.map((p: { id: number; label: string; hierarchyPosition: number }) => ({
      id: p.id,
      label: p.label,
      value: values[p.id] ?? [],
      hierarchyPosition: p.hierarchyPosition,
    })),
    isProcessing: canvas.isProcessing,
    templateId: canvas.template.id,
    createdAt: canvas.createdAt,
    modifiedAt: canvas.modifiedAt,
    deletedAt: canvas.deletedAt,
  };
};

export const useSaveCanvas: () => {
  saveCanvas: (templateId: number, positions?: CanvasPosition[], canvasId?: number) => Promise<CanvasInstance>;
} = () => {
  const { postFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const saveCanvas = async (templateId: number, positions: CanvasPosition[] = [], canvasId?: number) => {
    const payload = {
      dataRoomId,
      templateId,
      positionValue: positions
        .filter((p) => p.value && p.value.length > 0)
        .flatMap((p) => p.value.map((v) => ({ positionTemplateId: p.id, value: v }))),
    };

    const canvas = await postFetcher('/strategy-canvas/instance', { arg: payload });
    mutateCanvases(dataRoomId, canvasId);

    return canvas;
  };

  return {
    saveCanvas,
  };
};

export const useDeleteCanvas = (): {
  deleteCanvas: (instanceId: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const deleteCanvas = async (instanceId: number) => {
    await deleteFetcher(`/strategy-canvas/instance/${instanceId}`, { arg: {} });
    mutateCanvases(dataRoomId, instanceId);
  };

  return {
    deleteCanvas,
  };
};

export const useGenerateCanvas = (): {
  generateCanvas: (templateId: number, wsChannel: string) => Promise<{ wsChannel: string }>;
} => {
  const { postFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const generateCanvas = async (templateId: number, wsChannel: string) => {
    return await postFetcher(`/strategy-canvas/instance/${templateId}/${dataRoomId}/generate/${wsChannel}`, {
      arg: {},
    });
  };

  return {
    generateCanvas,
  };
};

export const useGenerateCanvasSection = (): {
  generateCanvasSection: (
    canvasInstanceId: number,
    sectionId: number,
    wsChannel: string,
  ) => Promise<{ wsChannel: string }>;
} => {
  const { postFetcher } = useApi();

  const generateCanvasSection = async (canvasInstanceId: number, sectionId: number, wsChannel: string) => {
    return await postFetcher(
      `/strategy-canvas/instance/${canvasInstanceId}/position/${sectionId}/generate/${wsChannel}`,
      {
        arg: {},
      },
    );
  };

  return {
    generateCanvasSection,
  };
};

export const useLayout = (templateId: number | string | undefined) =>
  useSWR(templateId ? `/api/layouts/${templateId}` : null, {});

export const mutateCanvases = (dataRoomId: number | null, canvasId?: number) => {
  if (canvasId) {
    mutate(`/strategy-canvas/instance/${canvasId}`);
  }
  mutate(`/strategy-canvas/instances/${dataRoomId}`);
};
