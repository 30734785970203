import { WebSearcherPageHeader } from './components/WebSearcherPageHeader.tsx';
import { WebSearcherPanel } from './components/WebSearcherPanel.tsx';
import { WebSearcherNewSearchForm } from './components/WebSearcherNewSearchForm.tsx';
import { Helmet } from 'react-helmet';
import { useWebSearcherRooms } from '@/api/websearcher/get-web-searcher-rooms.ts';

export function WebSearcherPage() {
  const { webSearcherRooms, isLoading } = useWebSearcherRooms();

  if (isLoading) {
    return;
  }

  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai | WebSearcher</title>
      </Helmet>
      <main className="flex flex-col h-svh w-full bg-neutrals-200">
        <WebSearcherPageHeader />
        <div className="flex h-[calc(100svh-66px)] pb-3 pr-3">
          {webSearcherRooms.length ? (
            <WebSearcherPanel webSearcherRooms={webSearcherRooms} />
          ) : (
            <WebSearcherNewSearchForm />
          )}
        </div>
      </main>
    </>
  );
}
