import useSWR from 'swr';
import { useApi } from '../api.ts';
import { OrganizationRole } from '@/models/organization-role.interface.ts';

export const useOrganizationRoles = (): {
  organizationRoles: OrganizationRole[];
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR('/organization/roles', getFetcher);

  return {
    organizationRoles: data,
    isLoading,
    error,
  };
};
