import { WorkspaceUpdate } from '@/models/workspace-update.infterface.ts';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useApi } from '../api.ts';
import { mutate } from 'swr';
import { Workspace } from '@/models/workspace.interface.ts';

export const useUpdateWorkspace = (): {
  updateWorkspace: (model: WorkspaceUpdate) => Promise<Workspace>;
} => {
  const { patchFetcher, getFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const updateWorkspace = async (arg: WorkspaceUpdate): Promise<Workspace> => {
    await patchFetcher(`/workspace/${workspaceId}`, { arg });
    await mutate('/workspace/by-user');
    await mutate(`/workspace/${workspaceId}`);
    const response = await getFetcher(`/workspace/${workspaceId}`);
    return response;
  };

  return {
    updateWorkspace,
  };
};
