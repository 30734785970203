import { useCurrentWorkspace } from '@/state/app-store';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage';
import { useCallback, useEffect } from 'react';
import { MemoryLogToggler } from './MemoryLogToggler';
import { MemoryLogBody } from './MemoryLogBody';
import {
  addLog,
  setAllTaskIsCompleted,
  setClearMemoryLogs,
  setIsOpen,
  setUnreadLogsCount,
  useMemoryLogStore,
} from './store';

export interface MemoryLogItem {
  message: string;
  completed: boolean;
}

enum MemoryLogCompletedEvents {
  PROCESS_FINISHED = 'Process finished',
  REFERENCES = 'References',
}

export const MemoryLog = () => {
  const { listenerOn } = useWebSocketMessageListener();
  const currentWorkspace = useCurrentWorkspace();
  const { logs, isOpen, allTaskIsCompleted, unreadLogsCount } = useMemoryLogStore();

  const toggleIsOpen = useCallback(() => {
    setUnreadLogsCount(0);
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onMessage = useCallback(
    (data: string) => {
      const currentTaskIsCompleted =
        data === MemoryLogCompletedEvents.PROCESS_FINISHED || data === MemoryLogCompletedEvents.REFERENCES;

      addLog({ message: data, completed: currentTaskIsCompleted });
      setAllTaskIsCompleted(logs.every((log) => log.completed));
      setUnreadLogsCount(unreadLogsCount + 1);
    },
    [logs],
  );

  useEffect(() => {
    if (!currentWorkspace) return;
    const channel = `memory-log:${currentWorkspace.id}`;
    const { listenerOff } = listenerOn(channel, onMessage);

    return () => {
      listenerOff();
      setClearMemoryLogs();
    };
  }, [currentWorkspace]);

  return (
    <div className="fixed bg-[#975BFF] h-[52px] w-[52px] rounded-full border-[1px] border-solid border-white z-[1000] right-[20px] bottom-[20px]">
      <MemoryLogToggler
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
        allTaskIsCompleted={allTaskIsCompleted}
        unreadLogsCount={unreadLogsCount}
      />
      <MemoryLogBody
        isOpen={isOpen}
        allTaskIsCompleted={allTaskIsCompleted}
        logs={logs}
      />
    </div>
  );
};
