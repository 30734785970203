import clsx from 'clsx';
import { PromptMentionType } from '@/components/tiptap/extensions/DigitalFirst/editing/PromptEditor.tsx';
import { ReactNode } from 'react';

interface PromptMentionTypeAccordionProps {
  label: string;
  isExpanded: boolean;
  isLastAccordion?: boolean;
  mentionType: PromptMentionType;
  toggleAccordion: (type: PromptMentionType) => void;
  icon: string;
  children: ReactNode;
}

export const MentionTypeAccordion = ({
  label,
  isExpanded,
  isLastAccordion = false,
  mentionType,
  toggleAccordion,
  icon,
  children,
}: PromptMentionTypeAccordionProps) => {
  return (
    <>
      <div
        className={clsx('border border-t-0 border-neutrals-400 overflow-hidden', { 'rounded-b-lg': isLastAccordion })}
      >
        <button
          onClick={() => {
            toggleAccordion(mentionType);
          }}
          className="p-4 w-full flex gap-3 items-center"
        >
          <img
            src={icon}
            alt=""
            width={16}
            height={16}
          />
          <span className="grow text-left text-caption-md text-text-primary">{label}</span>
          <img
            src="/chevron-down-black.svg"
            className={clsx('transition', { 'rotate-180': isExpanded })}
            alt=""
            width={16}
            height={16}
          />
        </button>
        {isExpanded && children}
      </div>
    </>
  );
};
