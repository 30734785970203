import { DIALOGS } from '@/consts/dialogs.const.ts';
import { Dropdown } from 'flowbite-react';
import { useDeleteCanvas } from '@/api/canvases/api.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { IconButton } from '@/components/IconButton.tsx';

export function DropdownCanvasOptions({
  instanceId,
  onDelete = () => {},
}: {
  instanceId: number;
  onDelete?: () => void;
}) {
  const { deleteCanvas } = useDeleteCanvas();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { openDialog } = useGlobalDialog();

  const onDeleteClick = () => {
    openDialog(DIALOGS.DeleteCanvas, async () => {
      showLoader('Deleting canvas...');
      await deleteCanvas(instanceId);
      onDelete();
      hideLoader();
    });
  };

  return (
    <>
      <Dropdown
        label=""
        renderTrigger={() => (
          <div>
            <IconButton
              icon="/three-dots-gray.svg"
              alt="More"
            />
          </div>
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <Dropdown.Item
          className="w-28"
          onClick={onDeleteClick}
        >
          <img
            src="/trash-gray.svg"
            className="mr-2"
            alt=""
          />
          Delete
        </Dropdown.Item>
      </Dropdown>
    </>
  );
}
