import { useApi } from '@/api/api.ts';
import { useDataRoomId } from '@/state/app-store.ts';

export const useCreateSegment = (): {
  createSegment: (name: string) => Promise<void>;
} => {
  const { postFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const createSegment = async (name: string) => {
    return postFetcher(`/persona-canvas/segment`, {
      arg: {
        dataRoomId,
        name,
      },
    });
  };

  return {
    createSegment,
  };
};
