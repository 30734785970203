import { io } from 'socket.io-client';

export const listenOnCanvasFillerMessage = <T>(channel: string, callback: (data: T) => void) => {
  const socket = io(import.meta.env.VITE_WEBSOCKET_URL, {
    transports: ['websocket'],
  });
  socket.connect();
  socket.emit('subscribe', channel);
  socket.on(channel, async (data: T) => {
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (e) {
        console.error(e);
      }
    }
    callback(data);
    socket.emit('unsubscribe', channel);
    socket.off(channel);
  });
};
