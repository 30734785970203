import { createContext } from 'react';

export interface IEditorContext {
  isAiLoading: boolean;
  aiError?: string | null;
  setIsAiLoading: () => void;
  setAiError: () => void;
}

export const EditorContext = createContext<IEditorContext>({
  isAiLoading: false,
  aiError: null,
  setIsAiLoading: () => {},
  setAiError: () => {},
});
