import { useApi } from '../api.ts';
import { mutate, useSWRConfig } from 'swr';
import { FlowTemplateUpdate } from '@/models/flow-template-update.interface.ts';

export const useUpdateTemplateFlow = () => {
  const { patchFetcher } = useApi();
  const { cache } = useSWRConfig();

  const updateTemplateFlow = async (id: number, arg: FlowTemplateUpdate): Promise<void> => {
    await patchFetcher(`/flow/${id}`, { arg });
    await Promise.all(
      Array.from(cache.keys())
        .filter((key) => key.startsWith('/flow'))
        .map((key) => mutate(key)),
    );
  };

  return {
    updateTemplateFlow,
  };
};
