import { MenuItem, MenuItemProps } from '@/components/MenuItem.tsx';
import { useLeftSideNav } from '@/layouts/MainLayout/store.ts';
import { DfTooltip } from '@/components/DfTooltip/DfTooltip.tsx';
import { v4 as uuid } from 'uuid';
import { ReactNode } from 'react';

export interface LeftSideNavMenuItemProps extends MenuItemProps {
  children?: ReactNode;
}

export function LeftSideNavMenuItem(props: LeftSideNavMenuItemProps) {
  const { isNavCollapsed } = useLeftSideNav();
  const tooltipId = uuid();

  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        className="relative"
      >
        <MenuItem
          {...props}
          disabledTooltip="Coming soon!"
        />

        {isNavCollapsed && !props.disabled && (
          <DfTooltip
            id={tooltipId}
            content={props.label}
            place="right"
          />
        )}
      </div>
    </>
  );
}
