import { createContext } from 'react';
import { Editor } from '@tiptap/react';
import { TOC } from '@/components/tiptap/extensions/DigitalFirst/editing/PromptEditor.tsx';

interface PromptEditorContextProps {
  editor: Editor | null;
  toc: TOC | null;
}

export const PromptEditorContext = createContext<PromptEditorContextProps>({
  editor: null,
  toc: null,
});
