import { GlobalLoader } from '@/components/GlobalLoader/GlobalLoader.tsx';
import { GlobalModal } from '@/components/GlobalModal/GlobalModal.tsx';
import { GlobalDialog } from '@/components/GlobalDialog/GlobalDialog.tsx';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

export function App() {
  // In a future we will use Intercom, but it will be hidden by default and available from "Help Center" button
  //
  // const { user } = useAuth0();
  //
  // Intercom({
  //   app_id: import.meta.env.VITE_INTERCOM_APP_ID,
  //   user_id: user?.sub,
  //   name: user?.name,
  //   email: user?.email,
  //   created_at: user?.createdAt,
  // });

  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai</title>
      </Helmet>
      <GlobalLoader />
      <GlobalModal />
      <GlobalDialog />
      <div>
        <Toaster />
      </div>

      <Outlet />
    </>
  );
}
