import { marked } from 'marked';

export const llmMarked = (text: string): string | Promise<string> => {
  return marked(
    (text
      ?.replaceAll('\n\n```markdown\n', '')
      ?.replaceAll('```markdown\n', '')
      ?.replaceAll('```', '')
      ?.replaceAll('$#@markdown$#@', '\n')
      ?.replaceAll('markdown$#@', '\n')
      ?.replaceAll('$#markdown', '\n')
      ?.replaceAll('$#@', '\n')
      ?.replace(/\$#@/g, '\n') as string) ?? '',
  );
};
