import { Button, TextInput } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { getDfFieldProps } from '@/helpers/get-df-field-props.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useCreateProject } from '@/api/projects/create-project.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { mutate } from 'swr';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';

export function ProjectCreateModalBody() {
  const { closeModal } = useGlobalModal<string>();
  const { createProject } = useCreateProject();
  const { showLoader, hideLoader } = useGlobalLoader();
  const workspaceId = useCurrentWorkspace()?.id;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async (data) => {
      closeModal();
      showLoader();
      const project = await createProject(data.name);
      await mutate(`/project/by-workspace/${workspaceId}`);
      navigate(buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: project.id }));
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          {...getDfFieldProps('name', formik)}
          placeholder="Project name"
        />

        <div className="modal-divider mt-8 mb-6"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Add
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
