import { OrganizationMemberUpdate } from '@/models/organization-member-update.interface.ts';
import { useApi } from '../api.ts';
import { mutate } from 'swr';

export const useUpdateOrganizationMember = (): {
  updateOrganizationMember: (organizationMemberUpdate: OrganizationMemberUpdate) => Promise<void>;
} => {
  const { postFetcher } = useApi();

  const updateOrganizationMember = async (organizationMemberUpdate: OrganizationMemberUpdate): Promise<void> => {
    await postFetcher('/organization/member/update', { arg: organizationMemberUpdate });
    await mutate('/organization/members');
  };

  return {
    updateOrganizationMember,
  };
};
