import { create } from 'zustand';

type GlobalLoaderState = {
  show: boolean;
  message: string;
};

const useGlobalLoaderStore = create<GlobalLoaderState>(() => ({
  show: false,
  message: '',
}));

const showLoader = (message?: string) => {
  useGlobalLoaderStore.setState(() => ({
    show: true,
    message: message ?? 'Loading...',
  }));
};

const hideLoader = () => {
  useGlobalLoaderStore.setState(() => ({
    show: false,
  }));
};

export const useGlobalLoaderInternalState = () => ({
  show: useGlobalLoaderStore((state) => state.show),
  message: useGlobalLoaderStore((state) => state.message),
});

export const useGlobalLoader = () => ({
  showLoader,
  hideLoader,
});
