import { Badge, Table } from 'flowbite-react';
import { useInputs } from '@/api/inputs/get-inputs.ts';
import { DropdownDataRoomItemOptions } from './DropdownDataRoomItemOptions.tsx';
import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import clsx from 'clsx';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { PreviewAndSummaryModal } from './PreviewAndSummaryModal.tsx';
import { useState } from 'react';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationMembers } from '@/api/organization/get-organization-members.ts';
import { LinkIcon } from 'lucide-react';

export function AddedDataTable() {
  const { user } = useAuth0();
  const { organizationMembers } = useOrganizationMembers();
  const { addedData, isLoading } = useInputs();
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DataRoomItem | null>(null);

  const previewItem = (item: DataRoomItem) => {
    if (!item.isParsed) return;

    setSelectedItem(item);
    setOpenModal(true);
  };

  const getCreator = (data: DataRoomItem) => {
    if (data.creatorId === user?.sub) {
      return user;
    }

    const creator = (organizationMembers ?? []).find((value) => value.user_id === data.creatorId);
    return creator;
  };

  const getCreatorLabel = (data: DataRoomItem): string => {
    if (data.creatorId === user?.sub) {
      return 'You';
    }

    const creator = (organizationMembers ?? []).find((value) => value.user_id === data.creatorId);
    if (!creator) {
      return 'Unknown user';
    }

    if (creator.given_name && creator.family_name) {
      return `${creator.given_name} ${creator.family_name}`;
    }

    return creator.email;
  };

  return (
    <>
      <div className="flex items-center gap-3 mb-6">
        <h2 className="text-h4-desktop">Added</h2>
        <Badge color="blue">{addedData.length}</Badge>
      </div>
      <Table>
        <Table.Head>
          <Table.HeadCell className="w-[40%]">Name</Table.HeadCell>
          <Table.HeadCell>Date</Table.HeadCell>
          <Table.HeadCell>Added by</Table.HeadCell>
          <Table.HeadCell>Folder</Table.HeadCell>
          <Table.HeadCell className="w-[70px]">
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {!isLoading &&
            addedData.map((item: DataRoomItem, index) => (
              <Table.Row key={index}>
                <Table.Cell
                  className={clsx('group flex items-center gap-3', {
                    'hover:cursor-pointer': item.isParsed,
                  })}
                  onClick={() => previewItem(item)}
                >
                  <div
                    className={clsx('p-2.5 shrink-0 bg-gray-ultra-light rounded-lg', {
                      'group-hover:bg-neutrals-300 transition': item.isParsed,
                    })}
                  >
                    <img
                      className={clsx('size-5', { 'animate-spin-slow': !item.isParsed })}
                      src={item.isParsed ? item.icon : '/loading-clockwise-gray.svg'}
                      alt=""
                    />
                  </div>
                  <span className="text-text-primary text-caption-md font-medium overflow-hidden grow text-ellipsis whitespace-nowrap max-w-full flex items-center gap-2">
                    {item.name}
                    {!item.extension && (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkIcon size={12}></LinkIcon>
                      </a>
                    )}
                  </span>
                </Table.Cell>
                <Table.Cell>{moment(item.date).format('DD.MM.YYYY')}</Table.Cell>
                <Table.Cell>
                  <div className="flex items-center gap-3">
                    {getCreator(item)?.picture ? (
                      <img
                        src={getCreator(item)?.picture}
                        className="size-6 rounded-full"
                      />
                    ) : (
                      <DfAvatar
                        firstName={getCreator(item)?.given_name || 'U'}
                        lastName={getCreator(item)?.family_name || 'U'}
                        size={24}
                      />
                    )}
                    <span>{getCreatorLabel(item)}</span>
                  </div>
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>
                  <DropdownDataRoomItemOptions
                    item={item}
                    onPreview={() => previewItem(item)}
                  >
                    <div>
                      <IconButton
                        icon="/three-dots-gray.svg"
                        size={24}
                        alt="More"
                      />
                    </div>
                  </DropdownDataRoomItemOptions>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>

      {!!addedData?.length && (
        <PreviewAndSummaryModal
          open={openModal}
          item={selectedItem}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
}
