import { create } from 'zustand';

type CanvasProcessingState = {
  canvases: {
    [canvasIdWithPositionId: string]: boolean;
  };
};

const useCanvasProcessingStore = create<CanvasProcessingState>(() => ({
  canvases: {},
}));

export const setCanvasSectionProcessingState = (canvasId: number, sectionId: number, isProcessing: boolean) => {
  useCanvasProcessingStore.setState((state) => ({
    canvases: {
      ...state.canvases,
      [`${canvasId}:${sectionId}`]: isProcessing,
    },
  }));
};

export const useCanvasSectionProcessingStatus = (canvasId: number, sectionId: number) =>
  useCanvasProcessingStore((state) => state.canvases[`${canvasId}:${sectionId}`]);
