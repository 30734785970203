import { useDataRoomId } from '@/state/app-store.ts';
import { mutate } from 'swr';
import { useApi } from '../api.ts';

export function useRemoveResource(): {
  removeResource: (itemId: string) => Promise<void>;
} {
  const { deleteFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const removeResource = (fileId: string) =>
    deleteFetcher(`/inputs/dataRoom/${dataRoomId}/remove-resource/${fileId}`).then(() =>
      mutate(`/inputs/dataRoom/${dataRoomId}`),
    );

  return {
    removeResource,
  };
}
