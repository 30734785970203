import useSWR from 'swr';
import { useApi } from '../api.ts';
import { Organization } from '@/models/organization.interface.ts';

export const useOrganization = (): {
  organization: Organization;
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR('/organization', getFetcher);

  return {
    organization: data,
    isLoading,
    error,
  };
};
