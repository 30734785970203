import { Editor } from '@tiptap/react';

export const getRenderContainer = (editor: Editor, nodeType: string) => {
  const {
    view,
    state: {
      selection: { from },
    },
  } = editor;

  const elements = document.querySelectorAll('.has-focus');
  const elementCount = elements.length;
  const innermostNode = elements[elementCount - 1];

  if (
    (innermostNode &&
      innermostNode.getAttribute('data-type') &&
      innermostNode.getAttribute('data-type') === nodeType) ||
    (innermostNode && innermostNode.classList && innermostNode.classList.contains(nodeType))
  ) {
    return innermostNode;
  }

  const node = view.domAtPos(from).node as HTMLElement;
  let container: HTMLElement | null = node;

  if (!container.tagName) {
    container = node.parentElement;
  }

  while (
    container &&
    !(container.getAttribute('data-type') && container.getAttribute('data-type') === nodeType) &&
    !container.classList.contains(nodeType)
  ) {
    container = container.parentElement;
  }

  return container;
};
