import { ReactNode, useEffect } from 'react';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';

export function WebSocketErrorListener({ children }: { children: ReactNode }) {
  const { listenerOn } = useWebSocketMessageListener();
  const { user } = useAuth0();

  useEffect(() => {
    if (!user?.sub) return;

    const { listenerOff } = listenerOn(`ws-error:${user.sub}`, (errorMessage: string) => {
      toast.error(errorMessage);
    });

    return () => {
      listenerOff();
    };
  }, [user?.sub]);

  return <>{children}</>;
}
