import { EditorInfo } from './EditorInfo';
import { EditorUser } from '../types';
import { WebSocketStatus } from '@hocuspocus/provider';
import { ExecutionModeSwitcher } from '@/components/tiptap/components/ExecutionModeSwitcher/ExecutionModeSwitcher';
import {
  useIsTemplateEditing,
  useTiptapAccessMode,
  useTiptapCurrentView,
  useMemoryContextSidebar,
} from '@/components/tiptap/extensions/DigitalFirst/store';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';
import { useReactToPrint } from 'react-to-print';
import { Editor, PureEditorContent } from '@tiptap/react';
import { MutableRefObject } from 'react';
import { ExpandLeftNavButton } from '@/layouts/MainLayout/components/ExpandLeftNavButton.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';
import { useParams } from 'react-router-dom';
import { useMyFlow } from '@/api/flow/my-flow/get-my-flow.ts';
import { useProject } from '@/api/projects/get-project.ts';
import { Button, ToggleSwitch } from 'flowbite-react';
import { Breadcrumbs } from '@/components/Breadcrumbs.tsx';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';

export type EditorHeaderProps = {
  characters: number;
  words: number;
  collabState: WebSocketStatus;
  users: EditorUser[];
  editorRef: MutableRefObject<PureEditorContent | null>;
  editor: Editor;
  executeAll: (editor: Editor) => Promise<void>;
};

const memoryContextToggleCustomTheme = {
  toggle: {
    base: "relative bg-white rounded-full border after:absolute after:rounded-full after:bg-[url('/memory-context-on.svg')] after:bg-no-repeat after:bg-center after:bg-contain after:transition-all",
    checked: {
      on: 'after:translate-x-full after:border-white rtl:after:-translate-x-full',
      off: "after:bg-[url('/memory-context-off.svg')]",
      color: {
        blue: '',
      },
    },
    sizes: {
      md: 'h-8 w-14 min-w-14 after:left-[4px] after:top-[3px] after:h-6 after:w-6',
    },
  },
};

export const EditorHeader = ({
  characters,
  collabState,
  users,
  words,
  editorRef,
  editor,
  executeAll,
}: EditorHeaderProps) => {
  const { projectId, flowId } = useParams();
  const { myFlow } = useMyFlow(Number(flowId));
  const { project } = useProject(Number(projectId));
  const isExecutionMode = useTiptapCurrentView() == TiptapView.EXECUTION_MODE;
  const { openModal } = useGlobalModal();
  const tiptapAccessMode = useTiptapAccessMode();
  const isTemplateEditing = useIsTemplateEditing();
  const { isMemoryContextSidebarOpen, toggleMemoryContextSidebar } = useMemoryContextSidebar();

  const handlePrint = useReactToPrint({
    content: () => editorRef.current,
  });

  const handleShare = () => {
    openModal(MODALS.ShareFlow);
  };

  return (
    <div className="flex items-center justify-between pr-8 pl-2 py-1 relative h-14">
      <div className="flex items-center gap-2 text-sm">
        <ExpandLeftNavButton />
        <div className="p-1.5 rounded bg-gray-ultra-light">
          <img
            src="/folder-black.svg"
            width={20}
            height={20}
            alt=""
          />
        </div>
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Projects', href: buildUrl([Urls.PROJECTS]) },
            { label: project?.name || '', href: buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: projectId! }) },
            { label: myFlow?.name || '', href: '#' },
          ]}
        />
      </div>
      <div className="absolute left-[calc(50%-120px)]">
        <ExecutionModeSwitcher />
      </div>
      <div className="flex items-center gap-3">
        {isExecutionMode && (
          <>
            {tiptapAccessMode === TiptapAccessMode.FULL && (
              <>
                <ToggleSwitch
                  checked={isMemoryContextSidebarOpen}
                  onChange={toggleMemoryContextSidebar}
                  theme={memoryContextToggleCustomTheme}
                />

                <Button
                  color="primary"
                  size="sm"
                  onClick={async () => await executeAll(editor)}
                  className=""
                >
                  <img
                    src="/generate-white.svg"
                    width={16}
                    height={16}
                    alt=""
                  />
                  Generate
                </Button>
              </>
            )}

            <Button
              onClick={handlePrint}
              color="secondary"
              size="sm"
            >
              <img
                src="/download-black.svg"
                width={16}
                height={16}
                alt=""
              />
              PDF
            </Button>
          </>
        )}
        {tiptapAccessMode === TiptapAccessMode.FULL && !isTemplateEditing && (
          <Button
            onClick={handleShare}
            color="secondary"
            size="sm"
          >
            <img
              src="/group-add-black.svg"
              width={16}
              height={16}
              alt=""
            />
            Share
          </Button>
        )}
        <EditorInfo
          characters={characters}
          words={words}
          collabState={collabState}
          users={users}
        />
      </div>
    </div>
  );
};
