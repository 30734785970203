import { useApi } from '../api.ts';
import { Project } from '@/models/project.interface.ts';

export const useAddOrganizationMembers = (): {
  addOrganizationMembers: (inviteeEmail: string[]) => Promise<Project>;
} => {
  const { postFetcher } = useApi();

  const addOrganizationMembers = async (inviteeEmail: string[]): Promise<Project> => {
    return await postFetcher('/organization/invitations', { arg: { inviteeEmail } });
  };

  return {
    addOrganizationMembers,
  };
};
