import { useApi } from '../api.ts';
import { mutate } from 'swr';

export const useRemoveOrganizationMember = (): {
  removeOrganizationMember: (id: string) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();

  const removeOrganizationMember = async (id: string): Promise<void> => {
    await deleteFetcher(`/organization/member/${id}`);
    await mutate('/organization/members');
  };

  return {
    removeOrganizationMember,
  };
};
