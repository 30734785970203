import { DIALOGS } from '@/consts/dialogs.const.ts';
import { Dropdown } from 'flowbite-react';
import { IconButton } from '@/components/IconButton.tsx';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { Segment } from '@/models/segment.interface.ts';
import { useDeleteSegment } from '@/api/segments/delete-segment.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import { mutate } from 'swr';

export const DropdownSegmentOptions = ({ segment }: { segment: Segment }) => {
  const { openDialog } = useGlobalDialog();
  const { openModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { deleteSegment } = useDeleteSegment();
  const dataRoomId = useDataRoomId();

  const onDelete = () => {
    openDialog(DIALOGS.DeleteSegment, async () => {
      showLoader();
      await deleteSegment(segment.id);
      await mutate(`/persona-canvas/segment/${dataRoomId}`);
      hideLoader();
    });
  };

  const onRename = () => {
    openModal(MODALS.EditSegment, { segment });
  };

  return (
    <>
      <Dropdown
        label=""
        renderTrigger={() => (
          <div>
            <IconButton
              icon="/three-dots-gray.svg"
              size={24}
              alt="More"
            />
          </div>
        )}
        onClick={(e) => e.stopPropagation()}
        placement="right-start"
      >
        <Dropdown.Item
          onClick={onRename}
          className="w-28"
        >
          <img
            src="/pencil-black.svg"
            alt=""
          />
          Rename
        </Dropdown.Item>
        <Dropdown.Item
          className="w-28"
          onClick={onDelete}
        >
          <img
            src="/trash-black.svg"
            alt=""
          />
          Delete
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};
