import { Button } from 'flowbite-react';

export const ToolPipelineHeader = ({
  executePipeline,
  savePipeline,
}: {
  executePipeline: () => void;
  savePipeline: () => void;
}) => {
  return (
    <div className="flex justify-end gap-4">
      <Button
        color={'success'}
        onClick={executePipeline}
      >
        Execute pipeline
      </Button>
      <Button
        color={'success'}
        onClick={savePipeline}
      >
        Save pipeline
      </Button>
    </div>
  );
};
