import { useApi } from '../api.ts';
import { UpdateMe } from '@/models/update-me.interface.ts';

export const useUpdateMe = () => {
  const { patchFetcher } = useApi();

  const updateMe = async (me: UpdateMe): Promise<void> => {
    await patchFetcher('/user', { arg: me });
  };

  return {
    updateMe,
  };
};
