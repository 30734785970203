import {
  setTiptapCurrentView,
  useTiptapAccessMode,
  useTiptapCurrentView,
} from '@/components/tiptap/extensions/DigitalFirst/store';
import clsx from 'clsx';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';
import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';

export const ExecutionModeSwitcher = () => {
  const currentView = useTiptapCurrentView();
  const tiptapAccessMode = useTiptapAccessMode();

  return (
    tiptapAccessMode === TiptapAccessMode.FULL && (
      <div className="bg-neutrals-300 flex rounded-lg border p-[2px] border-zinc-200 ">
        <button
          onClick={() => setTiptapCurrentView(TiptapView.CONFIGURATION_MODE)}
          className={clsx(
            'text-sm w-[120px] justify-center items-center gap-2 font-medium flex p-1 text-gray-dark rounded-md outline-none border-none focus:outline-none mr-1',
            {
              'bg-white': currentView === TiptapView.CONFIGURATION_MODE,
            },
          )}
        >
          <img
            src={`/article.svg`}
            alt=""
          />
          Edit
        </button>
        <button
          onClick={() => setTiptapCurrentView(TiptapView.EXECUTION_MODE)}
          className={clsx(
            'text-sm w-[128px] justify-center items-center gap-2 font-medium flex p-1 text-gray-dark rounded-md outline-none border-none focus:outline-none',
            {
              'bg-white': currentView === TiptapView.EXECUTION_MODE,
            },
          )}
        >
          <img
            src={`/play_arrow.svg`}
            alt=""
          />
          Flow
        </button>
      </div>
    )
  );
};
