import { useApi } from '@/api/api.ts';
import { useDataRoomId } from '@/state/app-store.ts';

export const useGenerateInputFileUrl = (): {
  generateInputFileUrl: (fileId: string) => Promise<string>;
} => {
  const { getFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const generateInputFileUrl = (fileId: string) =>
    getFetcher(`/inputs/data-room/${dataRoomId}/access-link/access-link/file/${fileId}`);

  return {
    generateInputFileUrl,
  };
};
