import { Modal } from 'flowbite-react';
import { useToolInstance } from 'src/libs/tools/api';
import { ToolOutput } from 'src/libs/tools/ToolOutput';
import { ToolOutputType } from 'src/libs/tools/type.model';

interface OutputModalProps {
  showOutputModal: boolean;
  setShowOutputModal: (show: boolean) => void;
  toolId: number;
  outputType: ToolOutputType;
}

export const OutputModal = ({ showOutputModal, setShowOutputModal, toolId, outputType }: OutputModalProps) => {
  const { toolInstance } = useToolInstance(toolId);

  return (
    <Modal
      show={showOutputModal}
      onClose={() => setShowOutputModal(false)}
    >
      <Modal.Header>Output Tool</Modal.Header>
      <Modal.Body>
        <ToolOutput
          output={toolInstance?.currentOutput ?? 'Not output yet'}
          outputType={outputType}
          showHistory={true}
          toolId={toolId}
        />
      </Modal.Body>
    </Modal>
  );
};
