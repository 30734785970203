import { FlowTemplateCategory } from '@/models/flow-template.interface';

interface FlowCategoryIconProps {
  category: string;
  width?: number;
  height?: number;
}

export function FlowTemplateCategoryIcon({ category, ...props }: FlowCategoryIconProps) {
  const categories: Record<string, string> = {
    [FlowTemplateCategory.MY]: '/star-black.svg',
    [FlowTemplateCategory.MARKETING]: '/rocket-launch-black.svg',
    [FlowTemplateCategory.COMMUNICATION]: '/speaker-notes-black.svg',
    [FlowTemplateCategory.EXECUTION]: '/location-searching-black.svg',
    [FlowTemplateCategory.DOCUMENTATION]: '/description-black.svg',
    [FlowTemplateCategory.ANALYSIS]: '/insert-chart-black.svg',
    [FlowTemplateCategory.RESEARCH]: '/action-key-black.svg',
    [FlowTemplateCategory.STRATEGY]: '/electric-bolt.svg',
    [FlowTemplateCategory.CREATIVE_DEVELOPMENT]: '/extension-black.svg',
    [FlowTemplateCategory.CONTENT_CREATION]: '/category-black.svg',
  };

  return (
    <img
      alt=""
      {...props}
      src={categories[category] ?? '/all-black.svg'}
    />
  );
}
