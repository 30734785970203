import { ProjectUpdate } from '@/models/project-update.infterface.ts';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useApi } from '../api.ts';
import { mutate } from 'swr';

export const useUpdateProject = (): {
  updateProject: (id: number, model: ProjectUpdate) => Promise<void>;
} => {
  const { patchFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const updateProject = async (id: number, arg: ProjectUpdate): Promise<void> => {
    await patchFetcher(`/project/${id}`, { arg });
    await mutate(`/project/by-workspace/${workspaceId}`);
  };

  return {
    updateProject,
  };
};
