import { useApi } from '../api';
import { UserEvent } from './model';

export const useTriggerUserEvent = () => {
  const { postFetcher } = useApi();

  const trigerUserEvent = async (event: UserEvent): Promise<void> => {
    await postFetcher('/user-events/triggerEvent', { arg: { eventType: event } });
  };

  return {
    trigerUserEvent,
  };
};
