import { useApi } from '../api.ts';

export const useAcceptInvitation = (): {
  acceptInvitation: (organizationId: string, invitationId: string) => Promise<boolean>;
} => {
  const { getFetcher } = useApi();

  const acceptInvitation = async (organizationId: string, invitationId: string): Promise<boolean> => {
    return await getFetcher(`/organization/${organizationId}/accept-invitation/${invitationId}`);
  };

  return {
    acceptInvitation,
  };
};
