import { Button } from 'flowbite-react';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useParams } from 'react-router-dom';

export function CreateBlankFlowButton() {
  const { projectId } = useParams();
  const { openModal } = useGlobalModal();

  return (
    <Button
      size="sm"
      color="primary"
      onClick={() => openModal(MODALS.CreateMyFlow, { projectId })}
    >
      <img
        src="/add-white.svg"
        alt=""
      />
      Blank flow
    </Button>
  );
}
