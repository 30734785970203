import useSWR from 'swr';
import { useApi } from '../api.ts';
import { Workspace } from '@/models/workspace.interface.ts';

export const useWorkspace = (
  workspaceId?: number,
): {
  workspace: Workspace;
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR(workspaceId ? `/workspace/${workspaceId}` : null, getFetcher);

  return {
    workspace: data,
    isLoading,
    error,
  };
};
