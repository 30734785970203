import { InputsUrl } from '@/models/inputs-url.interface.ts';

import { DataRoomItemType } from '@/enums/data-room-item.enum.ts';

export const getAddedDataTypeForUrl = (url: InputsUrl): DataRoomItemType => {
  if (url.url.includes('youtube.com')) {
    return DataRoomItemType.YOUTUBE;
  } else {
    return DataRoomItemType.WEBSITE;
  }
};
