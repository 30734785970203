import { useDataRoomId } from '@/state/app-store.ts';
import { mutate } from 'swr';
import { useApi } from '../api.ts';

export function useDeleteMessages(): {
  deleteMessages: () => Promise<void>;
} {
  const { deleteFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const deleteMessages = () => deleteFetcher(`/chat/${dataRoomId}`).then(() => mutate(`/chat/${dataRoomId}`));

  return {
    deleteMessages,
  };
}
