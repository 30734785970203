import { Button, TextInput } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { getDfFieldProps } from '@/helpers/get-df-field-props.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useUpdateProject } from '@/api/projects/update-project.ts';
import { Project } from '@/models/project.interface.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';

export function ProjectRenameModalBody() {
  const { closeModal, context } = useGlobalModal<Project>();
  const { updateProject } = useUpdateProject();
  const { showLoader, hideLoader } = useGlobalLoader();

  const formik = useFormik({
    initialValues: {
      name: context.name,
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async ({ name }) => {
      closeModal();
      showLoader();
      await updateProject(context.id, { name });
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          {...getDfFieldProps('name', formik)}
          placeholder="Project name"
        />

        <div className="modal-divider mt-8 mb-6"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Save changes
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
