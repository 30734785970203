import { AddResourcePayload } from '@/models/add-resource-payload.interface.ts';
import { useApi } from '../api.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import { mutate } from 'swr';

export const useAddResource = (): {
  addResource: (payload: AddResourcePayload) => Promise<void>;
} => {
  const { putFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const addResource = (payload: AddResourcePayload) =>
    putFetcher<AddResourcePayload>(`/inputs/dataRoom/${dataRoomId}/add-resource`, {
      arg: payload,
    }).then(() => mutate(`/inputs/dataRoom/${dataRoomId}`));

  return {
    addResource,
  };
};
