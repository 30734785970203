import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useEffect } from 'react';

export function PublicApp() {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate(buildUrl([Urls.DASHBOARD]));
    }
  }, [isLoading, isAuthenticated]);

  return !isAuthenticated && !isLoading && <Outlet />;
}
