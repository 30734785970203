import { create } from 'zustand';

interface AgentPanelState {
  isVisible: boolean;
}

const store = create<AgentPanelState>(() => ({
  isVisible: false,
}));

export const useAgentPanel = () => ({
  isVisible: store((state) => state.isVisible),
  toggleAgentPanel: () => {
    store.setState((state) => ({
      isVisible: !state.isVisible,
    }));
  },
});
