import { create } from 'zustand';
import { ModalContent } from '@/models/modal-content.interface.ts';

type GlobalModalState = {
  open: boolean;
  content: ModalContent;
  context: object;
};

const useGlobalModalStore = create<GlobalModalState>(() => ({
  open: false,
  content: {} as ModalContent,
  context: {},
}));

const openModal = (content: ModalContent, context?: object) => {
  useGlobalModalStore.setState(() => ({
    open: true,
    content,
    context,
  }));
};

const closeModal = () => {
  useGlobalModalStore.setState(() => ({
    open: false,
  }));
};

export const useGlobalModalInternalState = () => ({
  open: useGlobalModalStore((state) => state.open),
  content: useGlobalModalStore((state) => state.content),
  closeModal,
});

export const useGlobalModal = <T = object>() => ({
  context: useGlobalModalStore<T>((state) => state.context as T),
  openModal,
  closeModal,
});
