import { usePublicApi } from '@/api/public-api.ts';
import { WebSearcherAnswerModel } from '@/models/web-searcher-answer-model.interface.ts';
import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';

export const useAskQuestionInPublicWebSearcherRoom = (): {
  askQuestionInPublicWebSearcherRoom: (room: WebSearcherRoom, question: string) => Promise<WebSearcherAnswerModel>;
} => {
  const { publicPatchFetcher } = usePublicApi();

  const askQuestionInPublicWebSearcherRoom = async (room: WebSearcherRoom, question: string) => {
    return publicPatchFetcher(`/websearcher/room/${room.id}/ask-question`, {
      arg: { message: question },
    });
  };

  return { askQuestionInPublicWebSearcherRoom };
};
