import axios, { AxiosError } from 'axios';
import toast from 'react-hot-toast';

const errorComposer = (error: AxiosError) => {
  const statusCode = error.response ? error.response.status : null;

  if (statusCode === 404) {
    toast.error('Resource not found');
  }

  if (statusCode === 403 || statusCode === 401) {
    toast.error('No access to this resource.');
  }

  if (statusCode === 413) {
    toast.error('File too large. Max size: 50MB');
  }

  if (statusCode?.toString()?.startsWith('5')) {
    toast.error('Something went wrong. Please try again later.');
  }
};

export const createDfAxios = (accessToken: string, workspaceId?: number) => {
  const dfAxios = axios.create({
    baseURL: import.meta.env.VITE_API_SERVER,
    timeout: 1000 * 60 * 5,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'DF-Workspace-Id': `${workspaceId}`,
    },
  });

  dfAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      errorComposer(error);
      return Promise.reject(error);
    },
  );

  return dfAxios;
};

export const createPublicDFAxios = () => {
  const dfAxios = axios.create({
    baseURL: import.meta.env.VITE_API_SERVER,
    timeout: 1000 * 60 * 5,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  dfAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      errorComposer(error);
      return Promise.reject(error);
    },
  );

  return dfAxios;
};
