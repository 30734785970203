import { mutate, useSWRConfig } from 'swr';
import { useApi } from '../api';
import { FlowTemplate } from '@/models/flow-template.interface';

export const useSaveAsFlowTemplate = () => {
  const { postFetcher } = useApi();
  const { cache } = useSWRConfig();

  const saveAsFlowTemplate = async (id: number) => {
    const flow: FlowTemplate = await postFetcher(`/flow/my-flow/${id}/save-as-template`, {
      arg: {},
    });
    await Promise.all(
      Array.from(cache.keys())
        .filter((key) => key.startsWith('/flow'))
        .map((key) => mutate(key)),
    );
    return flow;
  };

  return { saveAsFlowTemplate };
};
