export function MemoryContextEmptySlot({ index }: { index: number }) {
  return (
    <>
      <div
        className="border border-dashed border-notice-500 border-y flex items-center justify-center flex-col gap-2 h-28 rounded-xl"
        key={`empty-${index}`}
      >
        <div className="text-h5-desktop text-notice-1000 bg-notice-300 p-1 pl-3 pr-3 rounded-2xl">{index + 1}</div>
        <div className="flex gap-2">
          <img
            src="/pin-notice.svg"
            alt=""
          />
          <span>Pin content</span>
        </div>
      </div>
    </>
  );
}
