import { useApi } from '@/api/api.ts';

export const useGeneratePersona = (): {
  generatePersona: (personaId: number, wsChannel: string) => Promise<void>;
} => {
  const { postFetcher } = useApi();

  const generatePersona = async (personaId: number, wsChannel: string) => {
    return postFetcher(`/persona-canvas/${personaId}/generate/${wsChannel}`, {
      arg: {},
    });
  };

  return {
    generatePersona,
  };
};
