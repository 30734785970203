import { useState } from 'react';
import clsx from 'clsx';

interface AvatarProps {
  firstName?: string;
  lastName?: string;
  size?: number;
  className?: string;
  avatar?: string;
}

export function DfAvatar({ size = 32, className, firstName, lastName, avatar }: AvatarProps) {
  const [avatarAvailable, setAvatarAvailable] = useState(true);

  return (
    <>
      {avatar && avatarAvailable ? (
        <img
          className={clsx(`rounded-full`, className)}
          style={{ width: size, height: size }}
          src={avatar}
          alt=""
          onError={() => setAvatarAvailable(false)}
        />
      ) : (
        <div
          className={clsx(`rounded-full bg-green-200 flex items-center justify-center text-text-secondary`, className)}
          style={{ width: size, height: size, fontSize: size / 2 }}
        >
          {`${firstName?.[0].toUpperCase()}${lastName?.[0].toUpperCase()}`}
        </div>
      )}
    </>
  );
}
