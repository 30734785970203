import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Helmet } from 'react-helmet';
import { Button } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import { PersonaPositionCard } from './components/PersonaPositionCard.tsx';
import { usePersonaDetails } from '@/api/segments/persona/get-persona-details.ts';
import { useGeneratePersona } from '@/api/segments/persona/generate-persona.ts';
import { ToolName } from '../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { mutate } from 'swr';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { PersonaMainInfoCard } from './components/PersonaMainInfoCard.tsx';

export const PersonaDetailsPage = () => {
  const { personaId } = useParams<{ personaId: string }>();
  const { personaDetails } = usePersonaDetails(Number(personaId));
  const { generatePersona } = useGeneratePersona();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { listenerOn } = useWebSocketMessageListener();

  const onRegenerate = async () => {
    showLoader('Generating persona...');
    const wsChannel = `${ToolName.CANVAS_FILLER}: ${uuid()}`;
    await generatePersona(Number(personaId), wsChannel);

    const { listenerOff } = listenerOn(wsChannel, () => {
      mutate(`/persona-canvas/${personaId}`);
      hideLoader();
      listenerOff();
    });
  };

  // const onData = () => {
  //   console.log('Data');
  // };

  if (!personaDetails) {
    return null;
  }

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Data', href: buildUrl([Urls.DATA_PANEL]) },
          { label: 'Segments', href: buildUrl([Urls.SEGMENTS]) },
          { label: personaDetails.segment, href: buildUrl([Urls.SEGMENTS]) },
          { label: personaId ?? '', href: '#' },
        ],
        icon: '/user-black.svg',
        rightAddons: [
          // <Button
          //   key={'0'}
          //   color="secondary"
          //   onClick={onData}
          //   size="sm"
          // >
          //   <img
          //     src="/rows-black.svg"
          //     width={16}
          //     height={16}
          //     alt=""
          //   />
          //   Data
          // </Button>,
          <Button
            key={'1'}
            color="primary"
            onClick={onRegenerate}
            size="sm"
          >
            <img
              src="/regenerate-white.svg"
              width={16}
              height={16}
              alt=""
            />
            Regenerate
          </Button>,
        ],
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | {personaId ?? ''}</title>
      </Helmet>

      <div className="grid grid-cols-[30%,70%] items-start gap-6">
        <div className="flex flex-col gap-6">
          <PersonaMainInfoCard personaDetails={personaDetails} />

          {personaDetails.positions
            .sort((a, b) => {
              return a.hierarchyPosition - b.hierarchyPosition;
            })
            .slice(0, 2)
            .map((position, i) => (
              <PersonaPositionCard
                key={i}
                personaDetails={personaDetails}
                position={position}
              />
            ))}
        </div>

        <div className="flex flex-col gap-6">
          {personaDetails.positions
            .sort((a, b) => {
              return a.hierarchyPosition - b.hierarchyPosition;
            })
            .slice(2)
            .map((position, i) => (
              <PersonaPositionCard
                key={i}
                personaDetails={personaDetails}
                position={position}
              />
            ))}
        </div>
      </div>
    </PageWithHeaderLayout>
  );
};
