import clsx from 'clsx';
import { MemoryLogItem } from './MemoryLog';

interface MemoryLogBodyProps {
  isOpen: boolean;
  allTaskIsCompleted: boolean;
  logs: MemoryLogItem[];
}

export const MemoryLogBody = ({ isOpen, allTaskIsCompleted, logs }: MemoryLogBodyProps) => {
  if (!isOpen) return null;
  return (
    <div className="absolute p-4 rounded shadow w-[380px] bottom-[66px] right-0 bg-white">
      <h4
        className={clsx('flex items-center pb-5 text-[#191C30E5] text-[16px] leading-[150%] font-semibold', {
          'justify-between': allTaskIsCompleted,
        })}
      >
        <span>Memory log</span>
      </h4>
      <div className="max-h-[200px] overflow-y-auto pr-3">
        {logs?.map((log, index) => (
          <div
            key={index}
            className="w-full flex items-center text-[#191C30E5] text-[14px] leading-[150%] font-semibold my-2"
          >
            <img src="/check-green.svg" />

            <span>{log.message}</span>
          </div>
        ))}
        {!logs?.length && <div className="text-[#191C30E5] text-[14px] leading-[150%] font-semibold">No logs</div>}
      </div>
    </div>
  );
};
