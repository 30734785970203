export default function romanize(num: number) {
  if (isNaN(num)) return NaN;
  const digits = String(+num)?.split('');
  const key = [
    '',
    'c',
    'cc',
    'ccc',
    'cd',
    'd',
    'dc',
    'dcc',
    'dccc',
    'cM',
    '',
    'x',
    'xx',
    'xxx',
    'xl',
    'l',
    'lx',
    'lxx',
    'lxxx',
    'xc',
    '',
    'i',
    'ii',
    'iii',
    'iv',
    'v',
    'vi',
    'vii',
    'viii',
    'ix',
  ];
  let roman = '';
  let i = 3;
  while (i--) {
    const x = Number(digits?.pop());
    roman = (key[x + i * 10] || '') + roman;
  }

  return Array(+digits.join('') + 1).join('M') + roman;
}
