import { Badge, Card } from 'flowbite-react';
import clsx from 'clsx';
import { Tool, ToolCategory } from '../../../libs/tools/type.model.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { Project } from '@/models/project.interface.ts';
import { useProject } from '@/api/projects/get-project.ts';
import { useCreateMyFlow } from '@/api/flow/my-flow/create-my-flow.ts';
import { TiptapDocumentType } from '@/enums/tiptap-document-type.enum.ts';
import { Flow } from '@/models/flow.interface.ts';
import { useCreateProject } from '@/api/projects/create-project.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { mutate } from 'swr';
import { useCurrentWorkspace } from '@/state/app-store.ts';

const toolCategoryProps = {
  [ToolCategory.TEXTGEN]: {
    iconBackgroundColor: 'bg-mint-500',
    iconSrc: '/description-black.svg',
    badgeText: 'Text',
    badgeColor: 'mint',
  },
  [ToolCategory.IMAGEGEN]: {
    iconBackgroundColor: 'bg-yellow-400',
    iconSrc: '/category-black.svg',
    badgeText: 'Image',
    badgeColor: 'yellow',
  },
  [ToolCategory.RESEARCHGEN]: {
    iconBackgroundColor: 'bg-pink-300',
    iconSrc: '/research-black.svg',
    badgeText: 'Research',
    badgeColor: 'pink',
  },
  [ToolCategory.OTHER]: {
    iconBackgroundColor: 'bg-neutrals-300',
    iconSrc: '/canvases-black.svg',
    badgeText: 'Other',
    badgeColor: 'neutrals',
  },
};

export function ToolCard({ tool }: { tool: Tool }) {
  const { projectId } = useParams<{ projectId: string }>();
  const { project } = useProject(projectId ? +projectId : undefined);
  const { showLoader, hideLoader } = useGlobalLoader();
  const { createMyFlow } = useCreateMyFlow();
  const { createProject } = useCreateProject();
  const { iconBackgroundColor, iconSrc, badgeText, badgeColor } = toolCategoryProps[tool.category];
  const navigate = useNavigate();
  const currentWorkspaceId = useCurrentWorkspace()?.id;

  const onClick = async () => {
    showLoader('Creating tool...');
    let flow: Flow;
    let newProject: Project;

    if (project) {
      flow = await createMyFlow(tool.displayName, project.id, TiptapDocumentType.TOOL);
    } else {
      newProject = await createProject('Default project');
      await mutate(`/project/by-workspace/${currentWorkspaceId}`);
      flow = await createMyFlow(tool.displayName, newProject.id, TiptapDocumentType.TOOL);
    }

    hideLoader();
    navigate(
      buildUrl(
        [Urls.PROJECTS, Urls.PROJECT, Urls.FLOW_EDITOR],
        { projectId: projectId ?? newProject!.id, flowId: flow.id },
        { toolName: tool.name },
      ),
    );
  };

  return (
    <button
      onClick={onClick}
      disabled={tool.category === ToolCategory.OTHER}
    >
      <Card>
        <div className="flex justify-between items-center w-full mb-4">
          <div className={clsx('rounded-lg p-2.5', iconBackgroundColor)}>
            <img
              src={iconSrc}
              alt=""
            />
          </div>
        </div>
        <div className="text-caption-sm mb-4 text-left">{tool.displayName}</div>
        <div className="flex items-center gap-1">
          <Badge color={badgeColor}>{badgeText}</Badge>
        </div>
      </Card>
    </button>
  );
}
