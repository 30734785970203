import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { v4 as uuid } from 'uuid';
import { DFGenOutputView } from './components/DFGenOutputView';
import { ToolCategory } from 'src/libs/tools/type.model';

export const DfGenOutput = Node.create({
  name: 'dfGenOutput',

  group: 'block',
  content: 'block+',

  defining: false,
  isolating: true,

  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      toolcategory: {
        default: ToolCategory.TEXTGEN,
      },
      selectedtoolid: {
        default: null,
      },
      selectedtool: {
        default: null,
      },
      tooldata: {
        default: null,
      },
      id: {
        default: uuid(),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      addGenOuput:
        (config) =>
        ({ chain }) =>
          chain()
            .insertContent([
              {
                type: 'heading',
                content: [
                  {
                    type: 'text',
                    text: 'Your heading',
                  },
                ],
                attrs: {
                  level: 1,
                  id: uuid(),
                },
              },
              {
                type: this.name,
                content: [
                  {
                    type: 'paragraph',
                  },
                ],
                attrs: {
                  id: uuid(),
                  ...config,
                },
              },
            ])
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(DFGenOutputView);
  },
});
