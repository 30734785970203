import { Urls } from '@/consts/urls.ts';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { Helmet } from 'react-helmet';
import { TextInput } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useWorkspaces } from '@/api/workspace/get-workspaces.ts';
import { useState } from 'react';
import { WorkspaceCard } from './WorkspaceCard.tsx';

export const WorkspacesListPanel = () => {
  const { openModal } = useGlobalModal();
  const { workspaces } = useWorkspaces();
  const [search, setSearch] = useState('');

  const onWorkspaceAdd = () => {
    openModal(MODALS.CreateWorkspace);
  };

  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai | All workspaces</title>
      </Helmet>

      <PageWithHeaderLayout
        header={{
          breadcrumbs: [{ label: 'All workspaces', href: Urls.WORKSPACES }],
          icon: '/dashboard-black.svg',
        }}
      >
        <h1 className="text-h2-desktop mb-8">All workspaces</h1>

        <section className="mb-8 relative">
          <TextInput
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            className="w-96"
            icon={HiSearch}
          />
        </section>

        <section className="grid grid-cols-4 gap-4 mt-7">
          <button
            onClick={onWorkspaceAdd}
            className="large-add-button rounded-xl border border-gray-divider flex-col bg-primary-100 flex items-center justify-center min-h-[8rem] cursor-pointer hover:bg-primary-600 active:bg-primary-700 transition duration-300"
          >
            <img
              src="/add-blue.svg"
              alt=""
              width={33}
            />
          </button>
          {!!workspaces &&
            workspaces
              .filter((workspace) => workspace.name.toLowerCase().includes(search.toLowerCase()))
              .map((workspace) => (
                <WorkspaceCard
                  key={workspace.id}
                  workspace={workspace}
                />
              ))}
        </section>
      </PageWithHeaderLayout>
    </>
  );
};
