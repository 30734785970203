import { OrganizationUpdate } from '@/models/organization-update.interface.ts';
import { useApi } from '../api.ts';
import { mutate } from 'swr';

export const useUpdateOrganization = (): {
  updateOrganization: (model: OrganizationUpdate) => Promise<void>;
} => {
  const { patchFetcher } = useApi();

  const updateOrganization = async (arg: OrganizationUpdate): Promise<void> => {
    await patchFetcher('/organization', { arg });
    await mutate('/organization');
  };

  return {
    updateOrganization,
  };
};
