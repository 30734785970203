import { Button } from 'flowbite-react';
import { useAgentPanel } from './agent-panel.store.ts';

export const AgentPanelButton = () => {
  const { toggleAgentPanel } = useAgentPanel();

  return (
    <Button
      color="secondary"
      size="sm"
      onClick={toggleAgentPanel}
    >
      <img
        src="/generate-purple-and-black.svg"
        className="size-4"
        alt=""
      />
      Ask Data Room
    </Button>
  );
};
