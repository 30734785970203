import * as Y from 'yjs';
import { toUint8Array } from 'js-base64';
import { TiptapTransformer } from '@hocuspocus/transformer';
import { JSONContent } from '@tiptap/core';

export function convertYdocToJson(text: string): JSONContent {
  const ydoc = new Y.Doc();
  const uint8Array = toUint8Array(text);

  Y.applyUpdate(ydoc, uint8Array);
  return TiptapTransformer.fromYdoc(ydoc, 'default');
}
