import { useApi } from '@/api/api';

export const useUploadImage = () => {
  const { postFetcher } = useApi();

  const uploadImage = async (file: File): Promise<string> => {
    const b64 = (await toBase64(file)) as string;
    const response = await postFetcher('/tiptap-document/upload', {
      arg: { b64: b64.replace(/^data:image\/[a-z]+;base64,/, '') },
    });

    return import.meta.env.VITE_ASSETS + response;
  };

  return { uploadImage };
};

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
