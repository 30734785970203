import { cn } from '@/components/tiptap/lib/utils';

export const DropdownCategoryTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="text-[.65rem] font-semibold mb-1 uppercase text-neutral-500 dark:text-neutral-400 px-1.5">
      {children}
    </div>
  );
};

export const DropdownButton = ({
  children,
  isActive,
  onClick,
  disabled,
  className,
}: {
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) => {
  const buttonClass = cn(
    'flex items-center gap-2 px-2 py-1.5 text-xs font-medium text-text-primary text-left bg-transparent rounded-lg transition',
    !isActive && !disabled,
    'hover:bg-neutrals-200 active:bg-neutrals-300',
    isActive && !disabled && 'bg-neutrals-200',
    disabled && 'text-neutral-400 cursor-not-allowed',
    className,
  );

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
