export function WebSearcherAnswerLoadingPlaceholder() {
  return (
    <>
      <section className="web-searcher-section">
        <div className="web-searcher-section__header">
          <div className="web-searcher-section__header__icon bg-notice-300">
            <img
              src="/linked-services-black.svg"
              alt=""
            />
          </div>
          <h2 className="web-searcher-section__header__title">Sources</h2>
        </div>

        <div className="grid grid-cols-4 gap-4 animate-pulse">
          {new Array(4).fill(null).map((_, i) => (
            <div
              key={`${i}-source`}
              className="flex flex-col gap-3 h-full p-4 rounded-lg bg-neutrals-100"
            >
              <div className="bg-neutrals-300 rounded-2xl h-2 w-full"></div>
              <div className="bg-neutrals-300 rounded-2xl h-2 w-2/3"></div>
              <div className="flex items-center gap-2">
                <div className="bg-neutrals-300 rounded-full w-6 h-6"></div>
                <div className="bg-neutrals-300 rounded-2xl h-2 w-2/3"></div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="web-searcher-section">
        <div className="web-searcher-section__header">
          <div className="web-searcher-section__header__icon bg-mint-400">
            <img
              src="/search-check-black.svg"
              alt=""
            />
          </div>
          <h2 className="web-searcher-section__header__title">Answer</h2>
        </div>
        <div>
          {[100, 85, 90, 100, 90, 85, 85, 85, 75, 75, 90, 85, 85, 85, 75, 75].map((width, i) => (
            <div
              key={`${i}-line`}
              className="bg-neutrals-200 h-2 rounded-lg mb-4 animate-pulse"
              style={{ width: `${width}%` }}
            ></div>
          ))}
        </div>
      </section>

      <section className="web-searcher-section">
        <div className="web-searcher-section__header">
          <div className="web-searcher-section__header__icon bg-blue-200">
            <img
              src="/fast-check-black.svg"
              alt=""
            />
          </div>
          <h2 className="web-searcher-section__header__title">Related</h2>
        </div>
        <div className="flex flex-col gap-2">
          {new Array(3).fill(null).map((_, i) => (
            <div
              key={`${i}-follow-up`}
              className="bg-neutrals-200 rounded-xl w-full h-[3.25rem] animate-pulse"
            ></div>
          ))}
        </div>
      </section>
    </>
  );
}
