import { useGlobalLoaderInternalState } from './global-loader.store.ts';

export function GlobalLoader() {
  const { show, message } = useGlobalLoaderInternalState();

  return (
    <>
      {show && (
        <div className="fixed w-screen h-screen top-0 left-0 bg-white opacity-80 z-[1000] flex items-center justify-center">
          <div className="flex flex-col items-center gap-4">
            <img
              src="/loading-blue.svg"
              className="animate-spin-slow"
              alt=""
            />
            {message && <div className="text-body-lg">{message}</div>}
          </div>
        </div>
      )}
    </>
  );
}
