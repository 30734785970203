import { useApi } from '../api';
import { useDataRoomId } from '@/state/app-store.ts';
import useSWR from 'swr';
import { Segment } from '@/models/segment.interface.ts';

export const useSegments = () => {
  const { getFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const { data } = useSWR<Segment[]>(`/persona-canvas/segment/${dataRoomId}`, getFetcher);

  if (data) {
    data.forEach((segment) => {
      segment.personaCanvases.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    });
  }

  return {
    segments: data,
  };
};
