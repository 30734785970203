import { create } from 'zustand';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';
import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';

type TiptapState = {
  currentView: TiptapView | null;
  configurationEditorNodeId: string | null;
  accessMode: TiptapAccessMode | null;
  isTemplateEditing: boolean;
  isMemoryContextSidebarOpen: boolean;
};

const useTiptapStore = create<TiptapState>(() => {
  return {
    accessMode: null,
    currentView: null,
    configurationEditorNodeId: null,
    isTemplateEditing: false,
    isMemoryContextSidebarOpen: false,
  };
});

// getters

export const useTiptapCurrentView = () => useTiptapStore((state) => state.currentView);

export const useIsConfigurationEditorVisible = (nodeId: string) => {
  const [configurationEditorNodeId, currentView] = useTiptapStore((state) => [
    state.configurationEditorNodeId,
    state.currentView,
  ]);
  return nodeId && configurationEditorNodeId === nodeId && currentView === TiptapView.CONFIGURATION_MODE;
};

export const useTiptapAccessMode = () => useTiptapStore((state) => state.accessMode);

export const useIsTemplateEditing = () => useTiptapStore((state) => state.isTemplateEditing);

export const useMemoryContextSidebar = () => ({
  isMemoryContextSidebarOpen: useTiptapStore((state) => state.isMemoryContextSidebarOpen),
  toggleMemoryContextSidebar: () =>
    useTiptapStore.setState((state) => ({ isMemoryContextSidebarOpen: !state.isMemoryContextSidebarOpen })),
});

// setters
export const setTiptapCurrentView = (mode: TiptapView) => useTiptapStore.setState({ currentView: mode });

export const setTiptapAccessMode = (accessMode: TiptapAccessMode) => {
  useTiptapStore.setState({ accessMode: accessMode });
};

export const setIsTemplateEditing = (isTemplateEditing: boolean) => useTiptapStore.setState({ isTemplateEditing });

export const setIsMemoryContextSidebarOpen = (isMemoryContextSidebarOpen: boolean) =>
  useTiptapStore.setState({ isMemoryContextSidebarOpen });

export const openConfigurationEditor = (nodeId: string) =>
  useTiptapStore.setState({ configurationEditorNodeId: nodeId });

export const closeConfigurationEditor = () => useTiptapStore.setState({ configurationEditorNodeId: null });
