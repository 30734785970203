import { ToggleSwitch } from 'flowbite-react';

interface ToggleProps {
  onChange: (checked: boolean) => void;
  checkByDefault?: boolean;
  label?: string;
  subLabel?: string;
  color?: string;
}

export function Toggle({ checkByDefault = false, onChange, label, subLabel, color = 'blue' }: ToggleProps) {
  return (
    <div className="flex items-center justify-between gap-2 w-full">
      {label && (
        <div>
          <div className="text-sm font-medium leading-[17.50px]">{label}</div>
          {subLabel && <div className="text-xs text-neutrals-700 leading-[18px] mt-0.5">{subLabel}</div>}
        </div>
      )}
      <ToggleSwitch
        checked={checkByDefault}
        onChange={(e) => {
          onChange(e);
        }}
        color={color}
      />
    </div>
  );
}
