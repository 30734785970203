import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';

import { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { closeConfigurationEditor, useIsConfigurationEditorVisible, useTiptapCurrentView } from '../store';

import clsx from 'clsx';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';

import { Label, Select } from 'flowbite-react';
import { ToolsContext } from 'src/libs/tools/ToolsContext';
import { Tool, ToolCategory } from 'src/libs/tools/type.model';
import { ToolForm } from 'src/libs/tools/ToolForm';
import { ConfigurationModeView } from './ConfigurationModeView';
import { ExecutionModeView } from './ExecutionModeView';
import { useUpdateToolType } from 'src/libs/tools/api';
import { useTiptapToolsByCategory } from '../../../../../../libs/tools/use-tiptap-tools-by-category.tsx';
import { usePermissions } from '@/api/permission/get-permissions.ts';
import './DFGenOutputView.scss';

export const DFGenOutputView = function DFGenOutputView(props: NodeViewWrapperProps) {
  const toolCategory = props.node.attrs.toolcategory as ToolCategory;
  const nodeId = props?.node?.attrs?.id;

  const isExecutionMode = useTiptapCurrentView() == TiptapView.EXECUTION_MODE;
  const isConfigurationEditorVisible = useIsConfigurationEditorVisible(nodeId);

  const allTools = useContext(ToolsContext);
  const toolsByCategory = useTiptapToolsByCategory(allTools?.tools || []);
  const { updateToolType } = useUpdateToolType();
  const formWrapperRef = useRef<HTMLDivElement>(null);
  const { canSeeHiddenPromptField } = usePermissions();

  const saveChanges = async () => {
    const form = formWrapperRef.current?.querySelector('form');
    const formData = new FormData(form as HTMLFormElement);

    let toolData: { [k: string]: FormDataEntryValue };

    if (canSeeHiddenPromptField) {
      toolData = Object.fromEntries(formData);
    } else {
      toolData = {
        ...Object.fromEntries(formData),
        hiddenPrompt: props?.node.attrs?.tooldata?.hiddenPrompt ? props?.node.attrs?.tooldata?.hiddenPrompt : '',
      };
    }

    props.updateAttributes({
      tooldata: toolData,
    });

    if (props.node.attrs?.selectedtoolid) {
      return closeConfigurationEditor();
    }

    closeConfigurationEditor();
  };

  useEffect(() => {
    //set default tool
    if (!props.node.attrs?.selectedtool) {
      props.node.attrs.selectedtool = toolsByCategory[toolCategory][0];
    }
  }, []);

  const getTextToDisplay = () => {
    const prompt = props.node.attrs?.tooldata?.prompt;
    const query = props.node.attrs?.tooldata?.query;
    const tool = props.node.attrs?.selectedtool;

    if (prompt) {
      return prompt;
    }

    if (query) {
      return query;
    }

    if (tool && tool.displayName) {
      return tool.displayName;
    }

    return '';
  };

  return (
    <NodeViewWrapper>
      <div className="df-node-border rounded-2xl border border-gray-divider">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="flex flex-col relative w-full">
              {!isExecutionMode ? (
                <ConfigurationModeView
                  node={props?.node}
                  updateAttributes={props.updateAttributes}
                  toolCategory={toolCategory}
                  textToDisplay={getTextToDisplay()}
                />
              ) : (
                <ExecutionModeView
                  editor={props.editor}
                  nodeId={nodeId}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* </Panel> */}
      {createPortal(
        <>
          <div
            className={clsx(
              'bg-gray-dark z-[110] transition-opacity duration-150 opacity-0 pointer-events-none w-full h-full absolute top-0 ',
              {
                'opacity-40 pointer-events-auto': isConfigurationEditorVisible,
              },
            )}
            onClick={() => closeConfigurationEditor()}
          />

          <div
            className={clsx(
              'transition-all duration-200 ease-in-out rounded-3xl fixed m-3 right-0 w-full max-w-[440px]  top-0 bottom-0 flex flex-col z-[300]',
              {
                'translate-x-[200px] opacity-0 pointer-events-none': !isConfigurationEditorVisible,
                'translate-x-0 opacity-100 pointer-events-auto': isConfigurationEditorVisible,
              },
            )}
          >
            <div className="bg-gray-ultra-light rounded-t-2xl p-6 flex-col justify-between relative">
              <div className="text-gray-dark text-h2-desktop font-medium mb-2 flex justify-stretch w-full ">
                <div className="grow">Configuration</div>
                <div>
                  <button
                    className=" hover:opacity-70"
                    onClick={() => closeConfigurationEditor()}
                  >
                    <img
                      src="/close-side-panel.svg"
                      className="cursor-pointer"
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="text-gray-dark text-sm font-normal">Define your prompt and other parameters</div>
            </div>

            <div
              ref={formWrapperRef}
              className="p-8 overflow-y-scroll grow bg-white"
            >
              <div className="mb-5">
                <Label htmlFor="select-tool">Select tool</Label>
                <Select
                  id="select-tool"
                  name="select-tool"
                  value={props.node.attrs?.selectedtool?.displayName ?? ''}
                  onChange={(e) => {
                    const toolDisplayName = e.target.value;
                    const tool = allTools?.tools?.find((tool) => tool.displayName === toolDisplayName);
                    props.updateAttributes({ selectedtool: tool });

                    if (props.node.attrs?.selectedtoolid && tool) {
                      updateToolType(props.node.attrs?.selectedtoolid, tool.name);
                    }
                  }}
                >
                  {toolsByCategory[toolCategory]?.map((option: Tool) => (
                    <option key={option.name}>{option.displayName}</option>
                  ))}
                </Select>
              </div>

              {props.node.attrs?.selectedtool && (
                <ToolForm
                  tool={props.node.attrs?.selectedtool}
                  hasOutput={false}
                  hideSaveButton={true}
                  initialValue={props.node.attrs.tooldata}
                  enableReinitialize={true}
                  tiptap={{
                    turnOnTiptapInputAsPromptInput: true,
                    toc: props.editor.extensionStorage.tableOfContents,
                  }}
                />
              )}
            </div>

            <div className="p-8 flex flex-col rounded-b-2xl  bg-white">
              <button
                className="rounded-lg px-4 py-3 text-sm cursor-pointer duration-200 ease-in-out flex justify-center text-white bg-indigo-600 hover:bg-indigo-700"
                onClick={saveChanges}
              >
                Save changes
              </button>
            </div>
          </div>
        </>,
        document.getElementById('root')!,
      )}
    </NodeViewWrapper>
  );
};
