import useSWR from 'swr';
import { useApi } from '../api.ts';
import { User } from '@/models/user.interface.ts';

export const useWorkspaceMembers = (
  workspaceId?: number,
): {
  workspaceMembers: User[];
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR(
    workspaceId ? `/workspace-access/members/${workspaceId}` : null,
    getFetcher,
  );

  return {
    workspaceMembers: data,
    isLoading,
    error,
  };
};
