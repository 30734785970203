export const convertVideoLinkToEmbed = (videoSrc: string): string | null => {
  switch (true) {
    case videoSrc.includes('youtube.com'):
      return videoSrc.replace('/watch?v=', '/embed/');
    case videoSrc.includes('facebook.com'):
      return `https://www.facebook.com/plugins/video.php?height=315&href=${videoSrc}&show_text=false&width=560&t=0`;
    case videoSrc.includes('tiktok.com'):
      return `https://www.tiktok.com/player/v1/${new URL(videoSrc).pathname.split('/').pop()}`;
    default:
      return null;
  }
};
