import { Tool, ToolCategory } from './type.model.ts';

export const useTiptapToolsByCategory: (tools: Tool[]) => {
  [ToolCategory.RESEARCHGEN]: Tool[];
  [ToolCategory.TEXTGEN]: Tool[];
  [ToolCategory.IMAGEGEN]: Tool[];
  [ToolCategory.OTHER]: Tool[];
} = (tools: Tool[]) => {
  return {
    [ToolCategory.TEXTGEN]: tools ? tools.filter((tool) => tool.category === ToolCategory.TEXTGEN) : [],
    [ToolCategory.IMAGEGEN]: tools ? tools.filter((tool) => tool.category === ToolCategory.IMAGEGEN) : [],
    [ToolCategory.RESEARCHGEN]: tools ? tools.filter((tool) => tool.category === ToolCategory.RESEARCHGEN) : [],
    [ToolCategory.OTHER]: tools ? tools.filter((tool) => tool.category === ToolCategory.OTHER) : [],
  };
};
