import { create } from 'zustand';

interface LeftSideNavState {
  leftSideNavCollapsed: boolean;
  setLeftSideNavCollapsed: (collapsed: boolean) => void;
}

const useLeftSideNavStore = create<LeftSideNavState>((set) => ({
  leftSideNavCollapsed: false,
  setLeftSideNavCollapsed: (collapsed: boolean) => set({ leftSideNavCollapsed: collapsed }),
}));

const collapseLeftSideNav = () => useLeftSideNavStore.setState({ leftSideNavCollapsed: true });
const expandLeftSideNav = () => useLeftSideNavStore.setState({ leftSideNavCollapsed: false });
const useLeftSideNavCollapsed = () => useLeftSideNavStore((state) => state.leftSideNavCollapsed);

export const useLeftSideNav = () => ({
  isNavCollapsed: useLeftSideNavCollapsed(),
  collapseNav: collapseLeftSideNav,
  expandNav: expandLeftSideNav,
});
