import { Badge, Card } from 'flowbite-react';
import { useCanvases } from '@/api/canvases/api.ts';
import { Link } from 'react-router-dom';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';

export function CompanyCanvasesShortcuts() {
  const { canvases } = useCanvases();

  return (
    <div className="grid grid-cols-4 gap-4">
      <Link to={buildUrl([Urls.CANVASES])}>
        <Card className="card-shortcut">
          <div className="card-shortcut__icon">
            <img
              src="/canvases-black.svg"
              alt=""
            />
          </div>
          <h3 className="card-shortcut__title">
            <span>Strategy canvases</span>
            <Badge color="blue">{canvases?.length ?? 0}</Badge>
            <img
              src="/chevron-right-blue.svg"
              alt=""
            />
          </h3>
          <div className="card-shortcut__description">
            Generate strategy canvases that are versatile and ready to use for any industry, product, service, or niche!
          </div>
        </Card>
      </Link>

      <Card className="card-shortcut">
        <div className="card-shortcut__icon">
          <img
            src="/products-black.svg"
            alt=""
          />
        </div>
        <h3 className="card-shortcut__title">
          <span>Products</span>
          <Badge color="blue">Coming soon!</Badge>
        </h3>
        <div className="card-shortcut__description">
          Create product collections and add your company's products in just a few seconds.
        </div>
      </Card>

      <Card className="card-shortcut">
        <div className="card-shortcut__icon">
          <img
            src="/offers-black.svg"
            alt=""
          />
        </div>
        <h3 className="card-shortcut__title">
          <span>Offers</span>
          <Badge color="blue">Coming soon!</Badge>
        </h3>
        <div className="card-shortcut__description">
          Offers Create offers for your company's products and use this data to create campaigns.
        </div>
      </Card>

      <Card className="card-shortcut">
        <div className="card-shortcut__icon">
          <img
            src="/user-black.svg"
            alt=""
          />
        </div>
        <h3 className="card-shortcut__title">
          <span>Personas</span>
          <Badge color="blue">Coming soon!</Badge>
        </h3>
        <div className="card-shortcut__description">
          Generate personas for your products, campaigns, and marketing strategies in just a few seconds.
        </div>
      </Card>
    </div>
  );
}
