import useSWR from 'swr';
import { useApi } from '../api.ts';
import { OrganizationInvitation } from '@/models/organization-invitation.interface.ts';

export const useOrganizationInvitations = (): {
  organizationInvitations: OrganizationInvitation[];
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR('/organization/invitations', getFetcher);

  return {
    organizationInvitations: data,
    isLoading,
    error,
  };
};
