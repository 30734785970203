import { useRef } from 'react';
import { BlockEditor } from './components/BlockEditor';
import * as Y from 'yjs';
import { HocuspocusProvider } from '@hocuspocus/provider';

import './styles/index.css';
import './styles/editor.css';

const TiptapEditor = ({ docName, token, editable }: { docName: string; token: string; editable: boolean }) => {
  const ydoc = useRef(new Y.Doc());
  const provider = useRef(
    new HocuspocusProvider({
      name: docName,
      url: import.meta.env.VITE_HOCUSPOCUS,
      document: ydoc.current,
      token,
    }),
  );

  return (
    <BlockEditor
      editable={editable}
      hasCollab={true}
      ydoc={ydoc.current}
      provider={provider.current}
    />
  );
};

export default TiptapEditor;
