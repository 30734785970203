import { useCurrentWorkspace } from '@/state/app-store.ts';
import { createDfAxios } from './create-df-axios.ts';
import { useAccessToken } from '@/api/get-access-token.ts';

export const useApi = () => {
  const { getAccessToken } = useAccessToken();
  const workspaceId = useCurrentWorkspace()?.id;

  const getFetcher = async (url: string) => {
    const token = await getAccessToken();
    return createDfAxios(token, workspaceId)
      .get(url)
      .then((res) => res.data);
  };

  const postFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    const token = await getAccessToken();
    return createDfAxios(token, workspaceId)
      .post(url, arg)
      .then((res) => res.data);
  };

  const deleteFetcher = async <T>(url: string, data?: { arg: T }) => {
    const token = await getAccessToken();
    return createDfAxios(token, workspaceId)
      .delete(url, data && { data: data.arg })
      .then((res) => res.data);
  };

  const putFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    const token = await getAccessToken();
    return createDfAxios(token, workspaceId)
      .put(url, arg)
      .then((res) => res.data);
  };

  const patchFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    const token = await getAccessToken();
    return createDfAxios(token, workspaceId)
      .patch(url, arg)
      .then((res) => res.data);
  };

  return {
    getFetcher,
    postFetcher,
    deleteFetcher,
    putFetcher,
    patchFetcher,
  };
};
