import { ExpandLeftNavButton } from '@/layouts/MainLayout/components/ExpandLeftNavButton.tsx';
import { Breadcrumbs } from '@/components/Breadcrumbs.tsx';

export function WebSearcherPageHeader() {
  return (
    <>
      <header className="flex items-center h-[66px]">
        <div className="flex items-center gap-3">
          <ExpandLeftNavButton />
          <div className="p-1.5 rounded bg-gray-ultra-light">
            <img
              src="/search-check-black.svg"
              width={20}
              height={20}
              alt=""
            />
          </div>
          <div>
            <Breadcrumbs breadcrumbs={[{ label: 'Web Searcher', href: '#' }]} />
          </div>
        </div>
      </header>
    </>
  );
}
