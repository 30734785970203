import { isModifiedWithin } from './is-modified-within';

interface Entity {
  name: string;
  modifiedAt: string;
}

export const filterEntity = (entity: Entity, searchValue: string, modifiedValue: string) => {
  const searchFilter = searchValue.toLowerCase();
  if (searchFilter && !entity.name.toLowerCase().includes(searchFilter)) {
    return false;
  }

  if (modifiedValue === 'Modified: Last 7 days' && !isModifiedWithin(entity.modifiedAt, 7)) {
    return false;
  }
  if (modifiedValue === 'Modified: Last 14 days' && !isModifiedWithin(entity.modifiedAt, 14)) {
    return false;
  }
  if (modifiedValue === 'Modified: Last 30 days' && !isModifiedWithin(entity.modifiedAt, 30)) {
    return false;
  }

  return true;
};
