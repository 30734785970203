import { Urls } from '@/consts/urls';
import { buildUrl } from '@/helpers/build-url';
import { isOrganizationAdmin } from '@/helpers/is-organization-admin';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

export function SettingsSubHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const menuItems = [
    {
      label: 'General',
      url: Urls.WORKSPACE_SETTINGS,
      organizationAdmin: false,
    },
    {
      label: 'Workspace members',
      url: Urls.WORKSPACE_MEMBERS,
      organizationAdmin: true,
    },
  ];

  return (
    <div className="px-8 py-3 flex gap-1 cursor-pointer border-b">
      {menuItems
        .filter((menuItem) => !menuItem.organizationAdmin || isOrganizationAdmin(user))
        .map((menuItem) => {
          const isActive = location.pathname.includes(menuItem.url);
          return (
            <div
              key={menuItem.url}
              onClick={() => navigate(buildUrl([menuItem.url]))}
              className={clsx(
                'text-caption-md px-4 py-2 rounded-lg',
                !isActive && 'text-text-secondary',
                isActive && 'text-primary-default bg-primary-100',
              )}
            >
              {menuItem.label}
            </div>
          );
        })}
    </div>
  );
}
