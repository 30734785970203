import clsx from 'clsx';
import { Button, TextInput } from 'flowbite-react';
import { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { useChatHistory } from '@/api/chat/get-chat-history.ts';
import { useSendMessage } from '@/api/chat/send-message.ts';
import { useDeleteMessages } from '@/api/chat/delete-messages.ts';
import { useAgentPanel } from './agent-panel.store.ts';
import { v4 as uuid } from 'uuid';
import { ChatMessage } from '@/models/chat-message.interface.ts';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { mutate } from 'swr';
import { useDataRoomId } from '@/state/app-store.ts';
import { htmlToText } from '@/helpers/html-to-text.ts';
import { llmMarked } from '../../helpers/llmMarked';
import './AgentPanel.scss';

export function AgentPanel() {
  const { messages, isLoading } = useChatHistory();
  const { sendMessage } = useSendMessage();
  const { deleteMessages } = useDeleteMessages();
  const { isVisible, toggleAgentPanel } = useAgentPanel();
  const [messagesHistory, setMessagesHistory] = useState<ChatMessage[]>([]);
  const { listenerOn } = useWebSocketMessageListener();
  const dataRoomId = useDataRoomId();

  const exampleQuestions = [
    'What is the pricing plan?',
    'How does a web application work?',
    'What are the benefits of using the application?',
  ];

  const [question, setQuestion] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const onExampleQuestionClick = (index: number) => {
    setQuestion(exampleQuestions[index]);
  };

  const onQuestionSend = async () => {
    const wsChannel = 'chat-question:' + uuid();
    let messageResponse = '';

    setQuestion('');

    const message: ChatMessage = {
      text: htmlToText(question),
      type: 'user',
    };

    const messagesWithNewQuestion = [...messagesHistory, message];

    setMessagesHistory(messagesWithNewQuestion);
    await sendMessage(htmlToText(question), wsChannel);

    const { listenerOff } = listenerOn(wsChannel, (data: string | Record<string, boolean>) => {
      if (typeof data === 'string') {
        messageResponse += data;

        const messageAnswer: ChatMessage = {
          text: llmMarked(messageResponse) as string,
          type: 'bot',
        };

        setMessagesHistory([...messagesWithNewQuestion, messageAnswer]);
      } else if (data.done) {
        listenerOff();
        setTimeout(() => {
          mutate(`/chat/${dataRoomId}`);
        }, 250);
      }
    });
  };

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = async (event) => {
    if (event.key === 'Enter') {
      await onQuestionSend();
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messagesHistory]);

  useEffect(() => {
    setMessagesHistory(messages);
  }, [messages]);

  return (
    <div
      className={clsx(
        ' z-[1001] agent-panel fixed right-0 w-[420px] bg-white shadow-lg transform transition-transform duration-300 border-l border-solid border-gray-divider flex flex-col',
        { 'translate-x-0': isVisible, 'translate-x-full': !isVisible },
      )}
    >
      <div className="p-3 flex justify-between items-center border-b border-gray-divider">
        <div className="flex flex-row gap-3 items-center">
          <img
            src="/df-logo.svg"
            className="border border-gray-divider p-2 rounded-full"
            alt=""
          />
          <h2 className="text-subtitle-md font-semibold">DigitalFirst AI Agent</h2>
        </div>
        <div className="flex flex-row gap-2">
          <img
            src="/trash-gray.svg"
            className="cursor-pointer"
            alt=""
            onClick={deleteMessages}
          />
          <img
            src="/close-side-panel.svg"
            className="cursor-pointer"
            alt=""
            onClick={toggleAgentPanel}
          />
        </div>
      </div>
      {!isLoading && (
        <>
          {messagesHistory?.length > 0 && (
            <div className="agent-message-list flex flex-1 flex-col gap-6 p-3 overflow-auto">
              {messagesHistory.map(({ type, text }, index) => (
                <div
                  key={index}
                  className={clsx(
                    'max-w-72 text-sm p-2 flex flex-row-reverse gap-3 items-start',
                    { 'self-end bg-gray-ultra-light rounded-lg rounded-br-none': type === 'user' },
                    { 'justify-end': type !== 'user' },
                  )}
                >
                  <div dangerouslySetInnerHTML={{ __html: llmMarked(text) as string }}></div>
                  {type !== 'user' && (
                    <img
                      src="/df-logo-small.svg"
                      className="border border-gray-divider p-2 rounded-full"
                      alt=""
                    />
                  )}
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          )}
          {!messagesHistory?.length && (
            <div className="flex flex-1 flex-col gap-8 items-center justify-center p-3">
              <div className="flex flex-col items-center justify-center">
                <div className="rounded-lg bg-tint-blue p-2 mb-1">
                  <img
                    src="/chat-blue.svg"
                    alt=""
                  />
                </div>
                <div className="font-medium text-base">Ask a question</div>
              </div>
              <div className="flex flex-col gap-3 w-full">
                {exampleQuestions.map((question, index) => (
                  <div
                    key={index}
                    onClick={() => onExampleQuestionClick(index)}
                    className="font-medium hover:bg-gray-bg active:bg-gray-bg transition cursor-pointer text-sm border border-gray-divider p-3 gap-2 items-center rounded-lg w-full flex justify-between"
                  >
                    <div>{question}</div>
                    <div className="text-primary-default flex">
                      Ask
                      <img
                        src="/arrow-up-blue.svg"
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <div className="flex flex-row gap-2 p-3">
        <TextInput
          value={question}
          onInput={(e: ChangeEvent<HTMLInputElement>) => setQuestion(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Ask a question..."
          className="flex flex-1"
        />
        <Button
          onClick={onQuestionSend}
          size="xs"
          color="primary"
        >
          <img
            src="/send-white.svg"
            alt=""
          />
        </Button>
      </div>
    </div>
  );
}
