import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { SettingsSubHeader } from './components/SettingsSubHeader';
import { Avatar, Button, FileInput, Label, TextInput } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { setCurrentWorkspace, useCurrentWorkspace } from '@/state/app-store';
import { useEffect, useRef, useState } from 'react';
import { useUpdateWorkspace } from '@/api/workspace/update-workspace';
import { getDfFieldProps } from '@/helpers/get-df-field-props';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store';
import { DIALOGS } from '@/consts/dialogs.const';
import { useDeleteWorkspace } from '@/api/workspace/delete-workspace';
import clsx from 'clsx';
import { useWorkspace } from '@/api/workspace/get-workspace';
import { useWorkspaces } from '@/api/workspace/get-workspaces';
import './WorkspaceSettings.scss';

export function WorkspaceSettings() {
  const currentWorkspace = useCurrentWorkspace();
  const { workspaces } = useWorkspaces();
  const { workspace } = useWorkspace(currentWorkspace?.id);

  const { openDialog } = useGlobalDialog();
  const { deleteWorkspace } = useDeleteWorkspace();
  const { updateWorkspace } = useUpdateWorkspace();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { showLoader, hideLoader } = useGlobalLoader();
  const formik = useFormik<{ name?: string; avatarB64?: string }>({
    initialValues: {},
    onSubmit: async (model, { resetForm }) => {
      showLoader('Saving workspace...');
      const response = await updateWorkspace({ name: model.name, avatarB64: model.avatarB64 });
      setCurrentWorkspace(response);
      hideLoader();
      resetForm();
    },
  });

  useEffect(() => {
    formik.setFieldValue('name', workspace?.name);
  }, [workspace]);

  const avatarUrl = workspace?.avatarUrl && import.meta.env.VITE_ASSETS + workspace.avatarUrl;

  const [workspaceImage, setWorkspaceImage] = useState(avatarUrl);

  useEffect(() => {
    setWorkspaceImage(avatarUrl);
  }, [avatarUrl]);

  return (
    <PageWithHeaderLayout
      subHeader={<SettingsSubHeader />}
      header={{
        breadcrumbs: [{ label: 'Settings', href: '#' }],
        icon: '/settings-black.svg',
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Settings</title>
      </Helmet>

      <div className="gap-6 grid grid-cols-2">
        <form
          className="border rounded-2xl p-6 h-fit"
          onSubmit={formik.handleSubmit}
        >
          <FormikProvider value={formik}>
            <h4 className="text-h4-desktop">Workspace</h4>
            <div className="flex items-center gap-4 my-6">
              {workspaceImage ? (
                <Avatar
                  img={workspaceImage}
                  size="lg"
                  className="workspace-settings__avatar"
                />
              ) : (
                <div className="flex shrink-0 items-center justify-center rounded-lg size-20 text-5xl font-bold bg-pink-300">
                  {workspace?.name.charAt(0).toUpperCase()}
                </div>
              )}
              <div
                className="text-primary-600 text-caption-md flex items-center gap-2 cursor-pointer"
                onClick={() => fileInputRef?.current?.click()}
              >
                <img
                  src="/upload-blue.svg"
                  width={'16px'}
                />
                <span>Upload</span>
                <FileInput
                  className="hidden"
                  ref={fileInputRef}
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (!file) {
                      return;
                    }

                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const base64String = reader.result as string;
                      formik.setFieldValue('avatarB64', base64String);
                      setWorkspaceImage(base64String);
                    };
                    reader.readAsDataURL(file);
                  }}
                />
              </div>
              <div
                className={clsx(
                  'text-text-secondary text-caption-md flex items-center gap-2 cursor-pointer',
                  !workspaceImage ? 'hidden' : null,
                )}
                onClick={() => {
                  formik.setFieldValue('avatarB64', null);
                  setWorkspaceImage(null);
                  if (fileInputRef.current) {
                    fileInputRef.current.files = new DataTransfer().files;
                  }
                }}
              >
                <img
                  src="/trash-gray.svg"
                  width={'16px'}
                />
                Remove
              </div>
            </div>
            <div>
              <Label value="Workspace name" />
              <TextInput {...getDfFieldProps('name', formik)} />
            </div>
            <div className="mt-6 flex justify-end">
              <Button
                color="primary"
                size="sm"
                type="submit"
              >
                Save changes
              </Button>
            </div>
          </FormikProvider>
        </form>

        <div className="border rounded-2xl p-6 h-fit">
          <h4>Delete workspace</h4>
          <div className="flex mt-6 gap-3 bg-notice-100 rounded-xl p-4">
            <img src="/warning-yellow-filled.svg" />
            <span className="text-body-md text-notice-1000">
              If you delete your organization, all workspaces, projects and other assets will be permanently deleted.
            </span>
          </div>
          <div className="mt-6 flex justify-end">
            <Button
              color="failure"
              size="sm"
              disabled={!workspaces || workspaces?.length === 1}
              onClick={() =>
                openDialog(DIALOGS.DeleteWorkspace, async () => {
                  showLoader('Deleting workspace...');
                  await deleteWorkspace(workspace!.id);
                  hideLoader();
                })
              }
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </PageWithHeaderLayout>
  );
}
