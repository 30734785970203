import { Editor } from '@tiptap/core';
import { findElementId } from './findElementId';
import { replacePromptVars } from './replacePromptVars';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';

export const getTextContentBeetweenHeadings = (editor: Editor, prompt: string) => {
  let replacmentPrompt = prompt;
  const headingsIds = findElementId(prompt, `[data-type="${MentionReferenceType.SECTION}"]`);

  if (!headingsIds || !headingsIds.length) return replacmentPrompt;

  for (const headingId of headingsIds) {
    const selector = `[data-id="${headingId}"]`;
    const firstHeader: HTMLElement = editor.extensionStorage.tableOfContents.content.find(
      ({ id }: { id: string }) => id === headingId,
    )?.dom;
    const firstHeaderLevel = firstHeader?.tagName;

    let next = firstHeader?.nextSibling as HTMLElement;
    let nextLevel = next?.tagName ?? '';
    const content = [];

    while (next && nextLevel !== firstHeaderLevel) {
      let contentText = next.querySelector('[data-node-view-content-react]');

      if (!contentText) {
        contentText = next;
      }

      contentText && content.push(contentText);
      next = next?.nextSibling as HTMLElement;
      nextLevel = next?.tagName ?? '';
    }

    const contentText = content.map((e) => e.textContent).join(' ');

    replacmentPrompt = replacePromptVars(replacmentPrompt, contentText, selector);
  }

  return replacmentPrompt;
};
