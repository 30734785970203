import { useTiptapAccessMode } from '../store';
import { Editor, NodeViewContent } from '@tiptap/react';
import { useExecuteField } from '../helpers/executeField';

import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';

export const ExecutionModeView = ({ editor, nodeId }: { editor: Editor; nodeId: string }) => {
  const tiptapAccessMode = useTiptapAccessMode();
  const { execute, isExecuting } = useExecuteField();

  return (
    <>
      {tiptapAccessMode === TiptapAccessMode.FULL && (
        <div className="df-node-header flex justify-between items-center bg-gray-ultra-light px-3 py-2 rounded-t-2xl border-b border-gray-divider text-body-sm text-gray-dark">
          <button
            className="link link--md ml-auto"
            onClick={async () => {
              await execute(editor, nodeId);
            }}
          >
            {isExecuting ? (
              <img
                src="/tiptap-spiner.gif"
                className="!size-4 scale-[2]"
                alt=""
              />
            ) : (
              <img
                src="/generate-blue.svg"
                width={16}
                height={16}
                alt=""
              />
            )}
            Generate
          </button>
        </div>
      )}
      <div>
        <NodeViewContent className="content w-full focus-visible:outline-none p-4"></NodeViewContent>
      </div>
    </>
  );
};
