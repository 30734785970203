import { Button, Modal } from 'flowbite-react';
import { useRef } from 'react';
import { useGlobalDialogInternalState } from './global-dialog.store.ts';

export function GlobalDialog() {
  const initialFocusRef = useRef<HTMLDivElement>(null);
  const { open, config, closeDialog } = useGlobalDialogInternalState();

  return (
    <Modal
      show={open}
      size="md"
      popup
      onClose={closeDialog}
      initialFocus={initialFocusRef} // to prevent focus on X button
    >
      <Modal.Header />
      <Modal.Body>
        <div className="flex flex-col items-center text-center mx-8">
          <div className="p-1.5 mb-4 rounded-lg bg-tint-yellow">
            <img
              src="/warning-yellow.svg"
              alt=""
            />
          </div>

          <div
            className="mb-5"
            ref={initialFocusRef}
          >
            <div className="text-subtitle-md mb-2">{config.content.header}</div>
            {config.content.subheader && (
              <div className="text-body-md text-text-secondary">{config.content.subheader}</div>
            )}
          </div>

          <div className="flex items-center justify-center gap-4">
            <Button
              color="secondary"
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <Button
              color="red"
              onClick={() => {
                config.onConfirm();
                closeDialog();
              }}
            >
              {config.content.confirmButtonText}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
