import { useApi } from '../api';
import useSWR from 'swr';
import { MemoryContextItem } from '@/models/memory-context-item.interface.ts';
import { useMemo, useCallback } from 'react';
import { mutate } from 'swr';
import { SortableElement } from '@/models/sortable-element.interface';

export const useMemoryContext = (
  projectId: string,
): {
  memoryContextItems: Array<MemoryContextItem | null>;
  maxSlots: number;
  allSlotsFilled: boolean;
  isLoading: boolean;
  error: unknown;
  updateMemoryContext: (newItems: SortableElement[]) => void;
} => {
  const { getFetcher, patchFetcher } = useApi();
  const maxSlots = 8;

  const { data, isLoading, error } = useSWR(projectId && `/memory-context/${projectId}`, getFetcher);

  const memoryContextItems: Array<MemoryContextItem | null> = useMemo(() => {
    if (isLoading || !data || data.length === 0) {
      return Array(maxSlots).fill(null);
    }

    const items = [];

    for (let i = 0; i < maxSlots; i++) {
      items.push(data[i] || null);
    }

    return items;
  }, [isLoading, data]);

  const updateMemoryContext = useCallback(
    async (newItems: SortableElement[]) => {
      const sortableMemoryContextItems: MemoryContextItem[] = [];

      newItems.forEach((item) => {
        const memoryContextItem = memoryContextItems.find((memoryContextItem) => memoryContextItem?.id === item.id);
        memoryContextItem!.order = item.order;
        sortableMemoryContextItems.push(memoryContextItem as MemoryContextItem);
      });

      mutate(`/memory-context/${projectId}`, patchFetcher(`/memory-context/set-order`, { arg: newItems }), {
        optimisticData: sortableMemoryContextItems,
        rollbackOnError: true,
        populateCache: true,
        revalidate: false,
      });
    },
    [memoryContextItems],
  );

  return {
    memoryContextItems,
    maxSlots,
    allSlotsFilled: memoryContextItems.every((item) => item !== null),
    isLoading,
    error,
    updateMemoryContext,
  };
};
