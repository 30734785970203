import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { SettingsSubHeader } from './components/SettingsSubHeader';
import { Button, Table, TextInput } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { MODALS } from '@/components/GlobalModal/modals.const';
import { useCurrentWorkspace } from '@/state/app-store';
import { useWorkspaceMembers } from '@/api/workspace/get-workspace-members';
import { User } from '@/models/user.interface';
import { dateToRelativeString } from '@/helpers/date-to-relative-string';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { DIALOGS } from '@/consts/dialogs.const';
import { useRemoveWorkspaceMember } from '@/api/workspace/remove-workspace-member';

export function WorkspaceMembers() {
  const { openModal } = useGlobalModal();
  const workspace = useCurrentWorkspace();
  const { openDialog } = useGlobalDialog();
  const { removeWorkspaceMember } = useRemoveWorkspaceMember();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { workspaceMembers, isLoading } = useWorkspaceMembers(workspace?.id);

  const [searchTerm, setSearchTerm] = useState('');

  const getMemberFirstName = (workspaceMember: User) => {
    return workspaceMember.given_name ?? workspaceMember.name;
  };

  const getMemberLastName = (workspaceMember: User) => {
    return workspaceMember.family_name ?? workspaceMember.name;
  };

  const getMemberFullName = (workspaceMember: User) => {
    return workspaceMember.family_name && workspaceMember.given_name
      ? `${workspaceMember.given_name} ${workspaceMember.family_name}`
      : workspaceMember.name;
  };

  const filteredMembers = workspaceMembers?.filter((workspaceMember: User) => {
    const fullName = getMemberFullName(workspaceMember).toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading]);

  return (
    <PageWithHeaderLayout
      subHeader={<SettingsSubHeader />}
      header={{
        breadcrumbs: [{ label: 'Settings', href: '#' }],
        icon: '/settings-black.svg',
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Settings</title>
      </Helmet>

      <div className="flex justify-between">
        <h3 className="text-h3-desktop">Workspace members</h3>
        <div className="flex flex-row gap-3">
          <div className="relative">
            <TextInput
              placeholder="Search members..."
              className="w-56"
              icon={HiSearch}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Button
            color="primary"
            onClick={() => openModal(MODALS.AddToWorkspace, workspaceMembers)}
          >
            <img src="/members-white.svg" /> Add to workspace
          </Button>
        </div>
      </div>

      <div className="mt-8">
        <Table hoverable>
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Last active</Table.HeadCell>
            <Table.HeadCell className="w-20">
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {filteredMembers?.map((workspaceMember) => (
              <Table.Row
                key={workspaceMember.user_id}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell>
                  <div className="flex items-center gap-3">
                    {workspaceMember?.picture ? (
                      <img
                        src={workspaceMember?.picture}
                        className="size-8 rounded-full h-auto"
                      />
                    ) : (
                      <DfAvatar
                        firstName={getMemberFirstName(workspaceMember)}
                        lastName={getMemberLastName(workspaceMember)}
                      />
                    )}
                    <span className="text-caption-md">{getMemberFullName(workspaceMember)}</span>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-primary-default">
                  {dateToRelativeString(workspaceMember.last_login)}
                </Table.Cell>
                <Table.Cell>
                  <img
                    className="cursor-pointer"
                    src="/remove-gray.svg"
                    onClick={() => {
                      openDialog(DIALOGS.DeleteWorkspaceMember, async () => {
                        showLoader('Deleting member...');
                        await removeWorkspaceMember(workspace?.id, workspaceMember.user_id);
                        hideLoader();
                      });
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </PageWithHeaderLayout>
  );
}
