import { CanvasPositionCard } from './components/CanvasPositionCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useCanvasDetails } from '@/api/canvases/api.ts';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { DropdownCanvasOptions } from '../../components/DropdownCanvasOptions.tsx';
import { GenerateCanvasButton } from './components/GenerateCanvasButton.tsx';

export function CanvasDetails() {
  const { canvasId } = useParams<{ canvasId: string }>();
  const { canvasDetails, isLoading } = useCanvasDetails(canvasId!);
  const navigate = useNavigate();

  if (isLoading || !canvasDetails) return;

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Strategy canvases', href: buildUrl([Urls.CANVASES]) },
          { label: 'My canvases', href: buildUrl([Urls.CANVASES, Urls.MY_CANVASES]) },
          { label: canvasDetails?.name ?? '', href: '#' },
        ],
        icon: '/canvases-black.svg',
        leftAddons: [
          <DropdownCanvasOptions
            key={canvasDetails.id}
            instanceId={canvasDetails.id}
            onDelete={() => {
              navigate(buildUrl([Urls.CANVASES, Urls.MY_CANVASES]));
            }}
          />,
        ],
        rightAddons: [
          <GenerateCanvasButton
            key={canvasDetails.id}
            canvasDetails={canvasDetails}
          />,
        ],
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | {canvasDetails.name}</title>
      </Helmet>

      <div className="grid grid-cols-2 items-start gap-6">
        {canvasDetails.positions
          .sort((a, b) => {
            return a.hierarchyPosition - b.hierarchyPosition;
          })
          .map((position) => (
            <CanvasPositionCard
              key={position.id}
              position={position}
              canvasDetails={canvasDetails}
            />
          ))}
      </div>
    </PageWithHeaderLayout>
  );
}
