import { Label, Radio } from 'flowbite-react';
import { getDfRadioProps } from '@/helpers/get-df-field-props.ts';
import clsx from 'clsx';
import { Project } from '@/models/project.interface.ts';
import { FormikProps } from 'formik';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProjectSelectRadio = ({ project, formik }: { project: Project; formik: FormikProps<any> }) => {
  return (
    <Label>
      <div className="flex items-center gap-2 px-3 py-2 rounded-lg hover:border-primary-600 hover:bg-primary-100 transition has-[:checked]:border-primary-600 has-[:checked]:bg-primary-100 border border-neutrals-400">
        <Radio {...getDfRadioProps('newProjectId', String(project.id), formik)} />
        <div className={clsx('rounded-lg p-2', project.color ?? 'bg-green-light')}>
          <img
            src="/business-black.svg"
            alt=""
          />
        </div>
        <span className="text-caption-md">{project.name}</span>
      </div>
    </Label>
  );
};
