import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect } from 'react';
import { useGlobalLoader } from '../components/GlobalLoader/global-loader.store.ts';
import { useSearchParams } from 'react-router-dom';
import { useAcceptInvitation } from '@/api/organization/accept-invitation.ts';
import { useDebouncedCallback } from 'use-debounce';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useRefreshAuth0Token } from '@/helpers/refresh-auth0-token.ts';
import { usePostHog } from 'posthog-js/react';
import { useTriggerUserEvent } from '@/api/user-events/api.ts';
import { UserEvent } from '@/api/user-events/model.ts';
import { useInvitationData } from '@/state/app-store.ts';
import toast from 'react-hot-toast';

export function AuthGuard({ children }: { children: ReactNode }) {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const { showLoader, hideLoader } = useGlobalLoader();
  const [searchParams] = useSearchParams();
  const { acceptInvitation } = useAcceptInvitation();
  const debauncedHandleAcceptInvitation = useDebouncedCallback(handleAcceptInvitation, 1000);
  const { listenerOn } = useWebSocketMessageListener();
  const { refreshAuth0Token } = useRefreshAuth0Token();
  const posthog = usePostHog();
  const { trigerUserEvent } = useTriggerUserEvent();
  const { invitationData, setInvitationData } = useInvitationData();

  function handleAcceptInvitation() {
    const invitationId = searchParams.get('invitation') ?? invitationData?.invitationId;
    const organizationId = searchParams.get('organization') ?? invitationData?.organizationId;

    if (invitationId && organizationId) {
      acceptInvitation(organizationId, invitationId).then(() => {
        setInvitationData(null);
        searchParams.delete('invitation');
        searchParams.delete('organization');
        toast.success('Invitation accepted!');
      });
    }
  }

  const trySavingInvitationDataToStore = () => {
    const invitationId = searchParams.get('invitation');
    const organizationId = searchParams.get('organization');

    if (invitationId && organizationId) {
      setInvitationData({ organizationId, invitationId });
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      trySavingInvitationDataToStore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user?.sub) return;

    const { listenerOff } = listenerOn(`refresh-token:${user.sub}`, () => refreshAuth0Token());
    posthog?.identify(user.sub, {
      email: user.email,
    });

    trigerUserEvent(UserEvent.user_logged_in);

    return () => {
      listenerOff();
    };
  }, [user?.sub]);

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, hideLoader, showLoader]);

  if (isLoading) {
    return null;
  }

  if (isAuthenticated) {
    debauncedHandleAcceptInvitation();
    return <>{children}</>;
  } else {
    loginWithRedirect({ authorizationParams: { redirect_uri: window.location.origin } });
  }
}
