import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { DropdownDataRoomItemOptions } from './DropdownDataRoomItemOptions.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { Tabs } from 'flowbite-react';
import { AddedDataItemPreview } from './AddedDataItemPreview.tsx';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { AddedDataItemSummary } from './AddedDataItemSummary.tsx';
import { DataRoomItemType } from '@/enums/data-room-item.enum.ts';

export function PreviewAndSummaryModal({
  open,
  item,
  onClose,
}: {
  open: boolean;
  item: DataRoomItem | null;
  onClose: () => void;
}) {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const animationDuration = 400;

  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    if (open) {
      overlayRef.current?.classList.add('!z-50');
      overlayRef.current?.classList.add('!bg-[rgba(0,0,0,0.5)]');
      setShowContent(true);
    } else {
      overlayRef.current?.classList.remove('!bg-[rgba(0,0,0,0.5)]');
      setTimeout(() => {
        overlayRef.current?.classList.remove('!z-50');
        setShowContent(false);
      }, animationDuration);
    }
  }, [open]);

  return (
    <>
      <div
        className={clsx(`fixed top-0 left-0 w-[100svw] h-[100svh] bg-[rgba(0,0,0,0)] -z-50 transition-colors`)}
        style={{ transitionDuration: `${animationDuration}ms` }}
        onClick={onClose}
        ref={overlayRef}
      ></div>
      <div
        className={clsx(
          'flex flex-col fixed right-3 top-3 overflow-hidden w-[45svw] h-[calc(100svh-1.5rem)] transform bg-white rounded-2xl z-50',
          { 'translate-x-0': open, 'translate-x-[105%]': !open },
        )}
        style={{ transitionDuration: `${animationDuration}ms` }}
      >
        {item && showContent && (
          <>
            <div className="p-6 bg-neutrals-200 flex items-center justify-between">
              <div className="flex items-center">
                <div className="p-2.5 rounded-lg bg-white mr-3">
                  <img
                    src="/file-black.svg"
                    alt=""
                  />
                </div>
                <h2 className="text-h5-desktop mr-2">
                  {item.extension ? (
                    item.name
                  ) : (
                    <a
                      target="_blank"
                      href={item.url}
                      rel="noreferrer"
                    >
                      {item.name}
                    </a>
                  )}
                </h2>
                <DropdownDataRoomItemOptions item={item}>
                  <div>
                    <IconButton
                      icon="/three-dots-gray.svg"
                      size={24}
                      alt="More"
                    />
                  </div>
                </DropdownDataRoomItemOptions>
              </div>

              <IconButton
                icon="/close-side-panel-black.svg"
                size={24}
                alt="Close"
                onClick={onClose}
              />
            </div>

            <div className="grow min-h-0">
              <Tabs
                aria-label="Preview and Data Summary"
                variant="underline"
              >
                {item.type !== DataRoomItemType.WEBSITE && (
                  <Tabs.Item
                    active
                    title="Preview"
                  >
                    <AddedDataItemPreview item={item} />
                  </Tabs.Item>
                )}
                <Tabs.Item title="Data Summary">
                  <AddedDataItemSummary item={item} />
                </Tabs.Item>
              </Tabs>
            </div>
          </>
        )}
      </div>
    </>
  );
}
