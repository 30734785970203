import { Dropdown, FlowbiteDropdownTheme } from 'flowbite-react';
import type { Placement } from '@floating-ui/core';
import { ReactElement, ReactNode } from 'react';

export interface SubDropdownProps {
  renderTrigger: (theme?: FlowbiteDropdownTheme) => ReactElement;
  children: ReactNode;
  placement?: 'auto' | Placement;
}

export function SubDropdown({ renderTrigger, children, placement = 'auto' }: SubDropdownProps) {
  return (
    <Dropdown
      label=""
      placement={placement}
      trigger="hover"
      renderTrigger={renderTrigger}
    >
      {children}
    </Dropdown>
  );
}
