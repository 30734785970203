import { Button } from 'flowbite-react';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useMyFlow } from '@/api/flow/my-flow/get-my-flow.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useSharingTiptapDocument } from '@/api/tiptap-document/sharing-tiptap-document.ts';
import { Toggle } from '@/components/Toggle.tsx';
import { mutate } from 'swr';

export function ShareFlowModalBody() {
  const { flowId, projectId } = useParams<{ flowId: string; projectId: string }>();
  const { myFlow, isLoading } = useMyFlow(+flowId!);
  const [isShared, setIsShared] = useState<boolean | null>(null);
  const { shareTiptapDocument, unshareTiptapDocument } = useSharingTiptapDocument();

  const sharingLink = useMemo(() => {
    const pathToFlow = buildUrl([Urls.SHARED, Urls.FLOW_EDITOR], {
      flowId: myFlow.id,
    });
    return window.location.origin + pathToFlow;
  }, [myFlow.id, projectId]);

  useEffect(() => {
    if (!isLoading) {
      setIsShared(myFlow.tiptapDocument.isShared);
    }
  }, [isLoading, myFlow.tiptapDocument, myFlow.tiptapDocument.isShared]);

  const handleSharing = async (wantToShare: boolean) => {
    setIsShared(wantToShare);
    wantToShare
      ? await shareTiptapDocument(myFlow.tiptapDocument.id)
      : await unshareTiptapDocument(myFlow.tiptapDocument.id);
    await mutate(`/flow/my-flow/${flowId}`);
  };

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(sharingLink);
    toast.success('Link copied to clipboard!');
  };

  return (
    <>
      <div className="my-8">
        {isShared !== null && (
          <Toggle
            checkByDefault={isShared}
            onChange={handleSharing}
            label="Share public link with anyone"
            subLabel="Allow users to view the flow in view only mode."
          />
        )}
      </div>
      {isShared && (
        <Button
          color="primary"
          className="mt-8 w-full"
          onClick={handleCopyLink}
        >
          <img
            src="/duplicate-white.svg"
            width="21"
            height="20"
            alt=""
          />
          Copy link to clickboard
        </Button>
      )}
    </>
  );
}
