import { Button } from 'flowbite-react';
import { AccordionInfo } from '@/components/AccordionInfo.tsx';

import { useCallback, useEffect, useRef } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { AddResourcePayload, InputType } from '@/models/add-resource-payload.interface.ts';
import { inputsPayloadMapper } from '@/helpers/inputs-payload-mapper.ts';
import { UploadedFile } from '@/components/UploadedFile.tsx';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { GLOBAL_LOADER_MESSAGES } from '@/components/GlobalLoader/global-loader-messages.const.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useAddResource } from '@/api/inputs/add-resource.ts';

export class DropboxFile {
  name: string;
  url: string;
  size: number;
  type = 'dropbox';

  constructor(externalDropboxFile: ExternalDropboxFile) {
    this.name = externalDropboxFile.name;
    this.url = externalDropboxFile.link;
    this.size = externalDropboxFile.bytes;
  }
}

interface ExternalDropboxFile {
  id: string;
  name: string;
  link: string;
  bytes: number;
  icon: string;
  thumbnailLink?: string | undefined;
  isDir: boolean;
}

export function DataRoomAddFromDropboxModalBody() {
  const { addResource } = useAddResource();
  const dropboxContainer = useRef<HTMLDivElement>(null);
  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal } = useGlobalModal();

  const formik = useFormik<{ dropboxFiles: DropboxFile[] }>({
    initialValues: {
      dropboxFiles: [],
    },
    onSubmit: async ({ dropboxFiles }: { dropboxFiles: DropboxFile[] }) => {
      const payloads: AddResourcePayload[] = inputsPayloadMapper[InputType.Dropbox](dropboxFiles);
      closeModal();
      showLoader(GLOBAL_LOADER_MESSAGES.AddingData);
      await Promise.all(payloads.map((payload) => addResource(payload)));
      hideLoader();
    },
  });

  const handleDropboxSuccess = useCallback(
    (extFiles: ExternalDropboxFile[]) => {
      const dropboxFiles = extFiles.map((file) => new DropboxFile(file));
      // setFieldValue is for formik
      formik.setFieldValue('dropboxFiles', [...formik.values.dropboxFiles, ...dropboxFiles]);
      // values.dropboxFiles is for this function to work
      formik.values.dropboxFiles = [...formik.values.dropboxFiles, ...dropboxFiles];
    },
    [formik],
  );

  const createDropboxComponent = useCallback(() => {
    const options = {
      success: handleDropboxSuccess,
      cancel: function () {},
      linkType: 'direct' as const,
      multiselect: true,
      folderselect: false,
      sizeLimit: 10000000, //10Mb
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const button = window['Dropbox'].createChooseButton(options) as HTMLButtonElement;
    dropboxContainer.current?.appendChild(button);
  }, [handleDropboxSuccess]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.id = 'dropboxjs';
    script.dataset['appKey'] = import.meta.env.VITE_DROPBOX_APP_KEY;

    document.head.appendChild(script);

    script.onload = () => {
      createDropboxComponent();
    };
  });

  return (
    <FormikProvider value={formik}>
      <AccordionInfo
        question="What type of content should I share?"
        answer="You can share any content that provides value to your business and marketing. This includes campaign briefings, materials, articles, and previous strategies."
      />

      <form onSubmit={formik.handleSubmit}>
        <Button
          color="secondary"
          className="w-full mt-8"
          onClick={() => dropboxContainer.current?.querySelector('a')?.click()}
        >
          <img
            src="/dropbox.svg"
            alt=""
          />
          Select from Dropbox
        </Button>
        <div
          id="dropbox-box"
          className="hidden"
          ref={dropboxContainer}
        ></div>

        <div className="mt-6 flex flex-col gap-2">
          {formik.values.dropboxFiles.map((dropboxFile, index) => (
            <UploadedFile
              key={index}
              file={dropboxFile}
              url={dropboxFile.url}
              onRemove={(file) => {
                formik.setFieldValue(
                  'dropboxFiles',
                  formik.values.dropboxFiles.filter((f) => f !== file),
                );
              }}
            />
          ))}
        </div>

        <div className="modal-divider mt-8 mb-6"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Add
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
