import { Modal } from 'flowbite-react';
import { useGlobalModalInternalState } from './global-modal-store.ts';

export function GlobalModal() {
  const { open, content, closeModal } = useGlobalModalInternalState();

  return (
    <>
      <Modal
        show={open}
        onClose={closeModal}
        popup
      >
        <Modal.Header className="p-6 pb-2">
          <div className="text-subtitle-lg mb-2">{content.header}</div>
          {content.subheader && <div className="text-body-sm text-text-secondary">{content.subheader}</div>}
        </Modal.Header>
        <Modal.Body>
          <div className="modal-divider mb-5"></div>
          <div>{content.body}</div>
        </Modal.Body>
      </Modal>
    </>
  );
}
