import MenuList from '@/components/tiptap/extensions/SlashCommand/MenuList';
import { Editor } from '@tiptap/core';
import { ReactRenderer } from '@tiptap/react';
import { SuggestionProps, SuggestionKeyDownProps } from '@tiptap/suggestion';
import { MutableRefObject } from 'react';
import { Instance, Props } from 'tippy.js';

export const mentionRender = (tippy: MutableRefObject<Instance<Props>[]>) => () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let component: any;
  let scrollHandler: (() => void) | null = null;

  return {
    onStart: (props: SuggestionProps) => {
      component = new ReactRenderer(MenuList, {
        props,
        editor: props.editor,
      });

      const { view } = props.editor;

      const getReferenceClientRect = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const rect = props.clientRect()!;

        let yPos = rect.y;

        if (rect.top + component.element.offsetHeight + 40 > window.innerHeight) {
          const diff = rect.top + component.element.offsetHeight - window.innerHeight + 40;
          yPos = rect.y - diff;
        }

        return new DOMRect(rect.x, yPos, rect.width, rect.height);
      };

      scrollHandler = () => {
        tippy.current?.[0].setProps({
          getReferenceClientRect,
        });
      };

      view.dom.parentElement?.addEventListener('scroll', scrollHandler);

      tippy.current?.[0].setProps({
        getReferenceClientRect,
        appendTo: () => document.body,
        content: component.element,
      });

      tippy.current?.[0].show();
    },
    onUpdate(props: SuggestionProps) {
      component.updateProps(props);
    },
    onKeyDown(props: SuggestionKeyDownProps) {
      if (props.event.key === 'Escape') {
        tippy.current?.[0].hide();

        return true;
      }

      if (!tippy.current?.[0].state.isShown) {
        tippy.current?.[0].show();
      }

      return component.ref?.onKeyDown(props);
    },

    onExit({ editor }: { editor: Editor }) {
      tippy.current?.[0].hide();
      if (scrollHandler) {
        const { view } = editor;
        view.dom.parentElement?.removeEventListener('scroll', scrollHandler);
      }
      component?.destroy();
    },
  };
};
