import useSWR from 'swr';
import { useApi } from '../api.ts';
import { Project } from '@/models/project.interface.ts';

export const useProject = (
  projectId?: number,
): {
  project: Project;
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR(projectId ? `/project/${projectId}` : null, getFetcher);

  return {
    project: data,
    isLoading,
    error,
  };
};
