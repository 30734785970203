import useSWR from 'swr';
import { useApi } from '@/api/api.ts';
import {
  ExecuteToolPayload,
  KnownToolCategories,
  Tool,
  ToolCategory,
  ToolInstancePayload,
  ToolName,
  ToolOutput,
} from './type.model';
import { useMemo } from 'react';

export const useGetAllTools = (): {
  tools: Tool[];
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();
  const { data, error, isLoading } = useSWR('/tools', getFetcher);

  const tools = useMemo(() => {
    if (!data) return [];

    return data.map((tool: Tool) => ({
      ...tool,
      category: KnownToolCategories.includes(tool.category) ? tool.category : ToolCategory.OTHER,
    }));
  }, [data]);

  return {
    tools,
    isLoading,
    error,
  };
};

export const useToolInstance = (toolId?: number) => {
  const { getFetcher } = useApi();
  const { data, error, isLoading } = useSWR<ToolInstancePayload>(toolId ? `/tools/${toolId}` : null, getFetcher);
  return {
    toolInstance: data,
    isLoading,
    error,
  };
};

export const useCreateTool = () => {
  const { postFetcher } = useApi();

  const createTool = async (name: ToolName, projectId: number): Promise<{ id: number }> => {
    const response = await postFetcher('/tools', { arg: { name, projectId } });
    return response;
  };

  return {
    createTool,
  };
};

export const useOutputHistory = (toolId?: number) => {
  const { getFetcher } = useApi();
  const { data, isLoading, error } = useSWR(toolId ? `/tools/output-history/${toolId}` : null, getFetcher);
  const outputs = (data?.map((i: { output: ToolOutput }) => i.output) as Array<ToolOutput>) ?? [];

  return {
    outputs,
    isLoading,
    error,
  };
};

export const useUpdateCurrentOutput = () => {
  const { postFetcher } = useApi();
  const updateCurrentOutput = async (id: number, output: ToolOutput, historyItemId: number) => {
    if (historyItemId < 0) return;

    const response = await postFetcher(`/tools/change-output`, { arg: { id, output, historyId: historyItemId } });

    return {
      response,
    };
  };

  return {
    updateCurrentOutput,
  };
};

export const useExecuteTool = () => {
  const { postFetcher } = useApi();

  const executeTool = async (data: ExecuteToolPayload): Promise<{ wsChannel: string; toolId: number }> => {
    return await postFetcher<ExecuteToolPayload>('/tools/execute', { arg: data });
  };

  return {
    executeTool,
  };
};

export const useUpdateToolType = () => {
  const { patchFetcher } = useApi();

  const updateToolType = async (toolId: number, name: ToolName): Promise<{ id: number }> => {
    return await patchFetcher(`/tools/${toolId}`, { arg: { name } });
  };

  return {
    updateToolType,
  };
};
