import { useWorkspaces } from '../api/workspace/get-workspaces.ts';
import { setCurrentWorkspace, useCurrentWorkspace } from '../state/app-store.ts';
import { useCreateWorkspace } from '../api/workspace/create-workspace.ts';
import { ReactNode, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

export function ExistingWorkspaceGuard({ children }: { children: ReactNode }) {
  const { workspaces, isLoading: isLoadingWorkspaces, error } = useWorkspaces();
  const currentWorkspace = useCurrentWorkspace();
  const { createWorkspace, isLoading: isCreatingWorkspace } = useCreateWorkspace();
  const [appInitialized, setAppInitialized] = useState(false);

  useEffect(() => {
    if (isLoadingWorkspaces || isCreatingWorkspace) return;

    if (error) {
      toast.error('Something went wrong. Please try again later.');
      return;
    }

    if (!workspaces?.length) {
      // If there are no workspaces, create one
      createWorkspace('My first workspace');
      setAppInitialized(true);
    } else if (currentWorkspace && workspaces.find((w) => w.id === currentWorkspace.id)) {
      // If the current workspace is in the list of workspaces, we are good to go
      setAppInitialized(true);
    } else {
      // If the current workspace is not in the list of workspaces, set the first workspace as the current
      setCurrentWorkspace(workspaces[0]);
      setAppInitialized(true);
    }
  }, [isLoadingWorkspaces, workspaces, isCreatingWorkspace]);

  return appInitialized && <>{children}</>;
}
