import { NodeViewContent, NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';

import './DFInputEditor.scss';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

export const DFUserInputView = function DFUserInputView({ node }: NodeViewWrapperProps) {
  const [showContentRequiredError, setShowContentRequiredError] = useState(false);

  useEffect(() => {
    const content = node.content.content[0].textContent;
    content ? setShowContentRequiredError(false) : setShowContentRequiredError(true);
  }, [node]);

  return (
    <>
      <NodeViewWrapper>
        <div className="flex items-center mb-2">
          <img
            className="!h-[16px] !w-[16px] mr-2"
            src="/add_user_input.svg"
            alt=""
          />
          <p className="text-nav-sub !mt-0">
            Provide input <span className="font-normal text-neutrals-700">(Required)</span>
          </p>
        </div>
        <div
          className={clsx('rounded-2xl border border-gray-divider df-border', {
            'border-negative-700': showContentRequiredError,
          })}
        >
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between gap-1">
              <div className="flex flex-col relative w-full">
                <NodeViewContent className="content df-user-input-editor" />
              </div>
            </div>
          </div>
        </div>
      </NodeViewWrapper>

      {showContentRequiredError && <p className="text-input-text text-negative-700">This field is required.</p>}
    </>
  );
};
