import { SortableElement } from '@/models/sortable-element.interface';
import { DragEndEvent } from '@dnd-kit/core';

export const sortableHandleDragEnd = (
  event: DragEndEvent,
  items: SortableElement[],
  callback: (items: SortableElement[]) => void,
) => {
  const currentOrder = items.map((item) => ({
    id: item?.id,
    order: item?.order,
  })) as SortableElement[];

  const newOrder = structuredClone(currentOrder);

  const movedElement = newOrder.find((item) => item.id === event.active.id);
  const overElement = newOrder.find((item) => item.id === event.over?.id);

  if (!movedElement || !overElement) {
    return;
  }

  const movedElementOrder = movedElement?.order;
  const overElementOrder = overElement?.order;

  movedElement.order = overElementOrder;
  overElement.order = movedElementOrder;

  callback(newOrder.filter((item) => item.id));
};
