import { FormikProvider, useFormik } from 'formik';
import { Button, Checkbox } from 'flowbite-react';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import clsx from 'clsx';
import { useOrganizationRoles } from '@/api/organization/get-organization-roles';
import { OrganizationRole } from '@/models/organization-role.interface';
import { useOrganizationWorkspaces } from '@/api/workspace/get-organization-workspaces';
import { Workspace } from '@/models/workspace.interface';
import { useEffect } from 'react';
import { useUpdateOrganizationMember } from '@/api/organization/update-organization-members';
import { User } from '@/models/user.interface';

export function EditTeamMemberModalBody() {
  const { organizationRoles } = useOrganizationRoles();
  const { workspaces } = useOrganizationWorkspaces();
  const { updateOrganizationMember } = useUpdateOrganizationMember();

  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal, context } = useGlobalModal<User>();

  const getMemberFirstName = (organizationMember: User) => {
    return organizationMember.given_name ?? organizationMember.name;
  };

  const getMemberLastName = (organizationMember: User) => {
    return organizationMember.family_name ?? organizationMember.name;
  };

  const getMemberFullName = (organizationMember: User) => {
    return organizationMember.family_name && organizationMember.given_name
      ? `${organizationMember.given_name} ${organizationMember.family_name}`
      : 'Unknown User';
  };

  const formik = useFormik<{ roles: OrganizationRole[]; workspaces: Workspace[] }>({
    initialValues: {
      roles: context.roles,
      workspaces: [],
    },
    onSubmit: async ({ workspaces, roles }) => {
      showLoader('Editing team member...');
      await updateOrganizationMember({
        userId: context.user_id,
        workspaces: workspaces.map((value) => value.id),
        roles: roles.map((item) => item.name),
      });
      hideLoader();
      closeModal();
    },
  });

  useEffect(() => {
    if (workspaces) {
      formik.setFieldValue(
        'workspaces',
        context.workspaces
          .map((item) => workspaces.find((workspace) => workspace.id === item.workspaceId))
          .filter(Boolean),
      );
    }
  }, [workspaces]);

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <h5 className="text-h5-desktop">General information</h5>
          <div className="bg-gray-ultra-light rounded-xl p-4 flex gap-4 items-center mt-4">
            {context?.picture ? (
              <img
                src={context?.picture}
                className="size-8 rounded-full h-auto"
              />
            ) : (
              <DfAvatar
                firstName={getMemberFirstName(context)}
                lastName={getMemberLastName(context)}
              />
            )}
            <div className="flex flex-col gap-1">
              <span className="text-caption-md">{getMemberFullName(context)}</span>
              <span className="text-body-sm">{context.name}</span>
            </div>
          </div>
          <div className="mt-4 flex gap-3 flex-col">
            {organizationRoles?.map((organizationRole) => (
              <div
                key={organizationRole.id}
                className={clsx(
                  'flex gap-3 items-center p-4 border rounded-lg cursor-pointer',
                  !!formik.values.roles.find((role) => role.id === organizationRole.id) &&
                    ' border-primary-default bg-slate-100',
                )}
                onClick={() => {
                  formik.setFieldValue('roles', [organizationRole]);
                }}
              >
                <Checkbox checked={!!formik.values.roles.find((role) => role.id === organizationRole.id)} />
                <span className="text-gray-dark text-caption-md">{organizationRole.name}</span>
              </div>
            ))}
          </div>

          <h5 className="text-h5-desktop mt-6 mb-4">Workspaces</h5>

          <div className="flex flex-col gap-3">
            {workspaces?.map((workspace) => (
              <div
                key={workspace.id}
                className={clsx(
                  'flex gap-3 items-center p-4 border rounded-lg cursor-pointer',
                  !!formik.values.workspaces.find((item) => item.id === workspace.id) &&
                    ' border-primary-default bg-slate-100',
                )}
                onClick={() => {
                  if (formik.values.workspaces.find((item) => item.id === workspace.id)) {
                    formik.setFieldValue(
                      'workspaces',
                      formik.values.workspaces.filter((item) => item.id !== workspace.id),
                    );
                  } else {
                    formik.setFieldValue('workspaces', [...formik.values.workspaces, workspace]);
                  }
                }}
              >
                <Checkbox checked={!!formik.values.workspaces.find((item) => item.id === workspace.id)} />
                <div className="flex items-center justify-center rounded-lg size-10 text-h3-desktop bg-pink-300">
                  {workspace.name.charAt(0).toUpperCase()}
                </div>
                <span className="text-gray-dark text-caption-md">{workspace.name}</span>
              </div>
            ))}
          </div>

          <div className="modal-divider mt-8 mb-6"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
            >
              Save changes
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
}
