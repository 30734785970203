import { useApi } from '../api.ts';
import { mutate } from 'swr';

export const useRemoveWorkspaceMember = () => {
  const { postFetcher } = useApi();

  const removeWorkspaceMember = async (workspaceId?: number, userId?: string): Promise<void> => {
    await postFetcher(`/workspace-access/remove-access/workspace/${workspaceId}/user/${userId}`, { arg: {} });
    await mutate(`/workspace-access/members/${workspaceId}`);
  };

  return {
    removeWorkspaceMember,
  };
};
