import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useExecuteTool } from './api';
import { FormikDynamicForm } from './FormikDynamicForm';
import { getValidationSchema } from './getValidationSchema';
import {
  ExecuteToolPayload,
  ToolFormikFields,
  Tool,
  ToolOutput as ToolOutputType,
  ExecuteToolFieldsPayload,
} from './type.model';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ToolOutput } from './ToolOutput';
import clsx from 'clsx';
import { TOC } from '@/components/tiptap/extensions/DigitalFirst/editing/PromptEditor.tsx';
import fileToBase64 from '@/helpers/file-to-base64';
import { v4 as uuid } from 'uuid';
import { usePermissions } from '@/api/permission/get-permissions';

export interface DynamicFormProps {
  tool: Tool;
  hasOutput: boolean;
  initialValue?: Record<string, string>;
  externalSubmit?: (values: Record<string, string>) => void;
  hideSaveButton?: boolean;
  enableReinitialize?: boolean;
  submitLabel?: string;
  toolsPipeline?: {
    disabledFields: string[];
  };
  tiptap?: {
    turnOnTiptapInputAsPromptInput: boolean;
    toc: TOC;
  };
}

const clearLabel = (value: string) => {
  const string = value.charAt(0).toUpperCase() + value.slice(1);

  return string.replaceAll('_', ' ');
};

export const ToolForm = forwardRef(
  (
    {
      tool,
      hasOutput = true,
      externalSubmit,
      hideSaveButton,
      initialValue,
      enableReinitialize = false,
      submitLabel = 'Submit',
      toolsPipeline,
      tiptap,
    }: DynamicFormProps,
    ref,
  ) => {
    const [internalInitialValue, setInternalInitialValue] = useState(initialValue || {});
    const [validationSchema, setValidationSchema] = useState({});
    const [formicBaseFields, setFormicBaseFields] = useState<ToolFormikFields[]>([]);
    const [formicAdvancedFields, setFormicAdvancedFields] = useState<ToolFormikFields[]>([]);
    const [isFormInitialized, setIsFormInitialized] = useState(false);
    const [output, setOutput] = useState<null | ToolOutputType>(null);
    const { canSeeHiddenPromptField } = usePermissions();

    useImperativeHandle(ref, () => {});

    const { executeTool } = useExecuteTool();
    const { listenerOn } = useWebSocketMessageListener();

    /**
     * Default tool submit can be override by externalSubmit
     */
    const onSubmit = async (values: Record<string, string>) => {
      const wsChannel = `${tool.name}: ${uuid()}`;

      const fields = await Promise.all(
        await Object.keys(values).map(async (key) => {
          let value = values[key] as unknown;

          if (value instanceof File) {
            value = await fileToBase64(value);
          }

          return {
            key: key,
            value: value as string | number | boolean,
          } as ExecuteToolFieldsPayload;
        }),
      );

      const payload: ExecuteToolPayload = {
        toolName: tool.name,
        fields: fields,
        wsChannel,
      };

      try {
        await executeTool(payload);
      } catch {
        return; // error is handled by WebSocketErrorListener
      }

      if (hasOutput) {
        await new Promise((resolve) => {
          let output = '';

          const { listenerOff } = listenerOn(wsChannel, (data: ToolOutputType) => {
            if (tool?.isStreamable) {
              if (typeof data === 'object' && (data as unknown as Record<string, boolean>).done) {
                listenerOff();
              } else {
                output = (output + data) as string;
                setOutput(output);
              }
            } else {
              setOutput(data);
              listenerOff();
            }

            resolve(true);
          });
        });
      }
    };

    useEffect(() => {
      if (!tool?.config?.input) return;

      const validationSchema = getValidationSchema(tool.config.input, toolsPipeline?.disabledFields ?? []);
      const internalInitialValues: Record<string, string | number | boolean> = {};
      const formicBaseFields: ToolFormikFields[] = [];
      const formicAdvancedFields: ToolFormikFields[] = [];

      for (const key in tool.config.input) {
        if (!canSeeHiddenPromptField && key === 'hiddenPrompt') continue;

        const label = tool?.config?.input[key]?.displayName ?? key;

        const item = {
          name: key,
          label: clearLabel(label),
          type: tool.config.input[key].type,
          options: (tool?.config?.input[key]?.validation?.valueIn as string[]) ?? [],
          disabled: toolsPipeline?.disabledFields?.includes(key) ?? false,
          showIf: tool?.config?.input[key]?.showIf,
        };

        if (tool?.config?.input[key]?.isAdvanced) {
          formicAdvancedFields.push(item);
        } else {
          formicBaseFields.push(item);
        }

        internalInitialValues[key] = tool?.config?.input[key]?.defaultValue ?? '';
      }

      if (!initialValue) {
        setInternalInitialValue(internalInitialValues as Record<string, string>);
      }

      setValidationSchema(validationSchema);
      setFormicBaseFields(formicBaseFields);
      setFormicAdvancedFields(formicAdvancedFields);
      setIsFormInitialized(true);
    }, [initialValue, tool, toolsPipeline?.disabledFields]);

    return (
      <div
        className={clsx('grid  gap-6', {
          'grid-cols-2': hasOutput,
        })}
      >
        {isFormInitialized && (
          <FormikDynamicForm
            initialValue={initialValue || internalInitialValue}
            validationSchema={validationSchema}
            formikBaseFields={formicBaseFields}
            formikAdvancedFields={formicAdvancedFields}
            onSubmit={externalSubmit ?? onSubmit}
            hideSaveButton={hideSaveButton}
            enableReinitialize={enableReinitialize}
            submitLabel={submitLabel}
            tiptap={tiptap}
          />
        )}
        {output && (
          <ToolOutput
            outputType={tool.config.outputType}
            output={output}
            showHistory={true}
          />
        )}
      </div>
    );
  },
);
