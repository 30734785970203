import { Checkbox, Label } from 'flowbite-react';

interface CheckboxWithLabelProps {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  disabled?: boolean;
}

export function CheckboxWithLabel({ name, label, formik, disabled }: CheckboxWithLabelProps) {
  return (
    <>
      <Label className="inline-flex items-center">
        <Checkbox
          id={name}
          name={name}
          onChange={formik.handleChange}
          value={formik.values[name]}
          disabled={disabled}
        />
        <span className="ml-3 text-[0.875rem] leading-[1.1rem] font-medium">{label}</span>
      </Label>
    </>
  );
}
