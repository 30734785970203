import { useApi } from '@/api/api';

export const useGeneratePersonaPosition = (): {
  generatePersonaPosition: (personaId: number, positionId: number, wsChannel: string) => Promise<void>;
} => {
  const { postFetcher } = useApi();

  const generatePersonaPosition = async (personaId: number, positionId: number, wsChannel: string) => {
    return postFetcher(`/persona-canvas/${personaId}/position/${positionId}/generate/${wsChannel}`, {
      arg: {},
    });
  };

  return {
    generatePersonaPosition,
  };
};
