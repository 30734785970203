import './animation.scss';
interface MemoryLogTogglerProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  allTaskIsCompleted: boolean;
  unreadLogsCount: number;
}

export const MemoryLogToggler = ({
  isOpen,
  toggleIsOpen,
  allTaskIsCompleted,
  unreadLogsCount,
}: MemoryLogTogglerProps) => {
  return (
    <div
      className="w-full h-full rounded-full flex justify-center items-center cursor-pointer"
      onClick={toggleIsOpen}
    >
      {!isOpen && (
        <div className="absolute flex justify-center items-center right-[-10px] top-[-8px] h-6 w-6 rounded-full border-[1px] border-solid border-white z-50 text-white bg-[#FA4490] font-medium text-[14px]">
          {unreadLogsCount}
        </div>
      )}

      {allTaskIsCompleted && (
        <img
          src="/circle-check-white.svg"
          alt=""
        />
      )}

      {isOpen && !allTaskIsCompleted && (
        <img
          src="/close-white.svg"
          alt=""
        />
      )}

      {!isOpen && !allTaskIsCompleted && <div className="dot-pulse"></div>}
    </div>
  );
};
