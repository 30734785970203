import useSWR from 'swr';
import { useApi } from '@/api/api.ts';
import { GetPipelineTemplate, ReactFlowUpdateInput, UpdateToolsPipelineStep } from './type.model';

export const useCreateToolPipelineTemplate = () => {
  const { postFetcher } = useApi();

  const createToolPipeline = async (projectId: number): Promise<{ id: number }> => {
    const response = await postFetcher('/tools-pipeline/template', { arg: { projectId } });
    return response;
  };

  return {
    createToolPipeline,
  };
};

export const usePipelineAddStepTemplate = () => {
  const { putFetcher } = useApi();
  const pipelineAddStep = async (toolPipelineId: number, toolId: number): Promise<{ id: number }> => {
    const response = await putFetcher(`/tools-pipeline/template/${toolPipelineId}/add-step`, { arg: { toolId } });
    return response;
  };
  return {
    pipelineAddStep,
  };
};

export const usePipelineRemoveStepTemplate = () => {
  const { deleteFetcher } = useApi();
  const pipelineRemoveStep = async (stepId: number, pipelineId: number): Promise<{ id: number }> => {
    const response = await deleteFetcher(`/tools-pipeline/template/${pipelineId}/step/${stepId}`);
    return response;
  };
  return {
    pipelineRemoveStep,
  };
};

export const usePipelineUpdateStepTemplate = () => {
  const { patchFetcher } = useApi();
  const pipelineUpdateStep = async (stepId: number, data: UpdateToolsPipelineStep): Promise<{ id: number }> => {
    const response = await patchFetcher(`/tools-pipeline/template/step/${stepId}`, { arg: { ...data } });
    return response;
  };
  return {
    pipelineUpdateStep,
  };
};

export const useGetPipelineTemplate = (pipelineId: number) => {
  const { getFetcher } = useApi();
  const {
    data: pipelineTemplate,
    error,
    isLoading,
  } = useSWR<GetPipelineTemplate>(pipelineId ? `/tools-pipeline/template/${pipelineId}` : null, getFetcher);

  return {
    pipelineTemplate,
    error,
    isLoading,
  };
};

export const useUpdateReactFlow = () => {
  const { patchFetcher } = useApi();

  const updateReactFlow = async (reactFlowId: number, input: ReactFlowUpdateInput) => {
    const response = await patchFetcher(`/react-flow/${reactFlowId}`, { arg: { ...input } });
    return response;
  };

  return {
    updateReactFlow,
  };
};

export const useExecutePipeline = () => {
  const { postFetcher } = useApi();
  const executePipeline = async (pipelineId: number) => {
    const response = await postFetcher(`/tools-pipeline/execute/${pipelineId}`, { arg: {} });
    return response;
  };
  return {
    executePipeline,
  };
};
