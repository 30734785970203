import { MentionTypeAccordion } from '@/components/tiptap/extensions/DigitalFirst/editing/mention-injector/MentionTypeAccordion.tsx';
import { PromptMentionType } from '@/components/tiptap/extensions/DigitalFirst/editing/PromptEditor.tsx';
import { useState } from 'react';
import { SectionMentionAccordionBody } from '@/components/tiptap/extensions/DigitalFirst/editing/mention-injector/accordion-body/SectionMentionAccordionBody.tsx';
import { CanvasMentionAccordionBody } from '@/components/tiptap/extensions/DigitalFirst/editing/mention-injector/accordion-body/CanvasMentionAccordionBody.tsx';
import { DataRoomMentionAccordionBody } from '@/components/tiptap/extensions/DigitalFirst/editing/mention-injector/accordion-body/DataRoomMentionAccordionBody.tsx';

export function MentionInjector() {
  const [expandedAccordion, setExpandedAccordion] = useState<PromptMentionType | null>(null);

  const toggleAccordion = (type: PromptMentionType) => {
    if (expandedAccordion === type) {
      setExpandedAccordion(null);
    } else {
      setExpandedAccordion(type);
    }
  };

  return (
    <>
      {/* SECTIONS */}
      <MentionTypeAccordion
        label="Sections"
        isExpanded={expandedAccordion === PromptMentionType.SECTION}
        mentionType={PromptMentionType.SECTION}
        toggleAccordion={toggleAccordion}
        icon="/fact-check-black.svg"
      >
        <SectionMentionAccordionBody />
      </MentionTypeAccordion>

      {/* CANVAS */}
      <MentionTypeAccordion
        label="Canvases"
        isExpanded={expandedAccordion === PromptMentionType.CANVAS}
        mentionType={PromptMentionType.CANVAS}
        toggleAccordion={toggleAccordion}
        icon="/canvases-black.svg"
      >
        <CanvasMentionAccordionBody />
      </MentionTypeAccordion>

      {/* DATA ROOM */}
      <MentionTypeAccordion
        label="Data"
        isExpanded={expandedAccordion === PromptMentionType.DATA_ROOM}
        isLastAccordion={true}
        mentionType={PromptMentionType.DATA_ROOM}
        toggleAccordion={toggleAccordion}
        icon="/data-black.svg"
      >
        <DataRoomMentionAccordionBody />
      </MentionTypeAccordion>
    </>
  );
}
