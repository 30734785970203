import { DfAvatar } from '@/components/DfAvatar.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { isOrganizationAdmin } from '@/helpers/is-organization-admin.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { LeftSideNavMenuItem } from '@/layouts/MainLayout/components/LeftSideNavMenuItem.tsx';

export function MenuFooter() {
  const navigate = useNavigate();
  const { logout, user } = useAuth0();

  return (
    <div className="flex flex-col gap-1 mt-3">
      <LeftSideNavMenuItem
        label={`${user?.given_name} ${user?.family_name}`}
        customLeftIcon={
          <div className="ml-1">
            {user?.picture ? (
              <img
                src={user.picture}
                className="size-8 h-auto"
                alt=""
              />
            ) : (
              <DfAvatar
                firstName={user?.given_name || 'U'}
                lastName={user?.family_name || 'U'}
              />
            )}
          </div>
        }
        addons={
          <div className="flex gap-1">
            <IconButton
              icon="/settings-black.svg"
              size={24}
              onClick={() => {
                if (isOrganizationAdmin(user)) {
                  navigate(buildUrl([Urls.ORGANIZATION_SETTINGS]));
                } else {
                  navigate(buildUrl([Urls.USER_SETTINGS]));
                }
              }}
            />
            <IconButton
              icon="/logout-black.svg"
              size={24}
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            />
          </div>
        }
      />

      <LeftSideNavMenuItem
        label="Notifications"
        leftIcon="/bell-black.svg"
        addons={
          <div className="flex items-center justify-center size-5 rounded-full bg-red-default text-white text-caption-sm">
            1
          </div>
        }
        disabled={true}
      />

      <LeftSideNavMenuItem
        label="Help Center"
        leftIcon="/help-black.svg"
        disabled={true}
      />
    </div>
  );
}
