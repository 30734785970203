import { Card } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { CanvasDetails } from '@/api/canvases/api.ts';
import { DropdownCanvasOptions } from './DropdownCanvasOptions.tsx';
import { dateToRelativeString } from '@/helpers/date-to-relative-string.ts';

export function MyCanvasCard({ canvasDetails }: { canvasDetails: CanvasDetails }) {
  return (
    <>
      <div className="relative">
        <div className="absolute top-4 right-4">
          <DropdownCanvasOptions instanceId={canvasDetails.id} />
        </div>
        <Link to={buildUrl([Urls.CANVASES, Urls.CANVAS], { canvasId: canvasDetails.id })}>
          <Card className="hover:bg-gray-bg active:bg-gray-ultra-light hover:text-text-primary">
            <div className="flex justify-between items-center w-full mb-4">
              <div className="rounded-lg p-2.5 bg-tint-purple">
                <img
                  src="/business-black.svg"
                  alt=""
                />
              </div>
            </div>
            <h3 className="text-h3-desktop mb-1">{canvasDetails.name}</h3>
            <div className="text-text-secondary mb-4">Edited {dateToRelativeString(canvasDetails.modifiedAt)}</div>
          </Card>
        </Link>
      </div>
    </>
  );
}
