import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { IconButton } from '@/components/IconButton.tsx';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { DfTooltip } from '@/components/DfTooltip/DfTooltip.tsx';

export interface MenuItemProps {
  level?: number;
  label: string;
  href?: string;
  renderCollapsed?: ReactNode;
  addons?: ReactNode;
  leftIcon?: string;
  customLeftIcon?: ReactNode;
  customActiveCondition?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  disabledTooltip?: string;
}

export function MenuItem({
  level = 0,
  label,
  href = '#',
  renderCollapsed,
  addons,
  leftIcon,
  customLeftIcon,
  customActiveCondition,
  onClick = () => {},
  disabled,
  disabledTooltip,
}: MenuItemProps) {
  const [expanded, setExpanded] = useState(false);
  const [isOverflowEllipsis, setIsOverflowEllipsis] = useState(false);
  const tooltipId = useMemo(() => uuid(), []);
  const disabledTooltipId = useMemo(() => uuid(), []);
  const textRef = useRef<HTMLSpanElement>(null);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (textRef.current) {
        setIsOverflowEllipsis(textRef.current.offsetWidth < textRef.current.scrollWidth);
      }
    }, 300);
  }, []);

  return (
    <div>
      <div
        className={clsx({ 'cursor-not-allowed': disabled })}
        data-tooltip-id={disabledTooltipId}
      >
        <Link
          className={clsx(
            'has-[button:hover]:bg-transparent bg-interactive-gray flex items-center gap-2 rounded-lg max-h-10 group/item',
            { '!bg-gray-medium-light': customActiveCondition ?? location.pathname === href },
            { 'p-2.5': !customLeftIcon },
            { 'opacity-65 pointer-events-none': disabled },
          )}
          to={href}
          onClick={onClick}
        >
          {customLeftIcon ? (
            <div
              className="shrink-0"
              style={{ marginLeft: `${level * 1.75}rem` }}
            >
              {customLeftIcon}
            </div>
          ) : (
            <>
              {leftIcon && (
                <div
                  className="size-5 shrink-0"
                  style={{ marginLeft: `${level * 1.75}rem` }}
                >
                  <img
                    src={leftIcon}
                    alt=""
                  />
                </div>
              )}
            </>
          )}

          <div className="flex items-center grow gap-1 text-subtitle-sm min-w-0 group-[.is-collapsed]:hidden">
            <span
              className={clsx('grow overflow-hidden text-ellipsis text-nowrap min-w-0', {
                'text-caption-sm': level > 1,
              })}
              ref={textRef}
              data-tooltip-id={tooltipId}
            >
              {label}
            </span>

            <div
              onClick={(e) => {
                // Prevent parent anchor onClick from firing when clicking on addons
                e.preventDefault();
                e.stopPropagation();
              }}
              className="shrink-0"
            >
              {addons}
            </div>

            {renderCollapsed && (
              <div className="shrink-0">
                <IconButton
                  icon="/chevron-down-black.svg"
                  size={24}
                  styleOnSelect="bg-gray-medium-light rotate-180 transition"
                  onClick={(e) => {
                    // Prevent parent anchor onClick from firing when clicking on chevron
                    e.preventDefault();
                    e.stopPropagation();
                    setExpanded(!expanded);
                  }}
                />
              </div>
            )}
          </div>
        </Link>
      </div>

      {disabled && disabledTooltip ? (
        <DfTooltip
          id={disabledTooltipId}
          content={disabledTooltip}
          place="right"
          delay={800}
        />
      ) : (
        isOverflowEllipsis && (
          <DfTooltip
            id={tooltipId}
            content={label}
            place="right"
          />
        )
      )}

      {expanded && <div className="group-[.is-collapsed]:hidden">{renderCollapsed}</div>}
    </div>
  );
}
