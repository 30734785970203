import { WebSearcherNewSearchForm } from './WebSearcherNewSearchForm.tsx';
import { WebSearcherRoomChat } from './WebSearcherRoomChat.tsx';
import { WebSearcherPanelMenu } from './WebSearcherPanelMenu.tsx';
import { useEffect, useRef } from 'react';
import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';
import { useParams } from 'react-router-dom';

export function WebSearcherPanel({ webSearcherRooms }: { webSearcherRooms: WebSearcherRoom[] }) {
  const { roomId } = useParams();
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const currentRoom = webSearcherRooms.find((room) => room.id === Number(roomId));

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo(0, 0);
    }
  }, [roomId]);

  return (
    <>
      <div className="w-full grid grid-cols-[auto,minmax(0,1fr)] h-full rounded-2xl bg-white">
        <WebSearcherPanelMenu webSearcherRooms={webSearcherRooms} />

        <div
          className="overflow-auto relative"
          ref={scrollContainer}
        >
          {currentRoom ? (
            <WebSearcherRoomChat room={currentRoom} />
          ) : (
            <div className="p-8 w-full h-full">
              <WebSearcherNewSearchForm />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
