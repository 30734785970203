import { useApi } from '../api.ts';
import { mutate } from 'swr';

export const useRemoveOrganizationInvitation = (): {
  removeOrganizationInvitation: (id: string) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();

  const removeOrganizationInvitation = async (id: string): Promise<void> => {
    await deleteFetcher(`/organization/invitation/${id}`);
    await mutate('/organization/invitations');
  };

  return {
    removeOrganizationInvitation,
  };
};
