import { useWebSocketMessageListener } from '@/useOnWebSocketMessage';
import { useState } from 'react';
import { useCreateTool, useExecuteTool } from 'src/libs/tools/api';
import { ToolName } from 'src/libs/tools/type.model';
import { v4 as uuid } from 'uuid';

export const PreCanvas = () => {
  const wsChannel = `pre-canvas:${uuid()}`;
  const { createTool } = useCreateTool();
  const { executeTool } = useExecuteTool();
  const { listenerOn } = useWebSocketMessageListener();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [output, setOutput] = useState<any>('');

  const generate = async () => {
    const { id } = await createTool(ToolName.RESEARCHER_PRE_CANVAS, 1);
    const payload = {
      id,
      toolName: ToolName.RESEARCHER_PRE_CANVAS,
      wsChannel,
      fields: [
        {
          key: 'researchType',
          value: 'preCanvas',
        },
      ],
    };

    executeTool(payload);

    const { listenerOff } = listenerOn(wsChannel, async (data) => {
      setOutput(data as string);
      listenerOff();
    });
  };

  return (
    <div className="mt-2">
      <button onClick={generate}>Generate</button>

      <pre>{output ? JSON.stringify(output, undefined, 2) : null}</pre>
    </div>
  );
};
