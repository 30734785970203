import { useApi } from '@/api/api.ts';
import useSWR from 'swr';
import { Flow } from '@/models/flow.interface.ts';

export const useFlowTemplate = (
  flowId: number,
): {
  template: Flow;
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR(`/flow/${flowId}`, getFetcher);

  return {
    template: data,
    isLoading,
    error,
  };
};
