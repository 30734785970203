import { useApi } from '@/api/api.ts';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';
import { mutate } from 'swr';

export const useCreateWebSearcherRoom = (): {
  createWebSearcherRoom: (name: string) => Promise<WebSearcherRoom>;
} => {
  const { postFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const createWebSearcherRoom = async (name: string) => {
    const room = await postFetcher('/websearcher/room', { arg: { name, workspaceId } });
    await mutate(`/websearcher/rooms/by-workspace/${workspaceId}`);
    return room;
  };

  return {
    createWebSearcherRoom,
  };
};
