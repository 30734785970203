import { IconButton } from '@/components/IconButton.tsx';
import { useLeftSideNav } from '../store.ts';

export function ExpandLeftNavButton() {
  const { isNavCollapsed, expandNav } = useLeftSideNav();

  return (
    <>
      {isNavCollapsed && (
        <IconButton
          icon="/sidebar-black.svg"
          onClick={() => expandNav()}
        />
      )}
    </>
  );
}
