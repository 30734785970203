import { useApi } from '@/api/api.ts';

export const useUpdateSegment = (): {
  updateSegment: (segmentId: number, name: string) => Promise<void>;
} => {
  const { patchFetcher } = useApi();

  const updateSegment = async (segmentId: number, name: string) => {
    return patchFetcher(`/persona-canvas/segment/${segmentId}`, {
      arg: {
        name,
      },
    });
  };

  return {
    updateSegment,
  };
};
