export default async function fileToBase64(file: File): Promise<string> {
  return new Promise(function (resolve) {
    const reader = new FileReader();
    const readFile = () => {
      const buffer = reader.result as ArrayBuffer;
      const b64 = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));

      resolve(b64);
    };

    reader.addEventListener('load', readFile);
    reader.readAsArrayBuffer(file);
  });
}
