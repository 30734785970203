import { useApi } from '../../api.ts';
import { mutate } from 'swr';

export const useUnpinMyFlowFromProject = (): {
  unpinMyFlowFromProject: (flowId: number, projectId: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();

  const unpinMyFlowFromProject = async (myFlowId: number, projectId: number) => {
    await deleteFetcher(`/flow/unpin-from-project/${myFlowId}`, { arg: {} });
    await mutate(`/flow/my-flows/${projectId}`);
  };

  return { unpinMyFlowFromProject };
};
