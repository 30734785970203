import { useApi } from '../api.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import useSWRImmutable from 'swr/immutable';

export const useResourceSummary = (
  fileId: string,
): {
  resourceSummary: string;
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const { data, error, isLoading } = useSWRImmutable(
    `/inputs/dataRoom/${dataRoomId}/resource-summary/${fileId}`,
    getFetcher,
  );

  return {
    resourceSummary: data,
    isLoading,
    error,
  };
};
