import { InputType, AddResourcePayload } from '@/models/add-resource-payload.interface.ts';
import fileToBase64 from './file-to-base64.ts';
import { DropboxFile } from '../../routes/data/components/modal-body-templates/DataRoomAddFromDropboxModalBody.tsx';

export const inputsPayloadMapper = {
  [InputType.File]: (files: File[]) => mapFiles(files),
  [InputType.Url]: (values: string[]) => mapUrls(values, InputType.Url),
  [InputType.Youtube]: (values: string[]) => mapUrls(values, InputType.Youtube),
  [InputType.Dropbox]: (values: DropboxFile[]) => mapDropbox(values),
  [InputType.Google]: (values: Record<string, string>[]) => mapGoogle(values),
};

async function mapFiles(files: File[]): Promise<AddResourcePayload[]> {
  const payloadValues: AddResourcePayload[] = [];

  for (const file of files) {
    const content = await fileToBase64(file);
    payloadValues.push({
      type: InputType.File,
      file: content,
      fileExtension: file.type,
      fileName: file.name,
    });
  }

  return payloadValues;
}

function mapUrls(urls: string[], type: InputType): AddResourcePayload[] {
  const payloadValues: AddResourcePayload[] = [];

  urls.forEach((url: string) => {
    let stringValue = url;
    stringValue = stringValue.replace(/^www\./, '');
    stringValue = stringValue.replace(/^https:\/\/www\./, 'https://');
    stringValue = stringValue.replace(/^http:\/\/www\./, 'http://');
    stringValue =
      stringValue.startsWith('https://') || stringValue.startsWith('http://') ? stringValue : 'https://' + stringValue;
    payloadValues.push({ type: type, url: stringValue });
  });

  return payloadValues;
}

function mapDropbox(files: DropboxFile[]): AddResourcePayload[] {
  return files.map((file: DropboxFile) => ({
    type: InputType.Dropbox,
    value: file.url,
    fileName: file.name,
  }));
}

function mapGoogle(values: Record<string, string>[]): AddResourcePayload[] {
  const payloadValues: AddResourcePayload[] = [];

  values.forEach((value: Record<string, string>) => {
    if (value?.id)
      payloadValues.push({
        type: InputType.Google,
        googleFileId: value.id,
        googleAuthToken: value.token,
        fileName: 'google', // TODO: add file name
      });
  });

  return payloadValues;
}
