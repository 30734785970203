import { useApi } from '../api';
import { mutate } from 'swr';

export const useDeleteWorkspace = (): {
  deleteWorkspace: (workspaceId: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();

  const deleteWorkspace = async (workspaceId: number) => {
    await deleteFetcher(`/workspace/${workspaceId}`);
    await mutate('/workspace/by-user');
  };

  return { deleteWorkspace };
};
