import { ModalContent } from '@/models/modal-content.interface.ts';
import { DataRoomAddWebsiteLinksModalBody } from '../../../routes/data/components/modal-body-templates/DataRoomAddWebsiteLinksModalBody.tsx';
import { DataRoomAddRecordingsModalBody } from '../../../routes/data/components/modal-body-templates/DataRoomAddRecordingsModalBody.tsx';
import { DataRoomAddFilesModalBody } from '../../../routes/data/components/modal-body-templates/DataRoomAddFilesModalBody.tsx';
import { DataRoomAddYoutubeLinksModalBody } from '../../../routes/data/components/modal-body-templates/DataRoomAddYoutubeLinksModalBody.tsx';
import { DataRoomAddFromGoogleDriveModalBody } from '../../../routes/data/components/modal-body-templates/DataRoomAddFromGoogleDriveModalBody.tsx';
import { DataRoomAddFromDropboxModalBody } from '../../../routes/data/components/modal-body-templates/DataRoomAddFromDropboxModalBody.tsx';
import { CreateWorkspaceModalBody } from '../../layouts/MainLayout/components/CreateWorkspaceModalBody.tsx';
import { ProjectRenameModalBody } from '../../../routes/projects/components/ProjectRenameModalBody.tsx';
import { ProjectCreateModalBody } from '../../../routes/projects/components/ProjectCreateModalBody.tsx';
import { MyFlowRenameModalBody } from '../../../routes/projects/routes/project-details/components/MyFlowRenameModalBody.tsx';
import { CreateMyWorkFlowModalBody } from '../../../routes/projects/routes/project-details/components/CreateMyWorkFlowModalBody.tsx';
import { MoveMyFlowToProjectModalBody } from '../../../routes/projects/routes/project-details/components/MoveMyFlowToProjectModalBody.tsx';
import { FlowTemplateModalBody } from 'src/routes/projects/routes/flows-library/components/FlowTemplateModalBody.tsx';
import { CreateTemplateFlowModalBody } from 'src/routes/projects/routes/flows-library/components/CreateTemplateFlowModalBody.tsx';
import { ShareFlowModalBody } from '@/components/tiptap/components/BlockEditor/components/ShareFlowModalBody.tsx';
import { InviteToOrganizationModalBody } from '../../../routes/settings/components/InviteToOrganizationModalBody.tsx';
import { EditTeamMemberModalBody } from '../../../routes/settings/components/EditTeamMemberModalBody.tsx';
import { AddToWorkspaceModalBody } from '../../../routes/workspace/components/AddToWorkspaceModalBody.tsx';
import { SegmentModalBody } from '../../../routes/segments/components/SegmentModalBody.tsx';

export const MODALS: DfModals = {
  DataRoomAddWebsiteLinks: {
    header: 'Add website links',
    subheader: 'Let us learn your business from website',
    body: <DataRoomAddWebsiteLinksModalBody />,
  },
  DataRoomAddRecordings: {
    header: 'Add recordings',
    subheader: 'Upload MPEG, MP4, MOV, AVI',
    body: <DataRoomAddRecordingsModalBody />,
  },
  DataRoomAddFiles: {
    header: 'Add files',
    subheader: 'Upload TXT, DOCX, PPTX, CSV, PDF',
    body: <DataRoomAddFilesModalBody />,
  },
  DataRoomAddYoutubeLinks: {
    header: 'Upload from Youtube',
    subheader: 'Include a link to Youtube video or channel',
    body: <DataRoomAddYoutubeLinksModalBody />,
  },
  DataRoomAddFromGoogleDrive: {
    header: 'Upload from Google Drive',
    subheader: 'Upload any type of file from your Google Drive account',
    body: <DataRoomAddFromGoogleDriveModalBody />,
  },
  DataRoomAddFromDropbox: {
    header: 'Upload from Dropbox',
    subheader: 'Upload any type of file from your Dropbox account',
    body: <DataRoomAddFromDropboxModalBody />,
  },
  CreateWorkspace: {
    header: 'Create Workspace',
    subheader: 'Create a new workspace',
    body: <CreateWorkspaceModalBody />,
  },
  EditProjectName: {
    header: 'Edit project name',
    body: <ProjectRenameModalBody />,
  },
  EditFlowTemplate: {
    header: 'Edit flow template',
    body: <FlowTemplateModalBody />,
  },
  CreateProject: {
    header: 'Create project',
    body: <ProjectCreateModalBody />,
  },
  EditMyFlowName: {
    header: 'Edit flow name',
    body: <MyFlowRenameModalBody />,
  },
  CreateMyFlow: {
    header: 'Create flow',
    body: <CreateMyWorkFlowModalBody />,
  },
  CreateTemplateFlow: {
    header: 'Create template flow',
    body: <CreateTemplateFlowModalBody />,
  },
  MoveMyFlowToProject: {
    header: 'Move to project',
    body: <MoveMyFlowToProjectModalBody />,
  },
  ShareFlow: {
    header: 'Share flow',
    body: <ShareFlowModalBody />,
  },
  InviteToOrganization: {
    header: 'Invite to organization',
    body: <InviteToOrganizationModalBody />,
  },
  EditTeamMember: {
    header: 'Edit team member',
    body: <EditTeamMemberModalBody />,
  },
  AddToWorkspace: {
    header: 'Add to workspace',
    body: <AddToWorkspaceModalBody />,
  },
  CreateSegment: {
    header: 'Create segment',
    body: <SegmentModalBody />,
  },
  EditSegment: {
    header: 'Edit segment',
    body: <SegmentModalBody />,
  },
};

export interface DfModals {
  DataRoomAddWebsiteLinks: ModalContent;
  DataRoomAddRecordings: ModalContent;
  DataRoomAddFiles: ModalContent;
  DataRoomAddYoutubeLinks: ModalContent;
  DataRoomAddFromGoogleDrive: ModalContent;
  DataRoomAddFromDropbox: ModalContent;
  CreateWorkspace: ModalContent;
  CreateProject: ModalContent;
  EditFlowTemplate: ModalContent;
  EditProjectName: ModalContent;
  EditMyFlowName: ModalContent;
  CreateMyFlow: ModalContent;
  CreateTemplateFlow: ModalContent;
  MoveMyFlowToProject: ModalContent;
  ShareFlow: ModalContent;
  InviteToOrganization: ModalContent;
  EditTeamMember: ModalContent;
  AddToWorkspace: ModalContent;
  CreateSegment: ModalContent;
  EditSegment: ModalContent;
}
