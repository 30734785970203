import { useApi } from '../api';

export const useDeleteSegment = (): {
  deleteSegment: (segmentId: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();

  const deleteSegment = async (segmentId: number) => {
    return deleteFetcher(`/persona-canvas/segment/${segmentId}`);
  };

  return {
    deleteSegment,
  };
};
