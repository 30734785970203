import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useApi } from '../api.ts';
import { mutate } from 'swr';

export const useDeleteProject = (): {
  deleteProject: (id: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const deleteProject = async (id: number): Promise<void> => {
    await deleteFetcher(`/project/${id}`);
    await mutate(`/project/by-workspace/${workspaceId}`);
  };

  return {
    deleteProject,
  };
};
