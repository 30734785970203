import { mergeAttributes } from '@tiptap/core';
import Mention from '@tiptap/extension-mention';
import { PluginKey } from '@tiptap/pm/state';
import { mentionRender } from './mentionRender';
import { MutableRefObject } from 'react';
import { Instance, Props } from 'tippy.js';
import { TOC } from '../PromptEditor.tsx';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';

export const SectionMention = (toc: TOC, tippy: MutableRefObject<Instance<Props>[]>) =>
  Mention.extend({ name: MentionReferenceType.SECTION }).configure({
    HTMLAttributes: {
      class: MentionReferenceType.SECTION,
    },
    renderText({ options, node }) {
      return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
    },
    renderHTML({ options, node }) {
      return [
        'span',
        mergeAttributes(
          {
            id: node.attrs.id,
            class: 'inline-block px-2 bg-blue-100 rounded text-blue-800 text-body-md',
          },
          options.HTMLAttributes,
        ),
        `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
      ];
    },
    deleteTriggerWithBackspace: true,
    suggestion: {
      char: '#',
      pluginKey: new PluginKey(MentionReferenceType.SECTION),
      items: async ({ query }: { query: string }) => {
        const commands = toc.content.map((header) => ({
          id: header.id,
          name: header.dom.textContent,
          label: header.dom.textContent,
        }));

        return [
          {
            name: 'List',
            title: 'Add to prompt',
            commands: commands.filter(({ name }: { name: string }) =>
              name.toLowerCase().startsWith(query.toLowerCase()),
            ),
          },
        ];
      },
      render: mentionRender(tippy),
    },
  });
