import { mutate } from 'swr';
import { useApi } from '../api.ts';

export const useDuplicateTemplateFlow = () => {
  const { postFetcher } = useApi();

  const duplicateTemplateFlow = async (id: number): Promise<void> => {
    await postFetcher(`/flow/duplicate/${id}`, { arg: {} });
    mutate('/flow?search=&category=');
  };

  return {
    duplicateTemplateFlow,
  };
};
