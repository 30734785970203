import { Modal } from 'flowbite-react';
import { ToolForm } from 'src/libs/tools/ToolForm';
import { Tool } from 'src/libs/tools/type.model';

interface ConfigurationModalProps {
  data: {
    label: string;
    tool: Tool;
    deleteNode: (id: string) => void;
    pipelineId: string;
    formConfig?: Record<string, string>;
  };
  onChange: (values: Record<string, string>) => void;
  disabledFields: string[];
  showConfigurationModal: boolean;
  setShowConfigurationModal: (show: boolean) => void;
}

export const ConfigurationModal = ({
  data,
  onChange,
  disabledFields,
  showConfigurationModal,
  setShowConfigurationModal,
}: ConfigurationModalProps) => {
  return (
    <Modal
      show={showConfigurationModal}
      onClose={() => setShowConfigurationModal(false)}
    >
      <Modal.Header>Configurate Tool</Modal.Header>
      <Modal.Body>
        <ToolForm
          initialValue={data.formConfig}
          tool={data.tool}
          hasOutput={false}
          toolsPipeline={{
            disabledFields,
          }}
          submitLabel="Save"
          externalSubmit={(values) => {
            setShowConfigurationModal(false);
            onChange(values);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
