import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Command, MenuListProps } from './types';
import { Surface } from '@/components/tiptap/components/ui/Surface';
import { DropdownButton } from '@/components/tiptap/components/ui/Dropdown';
import { Icon } from '@/components/tiptap/components/ui/Icon';

export const MenuList = React.forwardRef((props: MenuListProps, ref) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const activeItem = useRef<HTMLButtonElement>(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);

  // Anytime the groups change, i.e. the user types to narrow it down, we want to
  // reset the current selection to the first menu item
  useEffect(() => {
    setSelectedGroupIndex(0);
    setSelectedCommandIndex(0);
  }, [props.items]);

  const selectItem = useCallback(
    (groupIndex: number, commandIndex: number, childIndex: number | null = null) => {
      const command = props.items[groupIndex].commands[commandIndex];
      let label = command.label;
      let childId = null;

      if (Number.isInteger(childIndex)) {
        label = `${label}/${command?.children?.commands[childIndex as number].label}`;
        childId = command?.children?.commands[childIndex as number].id;
      }

      props.command({ ...command, childId, label } as Command);
    },
    [props],
  );

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === 'ArrowDown') {
        if (!props.items.length) {
          return false;
        }

        const commands = props.items[selectedGroupIndex].commands;

        let newCommandIndex = selectedCommandIndex + 1;
        let newGroupIndex = selectedGroupIndex;

        if (commands.length - 1 < newCommandIndex) {
          newCommandIndex = 0;
          newGroupIndex = selectedGroupIndex + 1;
        }

        if (props.items.length - 1 < newGroupIndex) {
          newGroupIndex = 0;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === 'ArrowUp') {
        if (!props.items.length) {
          return false;
        }

        let newCommandIndex = selectedCommandIndex - 1;
        let newGroupIndex = selectedGroupIndex;

        if (newCommandIndex < 0) {
          newGroupIndex = selectedGroupIndex - 1;
          newCommandIndex = props.items[newGroupIndex]?.commands.length - 1 || 0;
        }

        if (newGroupIndex < 0) {
          newGroupIndex = props.items.length - 1;
          newCommandIndex = props.items[newGroupIndex].commands.length - 1;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === 'Enter') {
        if (!props.items.length || selectedGroupIndex === -1 || selectedCommandIndex === -1) {
          return false;
        }

        selectItem(selectedGroupIndex, selectedCommandIndex);

        return true;
      }

      return false;
    },
  }));

  useEffect(() => {
    if (activeItem.current && scrollContainer.current) {
      const offsetTop = activeItem.current.offsetTop;
      const offsetHeight = activeItem.current.offsetHeight;

      scrollContainer.current.scrollTop = offsetTop - offsetHeight;
    }
  }, [selectedCommandIndex, selectedGroupIndex]);

  const createCommandClickHandler = useCallback(
    (groupIndex: number, commandIndex: number, childIndex: number | null = null) => {
      return () => {
        selectItem(groupIndex, commandIndex, childIndex);
      };
    },
    [selectItem],
  );

  if (!props.items.length) {
    return null;
  }

  return (
    <Surface
      ref={scrollContainer}
      className="max-h-[min(80vh,24rem)] overflow-y-auto overflow-x-hidden flex-wrap py-3 px-1 rounded-lg shadow"
    >
      <div className="flex flex-col">
        {props.items.map((group, groupIndex: number) => (
          <React.Fragment key={`${groupIndex}-wrapper`}>
            <div
              className="text-text-secondary text-caption-sm mb-2 ml-2"
              key={`${groupIndex}`}
            >
              {group.title}
            </div>
            {group.commands.map((command: Command, commandIndex: number) => (
              <>
                <DropdownButton
                  key={`${command.label}`}
                  isActive={selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex}
                  onClick={createCommandClickHandler(groupIndex, commandIndex)}
                >
                  <Icon
                    name={command.iconName}
                    className="mr-1"
                  />
                  {command.label}
                </DropdownButton>

                {command?.children?.commands?.map((child: Command, childIndex: number) => (
                  <DropdownButton
                    key={`${child.label}`}
                    isActive={selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex}
                    onClick={createCommandClickHandler(groupIndex, commandIndex, childIndex)}
                    className="pl-4"
                  >
                    <Icon
                      name={child.iconName}
                      className="mr-1"
                    />
                    {child.label}
                  </DropdownButton>
                ))}
              </>
            ))}
          </React.Fragment>
        ))}
      </div>
    </Surface>
  );
});

MenuList.displayName = 'MenuList';

export default MenuList;
