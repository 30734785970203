import { create } from 'zustand';
import { DialogContent } from '@/models/dialog-content.interface.ts';

type GlobalDialogStore = {
  open: boolean;
  config: {
    content: DialogContent;
    onConfirm: () => void;
  };
};

const useGlobalModalStore = create<GlobalDialogStore>(() => ({
  open: false,
  config: {
    content: {} as DialogContent,
    onConfirm: () => {},
  },
}));

const openDialog = (content: DialogContent, onConfirm: () => void) => {
  useGlobalModalStore.setState(() => ({
    open: true,
    config: {
      content,
      onConfirm,
    },
  }));
};

const closeDialog = () => {
  useGlobalModalStore.setState(() => ({
    open: false,
  }));
};

export const useGlobalDialogInternalState = () => ({
  open: useGlobalModalStore((state) => state.open),
  config: useGlobalModalStore((state) => state.config),
  closeDialog,
});

export const useGlobalDialog = () => ({
  openDialog,
  closeDialog,
});
