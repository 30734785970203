import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useApi } from '../api.ts';
import { Project } from '@/models/project.interface.ts';

export const useCreateProject = (): {
  createProject: (name: string) => Promise<Project>;
} => {
  const { postFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const createProject = async (name: string): Promise<Project> => {
    return await postFetcher('/project', { arg: { name, workspaceId } });
  };

  return {
    createProject,
  };
};
