import { AuthGuard } from '@/guards/AuthGuard.tsx';
import { ExistingWorkspaceGuard } from '@/guards/ExistingWorkspaceGuard.tsx';
import { MainLayout } from '@/layouts/MainLayout/MainLayout.tsx';
import { Outlet } from 'react-router-dom';
import { WebSocketErrorListener } from '@/components/WebSocketErrorListener.tsx';

export function AuthorizedApp() {
  return (
    <>
      <AuthGuard>
        <WebSocketErrorListener>
          <ExistingWorkspaceGuard>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </ExistingWorkspaceGuard>
        </WebSocketErrorListener>
      </AuthGuard>
    </>
  );
}
