import { findElementId } from './findElementId';
import { replacePromptVars } from './replacePromptVars';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';

export const getDataRoomItemSummary = async (
  prompt: string,
  tiptapResourceSummary: (fileId: string) => Promise<string>,
) => {
  let replacementPrompt = prompt;
  const inputsIds = findElementId(prompt, `[data-type="${MentionReferenceType.DATA_ROOM}"]`);

  for (const inputId of inputsIds) {
    const selector = `[data-id="${inputId}"]`;
    const text = await tiptapResourceSummary(inputId as string);

    replacementPrompt = replacePromptVars(replacementPrompt, text, selector);
  }

  return replacementPrompt;
};
