import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Avatar, Card } from 'flowbite-react';
import { dateToRelativeString } from '@/helpers/date-to-relative-string.ts';
import { setCurrentWorkspace, useCurrentWorkspace } from '@/state/app-store.ts';
import { Workspace } from '@/models/workspace.interface.ts';
import { cn } from '@/components/tiptap/lib/utils';

export const WorkspaceCard = ({ workspace }: { workspace: Workspace }) => {
  const currentWorkspace = useCurrentWorkspace();
  const navigate = useNavigate();

  const handleSelectWorkspace = () => {
    setCurrentWorkspace(workspace);
    navigate(buildUrl([Urls.DASHBOARD]));
  };

  return (
    <>
      <button onClick={handleSelectWorkspace}>
        <Card className={cn({ 'outline outline-primary-default ': currentWorkspace?.id === workspace.id })}>
          <div className="rounded-lg mb-4">
            {!workspace.avatarUrl && (
              <div className="flex items-center justify-center rounded-lg size-10 text-h3-desktop bg-pink-300">
                {workspace?.name.charAt(0).toUpperCase()}
              </div>
            )}
            {workspace.avatarUrl && <Avatar img={import.meta.env.VITE_ASSETS + workspace.avatarUrl} />}
          </div>
          <div className="text-button-lg mb-1">{workspace.name}</div>
          <div className="text-body-sm text-neutrals-700">Viewed {dateToRelativeString(workspace.modifiedAt)}</div>
        </Card>
      </button>
    </>
  );
};
