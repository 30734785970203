import { FormikProvider, useFormik } from 'formik';
import { TextInput } from 'flowbite-react';
import { getDfFieldProps } from '@/helpers/get-df-field-props.ts';
import { HiSearch } from 'react-icons/hi';
import { object, string } from 'yup';
import { ToolCard } from '../projects/components/ToolCard.tsx';
import { MenuItem } from '@/components/MenuItem.tsx';
import { useGetAllTools } from '../../libs/tools/api.ts';
import { ToolCategory } from '../../libs/tools/type.model.ts';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { useParams } from 'react-router-dom';
import { useProject } from '@/api/projects/get-project.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';

export function ToolsPanel() {
  const { projectId } = useParams<{ projectId: string }>();
  const { project } = useProject(projectId ? +projectId : undefined);
  const { tools, isLoading } = useGetAllTools();

  const formik = useFormik({
    initialValues: {
      search: '',
      category: '',
    },
    validationSchema: object({
      search: string(),
      category: string(),
    }),
    onSubmit: () => {},
  });

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: projectId
          ? [
              { label: 'Projects', href: buildUrl([Urls.PROJECTS]) },
              { label: project?.name ?? '', href: buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: projectId }) },
              { label: 'Tools', href: '#' },
            ]
          : [{ label: 'Tools', href: '#' }],
        icon: projectId ? '/folder-black.svg' : '/dashboard-black.svg',
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Tools</title>
      </Helmet>

      <div className="grid grid-cols-[300px,1fr] items-start gap-8">
        <FormikProvider value={formik}>
          <aside className="flex flex-col gap-1">
            <div className="min-w-64 flex flex-col gap-3">
              <div className="relative mb-3">
                <TextInput
                  {...getDfFieldProps('search', formik)}
                  placeholder="Search"
                  icon={HiSearch}
                />
              </div>

              <ul className="flex flex-col gap-1">
                <li>
                  <MenuItem
                    label="All"
                    leftIcon="/dashboard-black.svg"
                    onClick={() => formik.setFieldValue('category', '')}
                    customActiveCondition={!formik.values.category}
                  />
                </li>
                <li>
                  <MenuItem
                    label="Text"
                    leftIcon="/description-black.svg"
                    onClick={() => formik.setFieldValue('category', ToolCategory.TEXTGEN)}
                    customActiveCondition={formik.values.category === ToolCategory.TEXTGEN}
                  />
                </li>
                <li>
                  <MenuItem
                    label="Image"
                    leftIcon="/category-black.svg"
                    onClick={() => formik.setFieldValue('category', ToolCategory.IMAGEGEN)}
                    customActiveCondition={formik.values.category === ToolCategory.IMAGEGEN}
                  />
                </li>
                <li>
                  <MenuItem
                    label="Research"
                    leftIcon="/research-black.svg"
                    onClick={() => formik.setFieldValue('category', ToolCategory.RESEARCHGEN)}
                    customActiveCondition={formik.values.category === ToolCategory.RESEARCHGEN}
                  />
                </li>
                <li>
                  <MenuItem
                    label="Other"
                    leftIcon="/canvases-black.svg"
                    onClick={() => formik.setFieldValue('category', ToolCategory.OTHER)}
                    customActiveCondition={formik.values.category === ToolCategory.OTHER}
                  />
                </li>
              </ul>
            </div>
          </aside>
        </FormikProvider>

        <div className="grid grid-cols-3 gap-6">
          {!isLoading &&
            tools
              .filter((tool) => tool.displayName.toLowerCase().includes(formik.values.search.toLowerCase()))
              .filter((tool) => !formik.values.category || tool.category === formik.values.category)
              .map((tool) => (
                <ToolCard
                  key={tool.name}
                  tool={tool}
                />
              ))}
        </div>
      </div>
    </PageWithHeaderLayout>
  );
}
