import { Dropdown, DropdownDivider } from 'flowbite-react';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { Project } from '@/models/project.interface';
import { useFlowTemplates } from '@/api/flow/get-flow-templates';
import { FlowTemplateCategoryIcon } from 'src/routes/projects/routes/flows-library/components/FlowTemplateCategoryIcon';
import { useState } from 'react';
import { FlowTemplate } from '@/models/flow-template.interface';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { useCreateProject } from '@/api/projects/create-project';
import { usePinFlowToProject } from '@/api/flow/pin-flow-to-project';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url';
import { Urls } from '@/consts/urls';
import { useProjects } from '@/api/projects/get-projects';
import { setFlowInstance } from '@/helpers/flowInstance';

interface SearchForm {
  search: string;
  project?: Project;
  template?: FlowTemplate;
}

export function DashboardSearch() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { createProject } = useCreateProject();
  const { pinFlowToProject } = usePinFlowToProject();

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: '',
    },
    onSubmit: async ({ template, project }) => {
      if (!template) {
        return;
      }

      showLoader();

      const projectEntity = project || (await createProject('Default project'));
      const flow = await pinFlowToProject(template.id, projectEntity.id);
      setFlowInstance(flow.id);

      hideLoader();

      navigate(
        buildUrl([Urls.PROJECTS, Urls.PROJECT, Urls.FLOW_EDITOR], { projectId: projectEntity.id, flowId: flow.id }),
      );
    },
  });

  const [resultsVisible, setResultsVisible] = useState(false);
  const { projects } = useProjects();
  const { flowTemplates } = useFlowTemplates(formik.values.search, '');

  return (
    <div className="relative w-full">
      {resultsVisible && (
        <div
          onMouseDown={(e) => e.preventDefault()}
          className="absolute left-0 top-[70px] max-h-64 bg-white flex w-full rounded-xl border border-gray-divider flex-col overflow-auto"
        >
          <div className="flex w-full flex-col">
            <span className="text-caption-sm text- p-3 text-text-secondary border-gray-divider border-b text-left">
              Recommended flows
            </span>
            {!flowTemplates.length && (
              <span className="text-caption-sm p-3 text-text-secondary border-gray-divider text-center">
                No results
              </span>
            )}
            <div>
              {flowTemplates?.map((template) => (
                <div
                  key={template.id}
                  onClick={() => {
                    formik.setFieldValue('template', template);
                    formik.setFieldValue('search', template.name);
                    setResultsVisible(false);
                  }}
                  className="flex items-center p-3 gap-4 cursor-pointer border-gray-divider border-b hover:bg-gray-50"
                >
                  <div className="rounded-lg p-2 bg-purple-dark">
                    <FlowTemplateCategoryIcon category={template.category} />
                  </div>
                  <h5 className="text-h5-desktop text-left">{template.name}</h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center gap-3 w-full">
        <FormikProvider value={formik}>
          <div className="flex bg-white p-2 rounded-xl flex-1">
            <div>
              <div
                className={clsx(
                  'rounded-md p-1.5 my-2.5 ml-1.5 mr-1',
                  formik.values.template ? ['bg-purple-dark'] : ['bg-gray-100'],
                )}
              >
                {formik.values.template ? (
                  <FlowTemplateCategoryIcon
                    width={12}
                    height={12}
                    category={formik.values.template.category}
                  />
                ) : (
                  <img
                    src="/search-black.svg"
                    width={12}
                    height={12}
                    alt=""
                  />
                )}
              </div>
            </div>
            <input
              id="search"
              {...formik.getFieldProps('search')}
              onFocus={() => setResultsVisible(true)}
              onBlur={() => setResultsVisible(false)}
              onInput={() => {
                formik.setFieldValue('template', null);
                setResultsVisible(true);
              }}
              className="flex flex-1 outline-none ml-2"
              autoComplete="off"
              placeholder="Ex. create a marketing strategy..."
            />
            <Dropdown
              label=""
              className="max-h-52 overflow-auto p-0"
              placement="bottom-end"
              renderTrigger={() => (
                <div className="cursor-pointer flex min-w-28 items-center justify-between">
                  <div>
                    <div
                      className={clsx(
                        'rounded-md p-1.5 my-2.5 ml-1.5 mr-3',
                        formik.values.project
                          ? [formik.values.project.color ?? 'bg-green-light', 'opacity-100']
                          : ['opacity-0', 'bg-gray-ultra-light'],
                      )}
                    >
                      <img
                        src={formik.values.project ? '/business-black.svg' : '/add-black.svg'}
                        width={12}
                        height={12}
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="text-caption-sm">
                    {formik.values.project ? formik.values.project.name : 'New project'}
                  </span>
                  <img
                    className="ml-3"
                    src="/chevron-down-gray.svg"
                    alt=""
                  />
                </div>
              )}
            >
              <Dropdown.Item
                className="min-w-52 rounded-none"
                onClick={() => formik.setFieldValue('project', null)}
              >
                <div className="rounded-md p-1.5 my-2.5 bg-gray-ultra-light">
                  <img
                    src="/plus-black.svg"
                    alt=""
                    width={12}
                    height={12}
                  />
                </div>
                New project
              </Dropdown.Item>

              <DropdownDivider />

              {projects?.map((project) => (
                <Dropdown.Item
                  key={project.id}
                  className="p-0 rounded-none"
                  onClick={() => formik.setFieldValue('project', project)}
                >
                  <div className={clsx('rounded-md p-1.5 my-2.5 ml-1.5', project.color ?? 'bg-green-light')}>
                    <img
                      src="/business-black.svg"
                      width={12}
                      height={12}
                      alt=""
                    />
                  </div>
                  {project.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
          <button
            onClick={formik.submitForm}
            disabled={!formik.isValid}
            className="group flex items-center justify-center size-[56px] rounded-xl bg-gray-dark text-white"
          >
            <img
              src="/arrow-right-white.svg"
              alt=""
              className="group-hover:translate-x-1 transition-transform"
            />
          </button>
        </FormikProvider>
      </div>
    </div>
  );
}
