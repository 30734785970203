import { FlowTemplate } from '@/models/flow-template.interface.ts';
import { useApi } from '../api.ts';
import useSWR from 'swr';

export const useFlowTemplates = (search: string, category: string) => {
  const { getFetcher } = useApi();

  const queryParams = new URLSearchParams();
  queryParams.append('search', search);
  queryParams.append('category', category);

  const { data, error, isLoading } = useSWR<FlowTemplate[]>(
    queryParams?.toString() && `/flow?${queryParams.toString()}`,
    getFetcher,
  );

  return {
    flowTemplates: data?.sort((a: FlowTemplate, b: FlowTemplate) => b.id - a.id) || [],
    error,
    isLoading,
  };
};
