import { useState } from 'react';
import { useApi } from '../api.ts';
import { mutate } from 'swr';
import { setCurrentWorkspace } from '@/state/app-store.ts';

export const useCreateWorkspace = (): {
  createWorkspace: (name: string) => Promise<void>;
  isLoading: boolean;
} => {
  const { postFetcher } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const createWorkspace = async (name: string): Promise<void> => {
    setIsLoading(true);

    try {
      const workspace = await postFetcher('/workspace', { arg: { name } });
      await mutate('/workspace/by-user');
      setCurrentWorkspace(workspace);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createWorkspace,
    isLoading,
  };
};
