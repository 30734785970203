import clsx from 'clsx';
import { Card } from 'flowbite-react';
import { ReactNode, useState } from 'react';

interface ProjectItemListProps {
  cards: ReactNode[];
  addButtonClass: string;
  iconClass: string;
  borderClass: string;
  title: string;
  addButtonHandler: () => void;
}

export function ProjectItemList({
  cards,
  addButtonClass,
  iconClass,
  title,
  borderClass,
  addButtonHandler,
}: ProjectItemListProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <div className={clsx(`p-2 rounded-lg ${iconClass}`)}>
            <img
              src="/fact-check-black.svg"
              alt=""
            />
          </div>
          <h2 className="text-h4-desktop">{title}</h2>
        </div>
        <button
          className="link link--lg"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          View all
          <img
            src="/chevron-down-blue.svg"
            className={clsx('transition', { 'rotate-180': isExpanded })}
            alt=""
          />
        </button>
      </div>

      <div className={clsx('flex gap-4')}>
        <button
          className="flex items-stretch h-[112px]"
          onClick={addButtonHandler}
        >
          <Card className={clsx(`${addButtonClass} ${borderClass} w-[112px]`)}>
            <div className="flex items-center justify-center size-full">
              <img
                src="/add-black.svg"
                width={32}
                height={32}
                alt=""
              />
            </div>
          </Card>
        </button>

        <div className="grow min-w-0 grid grid-cols-3 gap-4">
          {(isExpanded ? cards : cards.slice(0, 3)).map((card) => card)}
        </div>
      </div>
    </>
  );
}
