import { mutate, useSWRConfig } from 'swr';
import { useApi } from '../api';
import { FlowTemplateCreate } from '@/models/flow-template-create.interface';

export const useCreateFlowTemplate = () => {
  const { postFetcher } = useApi();
  const { cache } = useSWRConfig();

  const createFlowTemplate = async (input: FlowTemplateCreate) => {
    const flow = await postFetcher('/flow/create-template', {
      arg: input,
    });
    await Promise.all(
      Array.from(cache.keys())
        .filter((key) => key.startsWith('/flow'))
        .map((key) => mutate(key)),
    );
    return flow;
  };

  return { createFlowTemplate };
};
