import { useApi } from '@/api/api.ts';
import useSWR from 'swr';
import { PersonaDetails } from '@/models/persona-details.interface.ts';
import { useMemo } from 'react';
import { Persona } from '@/models/Persona-dto.interface.ts';

export const usePersonaDetails = (personaId: number) => {
  const { getFetcher } = useApi();

  const { data } = useSWR<Persona>(`/persona-canvas/${personaId}`, getFetcher);

  const personaDetails: PersonaDetails | undefined = useMemo((): PersonaDetails | undefined => {
    if (!data) return;

    const values = data.positionValues?.reduce(
      (
        acc: Record<number, string[]>,
        pv: {
          value: string;
          position: {
            id: number;
          };
        },
      ) => {
        acc[pv.position.id] = acc[pv.position.id] || [];
        acc[pv.position.id].push(pv.value);
        return acc;
      },
      {},
    );

    return {
      id: data.id,
      name: data.name,
      description: data.description,
      segment: data.segment.name,
      positions: data.template.positions.map((p) => ({
        id: p.id,
        label: p.label,
        value: values?.[p.id] ?? [],
        hierarchyPosition: p.hierarchyPosition,
      })),
      createdAt: data.createdAt,
      modifiedAt: data.modifiedAt,
      deletedAt: data.deletedAt,
    };
  }, [data]);

  return {
    personaDetails,
  };
};
