import { WebSocketStatus } from '@hocuspocus/provider';

export const getConnectionText = (collabState: WebSocketStatus) => {
  switch (collabState) {
    case WebSocketStatus.Connected:
      return ``;

    case WebSocketStatus.Connecting:
      return `Connecting...`;

    case WebSocketStatus.Disconnected:
      return `Disconnected`;

    default:
      return ``;
  }
};
