const KEY = 'dfFlowInstanceId';

export const getFlowInstance = () => {
  const data = localStorage.getItem(KEY);
  return data ? JSON.parse(data) : [];
};

export const setFlowInstance = (id: number) => {
  const data = getFlowInstance();
  localStorage.setItem(KEY, JSON.stringify([...data, id]));
};

export const clearFlowInstance = (id: number) => {
  const data = getFlowInstance();
  localStorage.setItem(KEY, JSON.stringify(data.filter((item: number) => item !== id)));
};
