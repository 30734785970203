import { useApi } from '@/api/api.ts';
import { mutate } from 'swr';
import { useCurrentWorkspace } from '@/state/app-store.ts';

export const useAskQuestionInWebSearcherRoom = (): {
  askQuestionInWebSearcherRoom: (roomId: number, question: string) => Promise<void>;
} => {
  const { patchFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const askQuestionInWebSearcherRoom = async (roomId: number, question: string) => {
    await patchFetcher(`/websearcher/room/${roomId}/ask-question`, {
      arg: {
        message: question,
        returnImages: true,
        returnVideos: true,
        returnSources: true,
        returnMaps: true,
        returnFollowUpQuestions: true,
      },
    });
    await mutate(`/websearcher/rooms/by-workspace/${workspaceId}`);
  };

  return { askQuestionInWebSearcherRoom };
};
