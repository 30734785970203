import { useApi } from '../../api.ts';
import { mutate } from 'swr';

export const useUpdateMyFlow = (): {
  updateMyFlowName: (name: string, myFlowId: number, projectId: number) => Promise<void>;
  moveMyFlowToProject: (newProjectId: number, myFlowId: number, oldProjectId: number) => Promise<void>;
} => {
  const { patchFetcher } = useApi();

  const updateMyFlowName = async (name: string, myFlowId: number, projectId: number) => {
    await patchFetcher(`/flow/my-flow/${myFlowId}`, { arg: { name } });
    await mutate(`/flow/my-flows/${projectId}`);
  };

  const moveMyFlowToProject = async (newProjectId: number, myFlowId: number, oldProjectId: number) => {
    await patchFetcher(`/flow/my-flow/${myFlowId}`, { arg: { projectId: newProjectId } });
    await mutate(`/flow/my-flows/${oldProjectId}`);
  };

  return {
    updateMyFlowName,
    moveMyFlowToProject,
  };
};
