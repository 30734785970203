import { FileInput } from 'flowbite-react';
import { CompanyCanvasesShortcuts } from './components/CompanyCanvasesShortcuts.tsx';
import { useCallback } from 'react';
import { AddedDataTable } from './components/AddedDataTable.tsx';
import { CompanyBriefIsland } from '@/components/CompanyBriefIsland.tsx';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { AddResourcePayload, InputType } from '@/models/add-resource-payload.interface.ts';
import { inputsPayloadMapper } from '@/helpers/inputs-payload-mapper.ts';
import { useAddResource } from '@/api/inputs/add-resource.ts';
import { useOnWebSocketMessage } from '@/useOnWebSocketMessage.ts';
import { mutate } from 'swr';
import { useDataRoomId } from '@/state/app-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { GLOBAL_LOADER_MESSAGES } from '@/components/GlobalLoader/global-loader-messages.const.ts';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { AddDataToDataRoomButton } from './components/AddDataToDataRoomButton.tsx';

export function DataPanel() {
  const { addResource } = useAddResource();
  const dataRoomId = useDataRoomId();
  const { showLoader, hideLoader } = useGlobalLoader();

  const refreshInputs = useCallback(async () => {
    await mutate(`/inputs/dataRoom/${dataRoomId}`);
  }, [dataRoomId]);

  useOnWebSocketMessage(`INPUT_PROCESSOR:${dataRoomId}`, refreshInputs);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const files: AddResourcePayload[] = await inputsPayloadMapper[InputType.File](acceptedFiles);
      showLoader(GLOBAL_LOADER_MESSAGES.AddingData);
      await Promise.all(files.map((file) => addResource(file)));
      hideLoader();
    },
    [addResource, hideLoader, showLoader],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
    multiple: false,
  });

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [{ label: 'Data', href: '#' }],
        icon: '/rows-black.svg',
        rightAddons: [<AddDataToDataRoomButton key="add-data-to-data-room-button" />],
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Data room</title>
      </Helmet>

      <div {...getRootProps()}>
        <div className={clsx('drag-and-drop', { 'drag-and-drop--invisible': !isDragActive })}>
          <div className="drag-and-drop__content">
            <div className="flex flex-col items-center justify-center text-center">
              <img
                src="/three-gray-documents.png"
                className="p-6 mb-3"
                alt=""
              />
              <img
                src="/upload-cloud-blue.svg"
                className="p-3 rounded-full bg-tint-blue mb-6"
                alt=""
              />
              <div className="text-h2-desktop mb-3">Drop any file</div>
              <div className="text-body-sm text-text-secondary">TXT, DOCX, PPTX, CSV, PDF, MPEG, MP4, MOV, AVI</div>
            </div>
          </div>
        </div>

        <h2 className="text-h4-desktop mb-6">Company canvases</h2>

        <section className="mb-8">
          <CompanyBriefIsland />
        </section>

        <section className="mb-10">
          <CompanyCanvasesShortcuts />
        </section>

        <section>
          <AddedDataTable />
        </section>

        <FileInput
          className="hidden"
          {...getInputProps()}
        />
      </div>
    </PageWithHeaderLayout>
  );
}
