import { Dropdown } from 'flowbite-react';
import { ReactElement } from 'react';
import { Flow } from '@/models/flow.interface.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useUnpinMyFlowFromProject } from '@/api/flow/my-flow/unpin-my-flow-from-project.ts';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useParams } from 'react-router-dom';
import { useSaveAsFlowTemplate } from '@/api/flow/save-as-flow-template';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { TiptapDocument } from '@/models/tiptap-document.interface.ts';
import { MemoryContextItemType } from '@/enums/memory-context-item-type.enum.ts';
import { useAddToMemoryContext } from '@/api/memory-context/add-to-memory-context.ts';
import toast from 'react-hot-toast';
import { useMemoryContext } from '@/api/memory-context/get-memory-context.ts';

export function DropdownMyFlowOptions({ children, myFlow }: { children: ReactElement; myFlow: Flow }) {
  const { projectId } = useParams();
  const { openModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { openDialog } = useGlobalDialog();
  const { unpinMyFlowFromProject } = useUnpinMyFlowFromProject();
  const { saveAsFlowTemplate } = useSaveAsFlowTemplate();
  const { addToMemoryContext } = useAddToMemoryContext();
  const { allSlotsFilled, memoryContextItems } = useMemoryContext(projectId!);

  const onRenameClick = () => {
    openModal(MODALS.EditMyFlowName, { ...myFlow, projectId });
  };

  const onDeleteClick = () => {
    openDialog(DIALOGS.UnpinMyFlowFromProject, async () => {
      await unpinMyFlowFromProject(myFlow.id, Number(projectId));
    });
  };

  const onMoveToProjectClick = () => {
    openModal(MODALS.MoveMyFlowToProject, { myFlow });
  };

  const onSaveAsTemplateClick = async () => {
    showLoader();
    await saveAsFlowTemplate(myFlow.id);
    hideLoader();
  };

  const onAddTiptapDocumentToMemoryContext = async (tiptapDocument: TiptapDocument) => {
    if (allSlotsFilled) {
      toast.error('Your memory storage is full. Please delete existing entries before adding new ones.');
    } else {
      addToMemoryContext(projectId!, {
        type: MemoryContextItemType.TIPTAP_DOCUMENT,
        itemId: tiptapDocument.id,
        order: memoryContextItems.length + 1,
      }).then(async () => {
        toast.success('Added to Memory Context');
      });
    }
  };

  return (
    <>
      <Dropdown
        label=""
        onClick={(e) => e.stopPropagation()}
        renderTrigger={() => <div>{children}</div>}
        placement="bottom-end"
        className="w-[230px]"
      >
        <Dropdown.Item onClick={() => onRenameClick()}>
          <img
            src="/pencil-black.svg"
            alt=""
          />
          Rename
        </Dropdown.Item>

        <Dropdown.Item onClick={() => onSaveAsTemplateClick()}>
          <img
            src="/generate-black.svg"
            alt=""
          />
          Save as template
        </Dropdown.Item>

        <Dropdown.Item onClick={() => onAddTiptapDocumentToMemoryContext(myFlow.tiptapDocument!)}>
          <img
            src="/pin-black.svg"
            alt=""
          />
          Add to memory context
        </Dropdown.Item>

        <Dropdown.Item onClick={onMoveToProjectClick}>
          <img
            src="/pin-black.svg"
            alt=""
          />
          Move to project
        </Dropdown.Item>

        <Dropdown.Item onClick={onDeleteClick}>
          <img
            src="/delete-black.svg"
            alt=""
          />
          Delete
        </Dropdown.Item>
      </Dropdown>
    </>
  );
}
